import { combineReducers } from "redux";

import InformationReducer from "./information.slice";
import UserReducer from "./user.slice";
import ProductsReducer from "./products.slice";
import ProductMarkReducer from "./product-mark.slice";
import BasketReducer from "./basket.slice";
import FilterReducer from "./filter.slice";

const RootReducer = combineReducers({
  information: InformationReducer,
  user: UserReducer,
  products: ProductsReducer,
  productMark: ProductMarkReducer,
  basket: BasketReducer,
  filters: FilterReducer,
});

export default RootReducer;
