import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";
// import Cookies from "js-cookie";
import Cookies from "universal-cookie";

import { Table, Th, Tr, Td, Thead, Tbody } from "../table/table.component";
import Product from "./Product.interface";
import ProductTitleEan from "./ProductTitleEan/ProductTitleEan";

import { formatAmount } from "../../utils/text-formatting.utils";
import { resetMark } from "../../store/actions/product-mark.action";
import { environment } from "../../environments";
import {
  getDiscountLevel,
  priceWithDiscount,
  getTotalWithoutTaxes,
  getTotalWithTaxes,
  getDiscountLevels,
  getAdjustedLevels,
} from "../../utils/prices";

const StyledTr = styled(Tr)<{ color?: string; bottomColor?: string }>`
  background-color: ${({ color }) => (color ? color : "white")};
  border-bottom: ${({ bottomColor }) =>
    bottomColor ? `1px solid ${bottomColor} !important;` : "none"};
  *::selection {
    background-color: transparent;
  }
`;

const StyledTh = styled(Th)<{
  bottomColor?: string;
  centered?: boolean;
  minWidth?: string;
}>`
  text-align: ${({ centered }) => (centered ? "center" : "left")};
  border-bottom: ${({ bottomColor }) =>
    bottomColor ? `1px solid ${bottomColor} !important;` : "none"};
  min-width: ${({ minWidth }) => (minWidth ? minWidth : "")};
  padding: 0 8px;
`;

const StyledTd = styled(Td)<{ centered?: boolean }>`
  text-align: ${({ centered }) => (centered ? "center" : "left")};
  padding: 0 8px;
`;

const Image = styled.div`
  display: flex;
  justify-content: center;
  margin: 10px;
  height: 40px;
  width: 40px;

  img {
    max-height: 100%;
    max-width: 100%;
  }
`;

const ImageTd = styled(Td)`
  padding: 0 !important;
`;

// DEBUG: I should have a way to directly open & scroll to the right category from a parameter.
const ProductRow = ({
  id,
  imagePath,
  title,
  brand,
  ean,
  batch,
  tva,
  price,
  stock,

  product,

  color = undefined,
  priceWithTaxesColumn,
  baseDiscounts,
  discountThresholds,
  discountGroupProducts,
}) => {
  const cookies = new Cookies();

  const dispatch = useDispatch();
  const rowRef = useRef(null);

  const productMark = useSelector((state) => state.productMark);
  const basket = useSelector(
    (state) => state.basket[cookies.get("pharmacy") ?? "0"] ?? {}
  );

  const [orderQuantity, setOrderQuantity] = useState("0");

  useEffect(() => {
    if (!productMark.type) return;
    if (productMark.type === "product" && id === productMark.id) {
      dispatch(resetMark);
      rowRef.current.scrollIntoView({
        alignToTop: true,
        behavior: "smooth",
      });
    }
  }, [productMark, dispatch, id]);

  useEffect(() => {
    if (!basket) return;
    if (basket[id]) {
      setOrderQuantity(basket[id]);
    } else {
      setOrderQuantity("0");
    }
  }, [id, basket]);

  // DEBUG: might be memoised or might use useState & only change on quantity change
  const discountLevels = getDiscountLevels(baseDiscounts, discountThresholds);

  const adustLevelsTest = getAdjustedLevels(
    product.product_id,
    discountLevels,
    discountGroupProducts,
    basket
  );

  const orderQuantityInt = parseInt(orderQuantity, 10);
  const discountLevel = getDiscountLevel(adustLevelsTest, orderQuantityInt);

  const pricePostDiscount = priceWithDiscount(price, discountLevel);
  const totalWithoutTaxes = getTotalWithoutTaxes(
    price,
    orderQuantityInt,
    discountLevel
  );
  const totalWithTaxes = getTotalWithTaxes(totalWithoutTaxes, tva);

  return (
    <StyledTr ref={rowRef} color={color} bottomColor="#f3f3f4">
      <ImageTd>
        <Image>
          <img
            src={`${environment.imageBasePath}${imagePath}`}
            // DEBUG
            // alt={`${title}`}
            alt={"image"}
          />
        </Image>
      </ImageTd>
      <StyledTd>
        <Link to={`/produit/${id}`}>
          <ProductTitleEan title={title} ean={ean} brand={brand} />
        </Link>
      </StyledTd>
      <StyledTd centered>{product.sub_range_title}</StyledTd>
      <StyledTd centered>{batch}</StyledTd>
      <StyledTd centered>{`${tva}%`}</StyledTd>
      <StyledTd centered>{formatAmount(price)}</StyledTd>
      <StyledTd centered>{`${discountLevel}%`}</StyledTd>
      <StyledTd centered>{formatAmount(pricePostDiscount)}</StyledTd>
      <StyledTd centered>
        <p>{orderQuantity}</p>
      </StyledTd>
      <StyledTd centered>{formatAmount(totalWithoutTaxes)}</StyledTd>
      {!priceWithTaxesColumn ? null : (
        <StyledTd centered>{formatAmount(totalWithTaxes)}</StyledTd>
      )}
    </StyledTr>
  );
};

const ProductsOrderRecap = ({
  products,
  baseDiscounts,
  discountThresholds,
  discountGroupProducts,
  priceWithTaxesColumn = false,
}) => {
  // DEBUG
  const columns = [
    "",
    "Produit",
    "Gamme",
    "Volume",
    "TVA",
    "Prix",
    "Remise",
    "Prix net",
    "Qté",
    "Total HT",
  ];

  if (priceWithTaxesColumn) {
    columns.push("Total TTC");
  }

  return (
    products &&
    products.length && (
      <Table className="responsive-static">
        <Thead>
          <StyledTr color="rgba(255, 255, 255, 0.9)">
            {columns.map((columnName, idx) => (
              <StyledTh
                key={idx}
                bottomColor="#f3f3f4"
                centered={idx !== 1}
                minWidth={idx >= columns.length - 2 ? "90px" : undefined}
              >
                {columnName}
              </StyledTh>
            ))}
          </StyledTr>
        </Thead>
        <Tbody>
          {products.map((product: Product, idx) => (
            <ProductRow
              key={idx}
              id={product.product_id}
              imagePath={product.image_path}
              title={product.product_title}
              brand={product.brand}
              ean={product.ean_id}
              stock={product.stock}
              batch={product.batch_quantity}
              tva={product.tva_percentage}
              price={product.price_eur}
              product={product}
              color={idx % 2 === 0 ? "rgba(255, 255, 255, 0.9)" : "#f3f9f9"}
              priceWithTaxesColumn={priceWithTaxesColumn}
              baseDiscounts={baseDiscounts[product.subRangeId]}
              discountThresholds={discountThresholds.filter((d) =>
                product.discount_groups?.includes(d.discount_group_id)
              )}
              discountGroupProducts={Object.fromEntries(
                Object.entries(discountGroupProducts).filter(([key]) =>
                  product.discount_groups?.includes(+key)
                )
              )}
            />
          ))}
        </Tbody>
      </Table>
    )
  );
};

export default ProductsOrderRecap;
