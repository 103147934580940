import React, { MouseEvent, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";

import {
  CenteredTd,
  CenteredTh,
  Table,
  Tbody,
  Thead,
  Tr,
} from "../../components/table/table.component";

import Layout from "../../components/Layout/Layout";
import PageHeader from "../../components/PageHeader/PageHeader";
import { get, postFormData } from "../../services/utils.service";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileCsv, faTrash } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";

const STATUS_MESSAGES = {
  INITIAL:
    "Veuillez charger un fichier CSV valide pour les commandes groupées.",
  SOME_PRODUCTS_ARE_NOT_ACTIVATED:
    "Certains produits du CSV ne sont pas activés dans la base de données Leadersanté Shop: ",
  GROUPED_ORDER_SENT: "La commande groupée a bien été envoyée.",
  CSV_FILE_NOT_VALID:
    "Le fichier CSV n'est pas valide. Veuillez charger un autre fichier :",
  FILE_TYPE_NOT_CSV:
    "Le fichier doit être de type CSV. Veuillez charger un autre fichier :",
  PRODUCTS_DATA_NOT_FOUND_IN_DB: `Les données relatives à certains produits sont absentes de la 
  base de données. Veuillez charger un autre fichier CSV ou corriger les données dans 
  la base de données.`,
};
const CSV_VALID_EXPLANATION_INTRO_TEXT =
  "Pour une commande groupée, un fichier CSV valide doit :";
const CSV_VALID_EXPLANATION_ITEMS = [
  `Contenir une colonne « cip » ainsi que des colonnes de la forme « [ean]-[pourcentage de remise] »
  (par exemple « 3664788001566-50 » comme nom de colonne signifie une remise de 50% sur le produit 
  ayant pour EAN 3664788001566) ;`,
  "Utiliser comme séparateur le point-virgule ;",
  "Ne pas contenir pas de ligne vide ou incomplète ;",
  `Lorsqu´un produit est pris en quantité nulle par une pharmacie, 
  contenir 0 dans la cellule correspondante.`,
];

const NameInput = styled.input`
  min-width: 300px;
  min-height: 30px;
`;

const Spacer = styled.div`
  min-height: 15px;
`;

const MainWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  padding: 100px;
  background-color: rgba(255, 255, 255, 0.8);
  justify-content: space-around;
`;

const TrashButton = styled.button`
  min-width: 25px;
  min-height: 25px;
`;

const SubmitButton = styled.input``;

const StatusMessageText = styled.p``;

const CSVAndTitleInputWrapper = styled.div``;

const ValidCSVExplanationTextWrapper = styled.div`
  background-color: #f5d1d1;
  max-width: 40%;
  min-width: 25%;
  padding: 15px;
  border-radius: 8px;
`;

type Inputs = {
  files: FileList;
  name: string;
};

const GroupedOrders = () => {
  const [fileUploaded, setFileUploaded] = useState(null);
  const [statusMessage, setStatusMessage] = useState(STATUS_MESSAGES.INITIAL);
  const [response, setResponse] = useState(null);
  const [isResponsePending, setIsResponsePending] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { isValid },
  } = useForm({ mode: "onChange" });

  const [groupedOrders, setGroupedOrders] = useState(null);

  const columns = [
    "ID",
    "Nom de la commande groupée",
    "Date",
    "Annuler",
    "Fichier CSV",
  ];

  const history = useHistory();

  const formRef = useRef<HTMLFormElement>(null);

  const handleCheckDetails = (event: MouseEvent, groupedOrderId: number) => {
    event.preventDefault();
    history.push(`/commande-groupee/${groupedOrderId}`);
  };

  const handleSubmitForm = async (inputs: Inputs) => {
    setIsResponsePending(true);
    if (!isValid) return;
    try {
      const data = new FormData();
      data.append("file", inputs.files[0]);
      data.append("name", inputs.name);

      const resp = await postFormData(
        "/orders/createGroupedOrderFromCSV",
        data
      );

      const respData = await resp.json();
      setResponse(respData);
      history.push(`commande-groupee/${response.groupedOrderId}`);
    } catch (error) {
      console.error(error);
    } finally {
      setIsResponsePending(false);
    }
  };

  const fetchGroupedOrders = async () => {
    try {
      const response = await get("/orders/groupedOrders");
      const orders = await response.json();
      if (orders.statusCode && orders.statusCode != 200)
        throw "Error while fetching grouped orders";
      const formattedOrders = orders.map((order) => {
        return {
          ...order,
        };
      });
      setGroupedOrders(formattedOrders);
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  const handleCancelGroupedOrder = async (event, groupedOrderId) => {
    event.preventDefault();
    event.stopPropagation();
    try {
      await get(`/orders/cancelGroupedOrder/${groupedOrderId}`);
      await fetchGroupedOrders();
    } catch (error) {
      console.error(error);
    }
  };

  const handleDownloadCSVFile = async (event, groupedOrderId) => {
    event.stopPropagation();
    try {
      const response = await get(
        `/orders/downloadGroupedOrderCSV/${groupedOrderId}`
      );
      const blob = await response.blob();
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.setAttribute("download", `commande-groupee-${groupedOrderId}.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error(error);
    }
  };

  const currentTimeMinus2H = moment()
    .add(-2, "hours")
    .utc()
    .format("YYYY-MM-DD HH:mm:ss");

  const showTrash = (groupedOrder) => {
    return (
      groupedOrder.orderStatusFk !== 7 &&
      moment(groupedOrder.creationDate).format("YYYY-MM-DD HH:mm:ss") >
        currentTimeMinus2H
    );
  };

  useEffect(() => {
    if (response === null) {
      fetchGroupedOrders();
      return;
    }

    if (response.message === "CSV_FILE_NOT_VALID") {
      setStatusMessage(STATUS_MESSAGES.CSV_FILE_NOT_VALID);
    } else if (response.message === "SOME_PRODUCTS_ARE_NOT_ACTIVATED") {
      const statusMessageWithProducts = `${
        STATUS_MESSAGES.SOME_PRODUCTS_ARE_NOT_ACTIVATED
      } 
      ${response.products
        .map((p) => `${p.title} (${p.eanId})`)
        .join(", ")}. Veuillez charger un autre fichier :`;
      setStatusMessage(statusMessageWithProducts);
    } else if (response.message === "FILE_TYPE_NOT_CSV") {
      setStatusMessage(STATUS_MESSAGES.FILE_TYPE_NOT_CSV);
    } else if (response.message === "PRODUCTS_DATA_NOT_FOUND_IN_DB") {
      setStatusMessage(STATUS_MESSAGES.PRODUCTS_DATA_NOT_FOUND_IN_DB);
    } else {
      setStatusMessage(STATUS_MESSAGES.GROUPED_ORDER_SENT);
    }

    setFileUploaded(null);
    reset();
    setResponse(null);
  }, [response]);

  useEffect(() => {
    if (fileUploaded === null) return;
    setStatusMessage(STATUS_MESSAGES.INITIAL);
  }, [fileUploaded]);

  return (
    <Layout>
      <PageHeader title="Commandes groupées" />
      <MainWrapper>
        <CSVAndTitleInputWrapper>
          <StatusMessageText>{statusMessage}</StatusMessageText>

          <br />

          <form ref={formRef} onSubmit={handleSubmit(handleSubmitForm)}>
            <input
              title={STATUS_MESSAGES.INITIAL}
              type="file"
              multiple={false}
              {...register("files", { required: true })}
            />

            <br />
            <Spacer />

            <NameInput
              placeholder="Nom de la commande (2 char. min.)"
              type="text"
              {...register("name", {
                required: true,
                minLength: 2,
                maxLength: 50,
              })}
            />

            <br />
            <Spacer />

            <SubmitButton
              disabled={isResponsePending}
              type="submit"
              value="Envoyer la commande"
            />
          </form>

          <br />
          <br />
        </CSVAndTitleInputWrapper>

        <ValidCSVExplanationTextWrapper>
          {CSV_VALID_EXPLANATION_INTRO_TEXT}
          <ul>
            {CSV_VALID_EXPLANATION_ITEMS.map((itemText, idx) => (
              <li key={idx}>{itemText}</li>
            ))}
          </ul>
        </ValidCSVExplanationTextWrapper>
      </MainWrapper>

      <PageHeader title="Historique" />

      {groupedOrders && groupedOrders.length ? (
        <Table className="responsive-static">
          <Thead>
            <Tr>
              {columns.map((h, idx) => (
                <CenteredTh key={idx}>{h}</CenteredTh>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {groupedOrders.map((groupedOrder) => (
              <Tr
                key={groupedOrder.id}
                onClick={(event) => handleCheckDetails(event, groupedOrder.id)}
                style={{ cursor: "pointer" }}
              >
                <CenteredTd>{groupedOrder.id}</CenteredTd>

                <CenteredTd>
                  {groupedOrder.name ?? ` Commande no. ${groupedOrder.id}`}
                </CenteredTd>

                <CenteredTd>
                  {moment(groupedOrder.creationDate).format(
                    "YYYY-MM-DD HH:mm:ss"
                  )}
                </CenteredTd>

                <CenteredTd>
                  {showTrash(groupedOrder) ? (
                    <TrashButton
                      onClick={(event) =>
                        handleCancelGroupedOrder(event, groupedOrder.id)
                      }
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </TrashButton>
                  ) : groupedOrder.orderStatusFk === 7 ? (
                    "Annulé"
                  ) : null}
                </CenteredTd>

                <CenteredTd>
                  {groupedOrder.filename ? (
                    <TrashButton
                      onClick={(event) =>
                        handleDownloadCSVFile(event, groupedOrder.id)
                      }
                    >
                      <FontAwesomeIcon icon={faFileCsv} />
                    </TrashButton>
                  ) : (
                    <div>{null}</div>
                  )}
                </CenteredTd>
              </Tr>
            ))}
          </Tbody>
        </Table>
      ) : null}
    </Layout>
  );
};

export default GroupedOrders;
