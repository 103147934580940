import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import queryString from "query-string";

import { resetMark } from "./../../store/actions/product-mark.action";
import variables from "../../themes/variables.theme";
import { selectTheme } from "../../utils/theme.utils";

const Wrapper = styled.div<{ subRow?: boolean; isOpen?: boolean }>`
  margin-bottom: ${({ subRow, isOpen }) => (!subRow && isOpen ? "32px" : "0")};
  border-bottom: ${({ subRow, isOpen }) =>
    subRow && !isOpen ? "1px solid #dedede;" : "none"};

  &:last-child {
    border-bottom: none;
  }
`;

const Children = styled.div`
  width: 100%;
  max-width: 100%;
  margin: 0;
  padding: 0;
  overflow-x: auto;
`;

const Row = styled.div<{
  color?: string;
  subRow?: boolean;
  isOpen?: boolean;
  themeColor: string;
}>`
  width: 100%;
  padding: 10px 32px 9px 16px;
  color: ${({ color, themeColor }) =>
    color === themeColor ? "#ffffff" : "#20252c"};

  background-color: ${({ color }) => (color ? color : "#f3f3f4")};
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  border-bottom: ${({ subRow, isOpen }) =>
    subRow && isOpen ? "1px solid #dedede;" : "none"};

  &:hover {
    cursor: pointer;
    background-color: ${({ subRow, color, themeColor }) =>
      !subRow ? themeColor : color ? color : "#f3f3f4"};
    color: ${({ subRow }) => (!subRow ? "white" : "#20252c")};
  }
`;

export const Accordion = styled.div`
  width: 100%;
`;

export const AccordionRow = ({
  title,
  children,
  color = undefined,
  subRow = false,
  parentTitle = undefined,
}) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const productMark = useSelector((state) => state.productMark);
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const location = useLocation();
  const queryParams = queryString.parse(location?.search);

  useEffect(() => {
    if (queryParams?.category === title || queryParams?.family === title) {
      setIsOpen(true);
      if (parentTitle || !queryParams?.family)
        ref.current.scrollIntoView({
          alignToTop: true,
          behavior: "smooth",
        });
    }
  }, []);

  useEffect(() => {
    const scrollIntoView = (ref) => {
      dispatch(resetMark);
      ref.current.scrollIntoView({
        alignToTop: true,
        behavior: "smooth",
      });
    };

    if (!productMark.type) return;

    if (productMark.type === "family") {
      if (
        subRow &&
        productMark.value === title &&
        productMark.parent === parentTitle
      ) {
        setIsOpen(true);
        scrollIntoView(ref);
      } else if (!subRow && productMark.parent === title) {
        setIsOpen(true);
      }
    }

    if (productMark.type === "subRange") {
      if (!subRow && productMark.value === title) {
        setIsOpen(true);
        scrollIntoView(ref);
      }
    }

    if (productMark.type === "product") {
      if (
        subRow &&
        productMark.value === title &&
        productMark.parent === parentTitle
      ) {
        setIsOpen(true);
      } else if (!subRow && productMark.parent === title) {
        setIsOpen(true);
      }
    }
  }, [productMark, ref, subRow, title, parentTitle, dispatch]);

  const formattedTitle = title ? `${title.toUpperCase()}` : "N/A";

  return (
    <Wrapper ref={ref} subRow={subRow} isOpen={isOpen}>
      <Row
        onClick={() => setIsOpen((open) => !open)}
        color={!subRow && isOpen ? selectTheme(user.Role) : color}
        subRow={subRow}
        isOpen={isOpen}
        themeColor={selectTheme(user.Role)}
      >
        {formattedTitle}
      </Row>
      {!isOpen ? null : <Children>{children}</Children>}
    </Wrapper>
  );
};
