import { SET_USER, RESET_USER } from "../actions/user.action";

const initialState = undefined;

const UserReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        ...action.data,
      };
    case RESET_USER:
      return initialState;
    default:
      return {
        ...state,
      };
  }
};

export default UserReducer;
