import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import Layout from "../../components/Layout/Layout";
import PageHeader from "../../components/PageHeader/PageHeader";
import {
  CenteredTh,
  Table,
  Tbody,
  Thead,
  Tr,
  CenteredTd,
} from "../../components/table/table.component";
import { get } from "../../services/utils.service";
import { formatAmount } from "../../utils/text-formatting.utils";

const ClientPortfolio = () => {
  const dispatch = useDispatch();

  const [revenues, setRevenues] = useState([]);

  const columns = ["Pharmacie", "CA HT"];

  const fetchRevenues = async () => {
    const response = await get("/pharmacies/CA");
    const parseResponse = await response.json();
    if (parseResponse && parseResponse.length) setRevenues(parseResponse);
  };

  useEffect(() => {
    fetchRevenues();
  }, []);

  return (
    <Layout>
      <PageHeader
        title="Portefeuille client"
        // buttonText="Télécharger le catalogue"
        // searchPlaceholder="Recherche d'un EAN, gamme ou d'un intitulé"
      />
      <Table className="responsive-static">
        <Thead>
          <Tr>
            {columns.map((h, idx) => (
              <CenteredTh key={idx}>{h}</CenteredTh>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {revenues.map((revenue, idx) => (
            <Tr key={idx}>
              <CenteredTd>{revenue.LegalName}</CenteredTd>
              <CenteredTd>{formatAmount(revenue.CA_HT)}</CenteredTd>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Layout>
  );
};

export default ClientPortfolio;
