export const SET_PRODUCT_MARK = "SET_PRODUCT_MARK";
export const RESET_PRODUCT_MARK = "RESET_PRODUCT_MARK";

export const setMark = (anchor) => (dispatch) => {
  dispatch({
    type: SET_PRODUCT_MARK,
    data: anchor,
  });
};

export const resetMark = (dispatch) => {
  dispatch({
    type: RESET_PRODUCT_MARK,
  });
};
