import { Badge, Center, Loader, Table, Text } from "@mantine/core";
import React from "react";
import { getCouponOrders } from "../../services/discounts.service";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { formatCurrency } from "../../utils/prices";
import ErrorBox from "../../components/ErrorBox";

function CouponCodeDetail({ coupon }) {
  const {
    data: orders,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["couponOrders", coupon.id],
    queryFn: () => getCouponOrders(coupon.id),
  });

  if (isLoading) {
    return (
      <Center m="lg">
        <Loader />
      </Center>
    );
  }

  if (error) {
    return <ErrorBox />;
  }

  return (
    <>
      {/* <Text>Commandes</Text> */}
      <Table>
        <thead>
          <tr>
            <th>Date</th>
            <th>Référence</th>
            <th>Montant</th>
            <th>Pharmacie</th>
          </tr>
        </thead>
        <tbody>
          {orders?.map((order) => {
            return (
              <tr key={order.id}>
                <td>
                  <Text span fw="bold">
                    {dayjs(order.order_date).format("DD/MM/YYYY")}
                  </Text>
                  <Text span>{dayjs(order.order_date).format(", HH:MM")}</Text>
                </td>
                <td>
                  <Badge variant="outline">{order.order_reference}</Badge>
                </td>
                <td>{formatCurrency(order.total_ht)}</td>
                <td>{order.pharmacy_name}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <Center m="md">
        {orders?.length === 0 && <Text>Aucune commande</Text>}
      </Center>
    </>
  );
}

export default CouponCodeDetail;
