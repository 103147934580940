import { useState } from "react";
import {
  AppShell,
  Navbar,
  Header,
  Footer,
  Aside,
  Text,
  MediaQuery,
  Burger,
  useMantineTheme,
  Code,
  Group,
  getStylesRef,
  rem,
  createStyles,
  NavLink,
} from "@mantine/core";
import React from "react";

import LsHeader from "./Header/Header";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";

import { useMediaQuery } from "@mantine/hooks";
import { isPharmacyUser } from "../../utils/client.utils";

export function Layout({ children }) {
  const theme = useMantineTheme();
  const smallScreen = useMediaQuery("(max-width: 48em)");

  const [opened, setOpened] = useState(true);
  const [openedSm, setOpenedSm] = useState(false);
  const user = useSelector((state) => state.user);

  const x = useParams();
  const history = useHistory();

  const location = useLocation();

  const data = [{ text: "Lancer une commande", href: "/" }].concat(
    user
      ? isPharmacyUser(user)
        ? [
            { text: "Mon historique", href: "/historique" },
            { text: "Ma comptabilité", href: "/compta" },
            { text: "Mes infos", href: "/informations" },
            { text: "Mes chiffres", href: "/mes-chiffres" },
          ]
        : user.Role === "admin"
        ? [
            { text: "Compte client", href: "/compte-client" },
            { text: "Portefeuille client", href: "/portefeuille-client" },
            { text: "Catalogue produit", href: "/catalogue" },
            { text: "Gestion plateforme", href: "/gestion-plateforme" },
            { text: "Commandes groupées", href: "/commandes-groupees" },
            { text: "Réductions", href: "/reductions/paliers" },
            { text: "Chiffres", href: "/statistiques" },
          ]
        : user.Role === "salesperson"
        ? [
            { text: "Compte client", href: "/compte-client" },
            { text: "Portefeuille client", href: "/portefeuille-client" },
          ]
        : []
      : []
  );

  const isActive = (item) => {
    // Returns the location without the first slash, or "/" if the location is "/"
    const stardardizedLocation =
      (location.pathname === "/" ? "/" : location.pathname?.substring(1)) ??
      "/";
    // Returns the first part of the link (to handle subroutes like reductions/gammes, etc), or "/" if the link is "/"
    const stardardizedLink =
      item.href === "/" ? "/" : item.href?.substring(1).split("/")[0];
    return stardardizedLocation.startsWith(stardardizedLink);
  };

  const links = data.map((item) => {
    return (
      <NavLink
        py="sm"
        sx={{ textTransform: "uppercase" }}
        key={item.text}
        active={isActive(item)}
        label={item.text}
        onClick={() => {
          history.push(item.href);
        }}
        variant="filled"
      />
    );
  });

  return (
    <AppShell
      navbar={
        <Navbar
          zIndex={3}
          hiddenBreakpoint="sm"
          hidden={smallScreen ? !openedSm : !opened}
          width={{ sm: opened ? 210 : 0 }}
          style={{
            border: 0,
            display:
              (!smallScreen && opened) || (smallScreen && openedSm)
                ? "block"
                : "none",
          }}
        >
          <Navbar.Section grow>{links}</Navbar.Section>
        </Navbar>
      }
      header={
        <Header height={50} p="md">
          <div
            style={{ display: "flex", alignItems: "center", height: "100%" }}
          >
            <MediaQuery largerThan="sm" styles={{ display: "none" }}>
              <Burger
                opened={openedSm}
                onClick={() => {
                  setOpenedSm((o) => !o);
                }}
                size="sm"
                color={theme.colors.gray[6]}
                mr="sm"
              />
            </MediaQuery>
            <MediaQuery smallerThan="sm" styles={{ display: "none" }}>
              <Burger
                opened={opened}
                onClick={() => {
                  setOpened((o) => !o);
                  setOpenedSm((o) => false);
                }}
                size="sm"
                color={theme.colors.gray[6]}
                mr="sm"
              />
            </MediaQuery>

            <LsHeader />
          </div>
        </Header>
      }
    >
      {children}
    </AppShell>
  );
}

export default Layout;
