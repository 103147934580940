import {
  ActionIcon,
  Button,
  Center,
  Group,
  Loader,
  Modal,
  Paper,
  Stack,
  Table,
  Text,
  TextInput,
  Textarea,
} from "@mantine/core";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import React, { useState } from "react";
import {
  createDiscountGroup,
  createPharmacyDiscountGroup,
  deleteDiscountGroup,
  deletePharmacyDiscountGroup,
  getDiscountGroups,
  getPharmacyDiscountGroups,
  updateDiscountGroup,
  updatePharmacyDiscountGroup,
} from "../../services/discounts.service";
import { faPen, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDisclosure } from "@mantine/hooks";
import ErrorBox from "../../components/ErrorBox";

function PharmacyDiscountGroupEditor() {
  const queryClient = useQueryClient();

  const {
    data: pharmacyDiscountGroups,
    isLoading,
    error,
  } = useQuery(["pharmacyDiscountGroups"], getPharmacyDiscountGroups);

  const [opened, { open, close }] = useDisclosure(false);
  const [selectedDiscountGroup, setSelectedDiscountGroup] = useState();

  const onEdit = (pharmacyDiscountGroup) => {
    setSelectedDiscountGroup(pharmacyDiscountGroup);
    open();
  };

  const onCreate = () => {
    setSelectedDiscountGroup(undefined);
    open();
  };

  const updatePharmacyDiscountGroupMutation = useMutation({
    mutationFn: (pharmacyDiscountGroup) => {
      return updatePharmacyDiscountGroup(pharmacyDiscountGroup);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["pharmacyDiscountGroups"] });
      queryClient.invalidateQueries({ queryKey: ["discountGroups"] });
      queryClient.invalidateQueries({ queryKey: ["allBaseDiscounts"] });
    },
  });

  const createPharmacyDiscountGroupMutation = useMutation({
    mutationFn: (newDiscountGroup) => {
      return createPharmacyDiscountGroup(newDiscountGroup);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["pharmacyDiscountGroups"] });
    },
  });

  const deletePharmacyDiscountGroupMutation = useMutation({
    mutationFn: (pharmacyDiscountGroup) => {
      return deletePharmacyDiscountGroup(pharmacyDiscountGroup);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["pharmacyDiscountGroups"] });
      queryClient.invalidateQueries({ queryKey: ["discountGroups"] });
      queryClient.invalidateQueries({ queryKey: ["allBaseDiscounts"] });
    },
  });

  const onFormSubmit = (pharmacyDiscountGroup) => {
    if (pharmacyDiscountGroup?.id) {
      updatePharmacyDiscountGroupMutation.mutate(pharmacyDiscountGroup);
    } else {
      createPharmacyDiscountGroupMutation.mutate(pharmacyDiscountGroup);
    }
    close();
  };

  const onFormCancel = () => {
    setSelectedDiscountGroup(undefined);
    close();
  };

  const onFormDelete = () => {
    deletePharmacyDiscountGroupMutation.mutate(selectedDiscountGroup);
    close();
  };

  const formLoading =
    updatePharmacyDiscountGroupMutation.isLoading ||
    createPharmacyDiscountGroupMutation.isLoading ||
    deletePharmacyDiscountGroupMutation.isLoading;

  if (isLoading)
    return (
      <Center my="lg">
        <Loader />
      </Center>
    );
  if (error) {
    return <ErrorBox />;
  }

  return (
    <>
      <Modal
        opened={opened}
        onClose={close}
        title="Modifier les groupes de réductions"
      >
        <PharmacyDiscountGroupForm
          onFormSubmit={onFormSubmit}
          onFormCancel={onFormCancel}
          onFormDelete={onFormDelete}
          pharmacyDiscountGroup={selectedDiscountGroup}
        />
      </Modal>

      <PharmacyDiscountGroupEditorWrapper
        loading={formLoading}
        onCreate={onCreate}
      >
        <Table>
          <thead>
            <tr>
              <th>Nom</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            {pharmacyDiscountGroups?.map((pharmacyDiscountGroup) => (
              <tr key={pharmacyDiscountGroup.id}>
                <td>
                  <Text fw="bold">{pharmacyDiscountGroup.name}</Text>
                </td>
                <td>
                  <Text>{pharmacyDiscountGroup.description}</Text>
                </td>
                <td>
                  <ActionIcon
                    color="primary"
                    variant="light"
                    title="delete"
                    onClick={() => onEdit(pharmacyDiscountGroup)}
                  >
                    <FontAwesomeIcon icon={faPen} />
                  </ActionIcon>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </PharmacyDiscountGroupEditorWrapper>
    </>
  );
}

function PharmacyDiscountGroupForm({
  onFormSubmit,
  onFormCancel,
  onFormDelete,
  pharmacyDiscountGroup,
}) {
  const [formValues, setFormValues] = useState(pharmacyDiscountGroup);

  function onSubmit(e) {
    e.preventDefault();
    onFormSubmit(formValues);
  }
  return (
    <form onSubmit={onSubmit}>
      <TextInput
        label="Nom du groupement"
        withAsterisk
        value={formValues?.name ?? ""}
        onChange={(e) => setFormValues({ ...formValues, name: e.target.value })}
      />
      <Textarea
        label="Description"
        value={formValues?.description ?? ""}
        onChange={(e) =>
          setFormValues({ ...formValues, description: e.target.value })
        }
      />

      <Group
        mt="md"
        position={pharmacyDiscountGroup?.id ?? false ? "apart" : "right"}
      >
        {pharmacyDiscountGroup?.id && (
          <Button variant="subtle" onClick={onFormDelete}>
            Supprimer
          </Button>
        )}
        <Group position="right">
          <Button variant="outline" onClick={onFormCancel}>
            Annuler
          </Button>
          {/*  */}
          <Button type="submit">Valider</Button>
        </Group>
      </Group>
    </form>
  );
}

function PharmacyDiscountGroupEditorWrapper({ loading, children, onCreate }) {
  return (
    <Paper>
      <Group position="apart" mb="xs">
        <Text size="xl" weight={700}>
          Groupes de réductions
        </Text>
        <Group>
          {loading && <Loader size="sm" />}
          <Button
            onClick={onCreate}
            leftIcon={<FontAwesomeIcon icon={faPlus} />}
          >
            Ajouter
          </Button>
        </Group>
      </Group>
      <Text mb="md" size="sm" color="gray">
        Ces groupes définissent les réductions qui s'appliquent aux pharmacies
        associées. Les groupes évoqués ici peuvent être différents du groupe
        d'appartenance de la pharmacie.
      </Text>
      {children}
    </Paper>
  );
}

export default PharmacyDiscountGroupEditor;
