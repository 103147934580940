import React from "react";
import Layout from "../../components/Layout/Layout";
import PageHeader from "../../components/PageHeader/PageHeader";
import CouponCodes from "../coupon-codes/CouponCodes";
import PharmacyDiscountGroups from "../pharmacy-discount-groups/PharmacyDiscountGroups";
import BaseDiscount from "../base-discounts/BaseDiscount";
import { DiscountGroups } from "../discount-groups/DiscountGroups";
import { Tabs, Paper } from "@mantine/core";
import PharmacyDiscountGroupEditor from "../pharmacy-discount-groups/PharmacyDiscountGroupEditor";
import { useParams, useHistory } from "react-router-dom";

function Discounts() {
  // const navigate = useNavigate();
  const { tabValue } = useParams();
  const history = useHistory();

  const validValues = ["paliers", "gammes", "pharmacies", "coupons"];

  if (!validValues.includes(tabValue)) {
    history.push(`/reductions/${validValues[0]}`);
  }

  return (
    <Layout>
      <PageHeader title="Réductions" />
      <Tabs
        color="primary"
        variant="pills"
        radius="xs"
        value={tabValue}
        onTabChange={(value) => history.push(`/reductions/${value}`)}
      >
        <Paper p="xs">
          <Tabs.List>
            <Tabs.Tab value="paliers">Paliers de réductions</Tabs.Tab>
            <Tabs.Tab value="gammes">Par Gammes</Tabs.Tab>
            <Tabs.Tab value="pharmacies">Groupes de Pharmacies</Tabs.Tab>
            <Tabs.Tab value="coupons">Codes Promo</Tabs.Tab>
          </Tabs.List>
        </Paper>

        <Tabs.Panel value="paliers" pt="md">
          <Paper p="md">
            <DiscountGroups />
          </Paper>
        </Tabs.Panel>

        <Tabs.Panel value="gammes" pt="md">
          <Paper p="md">
            <BaseDiscount />
          </Paper>
        </Tabs.Panel>

        <Tabs.Panel value="pharmacies" pt="md">
          <Paper p="md" mb="md">
            <PharmacyDiscountGroupEditor />
          </Paper>
          <Paper p="md">
            <PharmacyDiscountGroups />
          </Paper>
        </Tabs.Panel>

        <Tabs.Panel value="coupons" pt="md">
          <Paper p="md">
            <CouponCodes />
          </Paper>
        </Tabs.Panel>
      </Tabs>
    </Layout>
  );
}

export default Discounts;
