import React from "react";
import { environment } from "../environments";
import { Stack, Group, Text, Image } from "@mantine/core";

function ProductCard({ productId, productName, productImage, productEanId }) {
  return (
    <Group>
      <Image
        withPlaceholder
        placeholder={
          <Text
            sx={{ textOverflow: "ellipsis", overflow: "hidden" }}
            align="center"
            size="xs"
          >
            {productName.split(" ")[0]}
          </Text>
        }
        fit="scale-down"
        width={30}
        height={30}
        key={productId}
        src={`${environment.imageBasePath}${productImage}`}
      />
      <Stack justify="center" spacing={0}>
        <Text size="sm" fw="bold">
          {productName}
        </Text>
        <Text size="sm" color="dimmed">
          {productEanId}
        </Text>
      </Stack>
    </Group>
  );
}

export default ProductCard;
