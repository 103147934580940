import Cookies from "universal-cookie";
import { store } from "../configure.store";
import { environment } from "../../environments";

import { SET_BASKET, RESET_BASKET } from "../slices/basket.slice";

export const fetchBasketCookie = (dispatch) => {
  const cookies = new Cookies();
  const basket = cookies.get("basket");
  if (basket) {
    dispatch({
      type: SET_BASKET,
      data: basket,
    });
  }
};

export const updateBasketCookie =
  (pharmacyId: number, id: number, value: string) => (dispatch) => {
    const cookies = new Cookies();
    const currentBasket = store.getState().basket;
    const pharma = !pharmacyId || isNaN(pharmacyId) ? 0 : pharmacyId;
    let newBasket = {};
    if (value === "0") {
      if (currentBasket[pharma]) delete currentBasket[pharma][id];
    } else {
      newBasket = {
        ...currentBasket,
        [pharma]:
          value === "0"
            ? { ...currentBasket[pharma], [id]: undefined }
            : { ...currentBasket[pharma], [id]: value },
      };
    }
    cookies.set(
      "basket",
      { ...newBasket },
      { maxAge: environment.basketCookieTtl, path: "/" }
    );
    dispatch({
      type: SET_BASKET,
      data: newBasket,
    });
  };

export const removeItemBasketCookie = (pharmacyId, id) => (dispatch) => {
  const cookies = new Cookies();
  const currentBasket = store.getState().basket;
  const pharma = !pharmacyId || isNaN(pharmacyId) ? 0 : pharmacyId;
  delete currentBasket[pharma][id];
  if (!Object.keys(currentBasket[pharma]).length) {
    delete currentBasket[pharma];
  }
  cookies.set(
    "basket",
    { ...currentBasket },
    { maxAge: environment.basketCookieTtl, path: "/" }
  );
  dispatch({
    type: SET_BASKET,
    data: currentBasket,
  });
};

export const emptyBasket = (dispatch) => {
  const cookies = new Cookies();
  const currentBasket = store.getState().basket;
  const id = cookies.get("pharmacy") ?? 0;
  delete currentBasket[id];
  cookies.set(
    "basket",
    { ...currentBasket },
    { maxAge: environment.basketCookieTtl, path: "/" }
  );
  dispatch({
    type: SET_BASKET,
    data: currentBasket,
  });
};

export const emptyFullBasket = (dispatch) => {
  dispatch({
    type: RESET_BASKET,
  });
};
