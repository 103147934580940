import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { fetchUser } from "../store/actions/user.action";

import NotFoundScreen from "./404/not-found.screen";

import OrderPage from "./order/OrderPage";
import History from "./history/History";
import Informations from "./informations/informations";
import Accounting from "./accounting/Accounting";
import Metrics from "./metrics/Metrics";
import ProductPage from "./product/ProductPage";
import Basket from "./basket/Basket";
import Discounts from "./discounts/Discounts";

// DEBUG: salesperson & admin
import Catalogue from "./catalogue/Catalogue";
import ClientAccount from "./client-account/ClientAccount";
import ClientPortfolio from "./client-portfolio/ClientPortfolio";

// Admin only
import PlatformManagement from "./platform-management/PlatformManagement";
import { fetchBasketCookie } from "../store/actions/basket.action";
import OrderRecap from "./order-recap/OrderRecapPage";
import GroupedOrders from "./grouped-orders/GroupedOrders";
import GroupedOrderRecap from "./grouped-order-recap/GroupedOrderRecap";
import AdminMetrics from "../components/Metrics/AdminMetrics";
import { isPharmacyUser } from "../utils/client.utils";

export const PagesNavigation = () => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);

  useEffect(() => {
    dispatch(fetchUser);
    dispatch(fetchBasketCookie); //DEBUG_BASKET
  }, []);

  return (
    <Router>
      <Switch>
        <Route exact path="/" component={OrderPage} />
        <Route exact path="/basket" component={Basket} />
        <Route path="/produit/:id">
          <ProductPage />
        </Route>

        {isPharmacyUser(user) ? (
          <>
            <Route exact path="/historique" component={History} />
            <Route exact path="/informations" component={Informations} />
            <Route exact path="/compta" component={Accounting} />
            <Route exact path="/mes-chiffres" component={Metrics} />
            <Route exact path="/basket" component={Basket} />
            <Route exact path="/order/:orderReference">
              <OrderRecap />
            </Route>
            <Route path="/produit/:id">
              <ProductPage />
            </Route>
          </>
        ) : null}

        {["admin"].includes(user?.Role) ? (
          <>
            <Route exact path="/compte-client" component={ClientAccount} />
            <Route
              exact
              path="/portefeuille-client"
              component={ClientPortfolio}
            />
            <Route exact path="/catalogue" component={Catalogue} />
            <Route
              exact
              path="/gestion-plateforme"
              component={PlatformManagement}
            />
            <Route exact path="/commandes-groupees" component={GroupedOrders} />
            <Route exact path="/order/:orderReference">
              <OrderRecap />
            </Route>
            <Route exact path="/commande-groupee/:groupedOrderId">
              <GroupedOrderRecap />
            </Route>
            <Route path="/reductions/:tabValue">
              <Discounts />
            </Route>
            <Route exact path="/statistiques" component={AdminMetrics} />
          </>
        ) : null}

        {["salesperson"].includes(user?.Role) ? (
          <>
            <Route exact path="/compte-client" component={ClientAccount} />
            <Route
              exact
              path="/portefeuille-client"
              component={ClientPortfolio}
            />
            <Route exact path="/order/:orderReference">
              <OrderRecap />
            </Route>
          </>
        ) : null}
      </Switch>
    </Router>
  );
};

export default PagesNavigation;
