import React from "react";
import styled from "styled-components";

import Layout from "../Layout/Layout";
import PageHeader from "../PageHeader/PageHeader";

const Section = styled.div`
  width: 100%;
  padding: 32px;
  margin-bottom: 32px;
  background-color: white;
`;

const NoPharmacyError = ({ title }) => (
  <Layout>
    <PageHeader title={title} />
    <Section>
      <p>
        Votre compte n'est relié aucune pharmacie, nous vous invitons à prendre
        contact avec le support technique.
      </p>
    </Section>
  </Layout>
);

export default NoPharmacyError;
