import { Button, Group, Loader, MultiSelect, NumberInput, Stack, TextInput } from "@mantine/core"
import { DatePickerInput } from "@mantine/dates"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import React, { useState } from "react"
import { Coupon } from "../basket/useGetCoupon"
import { get } from "../../services/utils.service"
import {
  createCoupon,
  deleteCoupon,
  getPharmacyDiscountGroups,
  updateCoupon,
} from "../../services/discounts.service"
import ProductSelector from "../discount-groups/ProductSelector"

const getCoupon = async (couponId: number) => {
  const response = await get(`/coupon-codes/${couponId}`)
  if (!response.ok) {
    throw new Error("Network response was not ok")
  }
  return await response.json()
}

function CouponCodeForm({
  coupon,
  onFormCancel,
  onFormDelete,
  onFormSubmit,
}: {
  coupon?: Coupon
  onFormSubmit: (coupon: Omit<Coupon, "products"> & { products: number[] }) => void
  onFormCancel: () => void
  onFormDelete?: (couponId) => void
}) {
  const [formValues, setFormValues] = useState({
    ...coupon,
    pharmacy_discount_groups: coupon?.pharmacy_discount_groups.map((id) => id.toString()) ?? [],
  })

  const { data: pharmacyDiscountGroups } = useQuery(
    ["pharmacyDiscountGroups"],
    getPharmacyDiscountGroups
  )

  const pharmacyDiscountGroupData =
    pharmacyDiscountGroups?.map((pharmacyDiscountGroup) => ({
      value: pharmacyDiscountGroup.id.toString(),
      label: pharmacyDiscountGroup.name,
    })) ?? []

  const onFormChange = (field, value) => {
    setFormValues((prevState) => ({
      ...prevState,
      [field]: value,
    }))
  }

  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
    formValues?.start_date ? new Date(formValues?.start_date) : null,
    formValues?.end_date ? new Date(formValues?.end_date) : null,
  ])

  const finalFormValues = {
    ...formValues,
    pharmacy_discount_groups: formValues?.pharmacy_discount_groups.map((id) => +id) ?? [],
    max_uses: formValues?.max_uses ? formValues?.max_uses : undefined,
    start_date: dateRange[0]?.toISOString(),
    end_date: dateRange[1]?.toISOString(),
    products: formValues?.products?.map((product) => +product.id) ?? [],
  }

  function onSubmit(e) {
    e.preventDefault()
    onFormSubmit(finalFormValues)
  }

  const onProductAdd = (product) => {
    onFormChange("products", [...(formValues?.products ?? []), product])
  }

  const onProductDelete = (product) => {
    onFormChange(
      "products",
      formValues?.products?.filter((p) => p.id !== product.id)
    )
  }

  return (
    <form onSubmit={onSubmit}>
      <Stack spacing="sm">
        <TextInput
          label="Code de réduction"
          description="La casse n'est pas prise en compte. Le code doit être unique."
          value={formValues?.code ?? ""}
          onChange={(e) => onFormChange("code", e.target.value)}
        />
        <NumberInput
          label="Réduction en %"
          value={formValues?.discount_amount ?? ""}
          onChange={(value) => onFormChange("discount_amount", value)}
        />
        <NumberInput
          label="Minimum d'achat en Euros"
          value={formValues?.minimum_total ?? ""}
          onChange={(value) => onFormChange("minimum_total", value)}
        />
        <DatePickerInput
          label="Date de validité"
          description="La journée de fin de validité est incluse."
          type="range"
          allowSingleDateInRange
          value={dateRange}
          onChange={setDateRange}
        />
        <NumberInput
          label="Nombre d'utilisations maximum"
          description="Une fois atteint, le code ne sera plus utilisable. Laisser vide pour aucune limite."
          value={formValues?.max_uses ?? ""}
          onChange={(value) => onFormChange("max_uses", value)}
        />
        <ProductSelector
          selectedProducts={formValues?.products ?? []}
          onAdd={onProductAdd}
          onRemove={onProductDelete}
        />
        <MultiSelect
          dropdownPosition="top"
          my="md"
          label="Groupes de réductions de pharmacies"
          data={pharmacyDiscountGroupData}
          onChange={(value) => onFormChange("pharmacy_discount_groups", value)}
          value={formValues?.pharmacy_discount_groups ?? []}
        />
      </Stack>

      <Group mt="md" position={coupon?.id ? "apart" : "right"}>
        {coupon?.id && (
          <Button variant="subtle" onClick={() => onFormDelete(coupon.id)}>
            Supprimer
          </Button>
        )}
        <Group position="right">
          <Button variant="outline" onClick={onFormCancel}>
            Annuler
          </Button>
          <Button type="submit">Valider</Button>
        </Group>
      </Group>
    </form>
  )
}

export const CreateCouponCodeForm = ({ onFormCancel }: { onFormCancel: () => void }) => {
  const queryClient = useQueryClient()

  const createCouponMutation = useMutation({
    mutationFn: (newCoupon: Omit<Coupon, "products"> & { products: number[] }) => {
      return createCoupon(newCoupon)
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["coupons"] })
      onFormCancel()
    },
  })

  const onSubmit = (coupon: Omit<Coupon, "products"> & { products: number[] }) => {
    createCouponMutation.mutate(coupon)
  }

  return <CouponCodeForm onFormSubmit={onSubmit} onFormCancel={onFormCancel} />
}

export const EditCouponCodeForm = ({
  couponId,
  onFormCancel,
}: {
  couponId: number
  onFormCancel: () => void
}) => {
  const queryClient = useQueryClient()
  const {
    data: coupon,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["coupons", couponId],
    queryFn: () => getCoupon(couponId),
  })

  const updateCouponMutation = useMutation({
    mutationFn: (coupon: Omit<Coupon, "products"> & { products: number[] }) => {
      return updateCoupon(coupon)
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["coupons"] })
      onFormCancel()
    },
  })

  const deleteCouponMutation = useMutation({
    mutationFn: (couponId: number) => {
      return deleteCoupon(couponId)
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["coupons"], exact: true })
      onFormCancel()
    },
  })

  const onSubmit = (coupon: Omit<Coupon, "products"> & { products: number[] }) => {
    updateCouponMutation.mutate(coupon)
  }

  const onDelete = () => {
    deleteCouponMutation.mutate(couponId)
  }

  if (isLoading) {
    return <Loader />
  }

  if (isError) {
    return <div>Erreur</div>
  }

  return (
    <CouponCodeForm
      coupon={coupon}
      onFormSubmit={onSubmit}
      onFormCancel={onFormCancel}
      onFormDelete={onDelete}
    />
  )
}
