import React, { useEffect, useState } from "react";
import styled from "styled-components";
import PagesNavigation from "./pages/pages.navigation";
import { useDispatch, useSelector } from "react-redux";
import { get } from "./services/utils.service";
import { GlobalFonts, CustomMantineFonts } from "./themes/fonts.theme";
import { environment } from "./environments";
import "./assets/styles/app.css";
import backgroundImage from "./assets/imgs/forest.jpeg";
import {
  LoadingOverlay,
  MantineProvider,
  Image,
  Loader,
  Center,
  Stack,
  Box,
} from "@mantine/core";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { DatesProvider } from "@mantine/dates";
import "dayjs/locale/fr";
import { fetchUser } from "./store/actions/user.action";
import Logo from "./assets/imgs/logo-shop.png";
import { useScrollLock } from "@mantine/hooks";
const Container = styled.div`
  width: 100%;
  height: 100%;
`;

const Background = styled.div`
  z-index: -1000;
  position: fixed;
  background-image: url(${backgroundImage});
  background-size: cover;
  width: 100%;
  height: 100%;
  filter: brightness(75%);
`;

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    document.title = "Leadersante Shop & Depot";
    get("/auth/is-authenticated")
      .then((response) => response.json())
      .then((res) => setIsAuthenticated(res))
      .catch(() => {
        setIsAuthenticated(false);
      });
  }, []);

  const user = useSelector((state) => state.user);

  useEffect(() => {
    dispatch(fetchUser);
  }, []);

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 1 * (60 * 1000), // 1 mins
        cacheTime: 5 * (60 * 1000), // 5 mins
      },
    },
  });

  const currentTheme = () => {
    switch (user?.Role) {
      case "admin":
        return "lime";
      case "salesperson":
        return "blueLs";
      default:
        return "orangeLs";
    }
  };

  const loading = isAuthenticated === null || !user.Role;
  useScrollLock(loading);

  if (isAuthenticated === false) {
    window.location.href = `${environment.portalUrl}/login`;
  }

  return (
    <QueryClientProvider client={queryClient}>
      <MantineProvider
        withGlobalStyles
        theme={{
          primaryColor: currentTheme(),
          fontFamily: "Century Gothic",
          colors: {
            orangeLs: [
              "#F8F5F4",
              "#E8DCD8",
              "#DCC3BB",
              "#D5AB9D",
              "#D4937D",
              "#D97959",
              "#E65E30",
              "#CC562F",
              "#A95538",
              "#8E513D",
            ],
            greenLs: [
              "#CED2BC",
              "#C0C7A3",
              "#B5C089",
              "#ADBE6D",
              "#A9C14F",
              "#A6C631",
              "#A1C51C",
              "#88A228",
              "#74872F",
              "#647232",
            ],
            blueLs: [
              "#B5C6CD",
              "#9CB8C3",
              "#83ACBC",
              "#68A3BA",
              "#4A9DBE",
              "#3197BE",
              "#1D90BD",
              "#297B9C",
              "#2F6A82",
              "#325D6D",
            ],
          },
        }}
      >
        <DatesProvider settings={{ locale: "fr" }}>
          <CustomMantineFonts />
          <div
            style={{
              height: "100dvh",
              width: "100dvw",
              position: "fixed",
              zIndex: 1000,
              pointerEvents: "none",
            }}
          >
            <LoadingOverlay
              loader={
                <Stack justify="center" align="center">
                  <Image width={200} height={200} fit="contain" src={Logo} />
                  <Loader />
                </Stack>
              }
              visible={loading}
              transitionDuration={500}
              overlayBlur={2}
              overlayOpacity={1}
              overlayColor="#254939"
            />
          </div>

          <Container>
            <Background />
            <PagesNavigation />
          </Container>
        </DatesProvider>
      </MantineProvider>
    </QueryClientProvider>
  );
}

export default App;
