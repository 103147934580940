import React, { useEffect, useState } from "react";

import { get } from "../../services/utils.service";
import { Badge, Group, Paper, Table } from "@mantine/core";
import ProductCard from "../ProductCard";
import { DatePickerInput } from "@mantine/dates";
import dayjs from "dayjs";
import { useMediaQuery } from "@mantine/hooks";

const TopProducts = () => {
  const [topProducts, setTopProducts] = useState([]);
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
    dayjs().subtract(1, "month").toDate(),
    new Date(),
  ]);

  useEffect(() => {
    if (dateRange[0] && dateRange[1]) {
      fetchTopProducts();
    }
  }, [dateRange]);

  const fetchTopProducts = async () => {
    const params = new URLSearchParams();
    params.append("start", dateRange[0].toISOString().split("T")[0]);
    params.append("end", dateRange[1].toISOString().split("T")[0]);
    const response = await get("/product/top-products?" + params);
    const topProducts = await response.json();
    setTopProducts(topProducts);
  };

  const smallHeight = useMediaQuery("(min-height: 40em)");

  return (
    <Paper w="100%">
      <Group>
        <DatePickerInput
          valueFormat="DD MMMM YYYY"
          type="range"
          dropdownType={smallHeight ? "popover" : "modal"}
          allowSingleDateInRange
          popoverProps={{ position: "bottom" }}
          maxDate={new Date()}
          value={dateRange}
          onChange={setDateRange}
        />
      </Group>
      {!topProducts || !topProducts.length ? (
        <p>Pas de produits commandés sur cette période.</p>
      ) : (
        <Table>
          <thead>
            <tr>
              <th></th>
              <th>Produit</th>
              <th>Quantité</th>
            </tr>
          </thead>
          <tbody>
            {topProducts.map((product, idx) => (
              <tr key={product.id}>
                <td>{idx + 1}</td>
                <td>
                  <ProductCard
                    productId={product.id}
                    productName={product.title}
                    productImage={product.image}
                    productEanId={product.ean_id}
                  />
                </td>
                <td>{product.qty_sold}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </Paper>
  );
};

export default TopProducts;
