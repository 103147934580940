import React, { useState, useEffect, useRef, forwardRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { fetchPharmacyList } from "../../store/actions/information.action";
import { Avatar, Group, Select, Text } from "@mantine/core";

interface ItemProps extends React.ComponentPropsWithoutRef<"div"> {
  label: string;
  cip: string;
  zipcode: string;
}

const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
  ({ label, cip, zipcode, ...others }: ItemProps, ref) => (
    <div ref={ref} {...others}>
      <Text span size="sm">
        {label}
        {zipcode ? ` (${zipcode})` : ""}
      </Text>
      <Text size="xs" opacity={0.65}>
        {cip}
      </Text>
    </div>
  )
);

const PharmacieSelector = ({ pharmacyData, pharmacies, onSelection }) => {
  const formattedPharmacyData =
    pharmacies?.map((pharmacy) => {
      return {
        value: pharmacy.PharmacyId,
        label:
          pharmacy.LegalName?.split("|")[0]?.trim() ?? "Pharmacie sans nom",
        cip: pharmacy.CIP,
        zipcode: pharmacy.ZipCode,
      };
    }) ?? [];

  return (
    <Select
      searchable
      placeholder="Choisissez une pharmacie"
      itemComponent={SelectItem}
      value={pharmacyData?.PharmacyId}
      data={formattedPharmacyData}
      onChange={(value) => onSelection(value)}
      limit={20}
      filter={(value, item) =>
        item.label.toLowerCase().includes(value.toLowerCase().trim()) ||
        item.cip?.toLowerCase().includes(value.toLowerCase().trim()) ||
        item.zipcode?.toLowerCase().includes(value.toLowerCase().trim())
      }
    />
  );
};

export default PharmacieSelector;
