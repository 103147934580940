import React, { useEffect, useState } from "react";
import { get } from "../../services/utils.service";
import { Pagination } from "@mantine/core";

const PaginateClientAccount = ({ pharmacyData, fetchPharmacyOrders }) => {
  const [perPage, setPerPage] = useState(30);
  const [totalPage, setTotalPage] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [totalElement, setTotalElement] = useState(0);

  const handlePageClick = (pageNb) => {
    setPageNumber(pageNb - 1);
  };

  const fetchTotalElement = async () => {
    const response = await get("/orders/pharmacy-count");
    const { ordersNumber } = await response.json();
    const result = Math.trunc(ordersNumber / perPage + 1);
    setTotalElement(ordersNumber);
    setTotalPage(result);
  };

  useEffect(() => {
    fetchPharmacyOrders(pageNumber, perPage);
  }, [pharmacyData]);

  useEffect(() => {
    fetchTotalElement();
  }, [pharmacyData]);

  useEffect(() => {
    fetchPharmacyOrders(pageNumber, perPage);
  }, [pageNumber]);

  if (totalPage === 1) return null;
  return <Pagination my="md" total={totalPage} onChange={handlePageClick} />;
};

export default PaginateClientAccount;
