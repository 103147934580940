import React, { useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import NoImage from "./../../assets/imgs/image.png";
import {
  EditIcon,
  SaveIcon,
  CloseIcon,
  UploadIcon,
} from "../../assets/icons/icons";
import variables from "./../../themes/variables.theme";
import { environment } from "../../environments";

import {
  updateImage,
  uploadImage,
  cancelImage,
} from "./../../store/actions/information.action";

const ImageBox = styled.div`
  width: 100%;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
`;

const ImageContent = styled.img`
  max-width: 100%;
  width: 100%;
  margin-bottom: -5px;
`;

const EmptyImage = styled.div`
  height: 300px;
  width: 100%;
  background: #eee;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
`;

const EditBox = styled.div`
  height: 32px;
  position: absolute;
  right: 16px;
  top: 16px;
  background: white;
  display: flex;
  align-items: center;
  padding: 0 10px;
  border-radius: 4px;
  z-index: 1;
  span {
    font-size: 14px;
  }
  input {
    :focus {
      outline: none;
    }
  }
  button {
    background: none;
    border: none;
    margin-left: 10px;
    padding: 0;
    cursor: pointer;
    :focus {
      outline: none;
    }
  }
`;

const PreviewBox = styled.label`
  width: 100%;
  border-radius: 4px;
  padding: 1px;
  border: 2px dashed ${variables.white};
  background-color: ${variables.dark};
  display: block;
  cursor: pointer;
  margin-bottom: 0;
  &:before {
    content: "";
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    left: 2px;
    top: 2px;
    position: absolute;
    background: ${variables.white};
  }
  * {
    pointer-events: none;
  }
  div {
    margin-bottom: 0;
  }
  .upload-button {
    position: absolute;
    width: 100px;
    height: 100px;
    background: none;
    border: none;
    padding: 0;
    z-index: 1;
    top: calc(50% - 50px);
    left: calc(50% - 50px);
    :focus {
      outline: none;
    }
    svg {
      width: 60px;
      height: 60px;
    }
  }
  input {
    display: none;
  }
  :hover {
    img,
    div {
      opacity: 0.7;
    }
    .upload-button {
      cursor: pointer;
      svg {
        width: 62px;
        height: 62px;
        path {
          fill: ${variables.dark};
        }
      }
    }
  }
`;

const ImageEdit = (props) => {
  const [current, setCurrent] = useState("show");
  const [object, setObject] = useState(null);
  const dispatch = useDispatch();

  const onEditHandler = (event) => {
    setCurrent("edit");
  };

  const onImageAddHandler = (event) => {
    setObject(event.target.files[0]);
    dispatch(updateImage({ key: props.imgname, file: event.target.files[0] }));
  };

  const onImageUploadHandler = (event) => {
    dispatch(uploadImage({ key: props.imgname, file: object }));
    setCurrent("show");
  };
  const onImageCancelHandler = (event) => {
    dispatch(
      cancelImage({
        key: props.imgname,
        file:
          environment.backendUrl + `/${props.parentId}/${props.imgname}.jpg`,
      })
    );
    setCurrent("show");
  };

  return (
    <>
      <ImageBox>
        <EditBox>
          <span className="image-title">{props.alt}</span>
          {
            {
              show: (
                <button onClick={onEditHandler}>
                  <EditIcon color={variables.charcoalGrey} />
                </button>
              ),
              edit: (
                <>
                  <button onClick={onImageUploadHandler}>
                    <SaveIcon color={variables.charcoalGrey} />
                  </button>
                  <button onClick={onImageCancelHandler}>
                    <CloseIcon color={variables.charcoalGrey} />
                  </button>
                </>
              ),
              default: (
                <button onClick={onEditHandler}>
                  <EditIcon color={variables.charcoalGrey} />
                </button>
              ),
            }[current]
          }
        </EditBox>
        {
          {
            show: (
              <>
                {props.src ? (
                  <Image src={props.src} alt={props.alt} />
                ) : (
                  <EmptyImage>
                    <img src={NoImage} alt="no Image" width="150" />
                  </EmptyImage>
                )}
              </>
            ),
            edit: (
              <>
                <PreviewBox htmlFor={props.id}>
                  {props.src ? (
                    <Image src={props.src} alt={props.alt} />
                  ) : (
                    <EmptyImage>
                      <img src={NoImage} alt="no Image" width="150" />
                    </EmptyImage>
                  )}
                  <button className="upload-button">
                    <UploadIcon color={variables.white} />
                  </button>
                  <input
                    type="file"
                    name="image"
                    id={props.id}
                    onChange={onImageAddHandler}
                  />
                </PreviewBox>
              </>
            ),
            default: (
              <>
                {props.src ? (
                  <Image src={props.src} alt={props.alt} />
                ) : (
                  <EmptyImage>
                    <img src={NoImage} alt="no Image" width="150" />
                  </EmptyImage>
                )}
              </>
            ),
          }[current]
        }
      </ImageBox>
    </>
  );
};

function Image(props) {
  const ImageShow = (props) => {
    return (
      <>
        <ImageBox>
          {props.src ? (
            <ImageContent src={props.src} alt={props.alt} />
          ) : (
            <EmptyImage>
              <img src={NoImage} alt="no Image" width="150" />
            </EmptyImage>
          )}
        </ImageBox>
      </>
    );
  };
  return (
    <>
      {props.editable ? (
        <ImageEdit
          parentId={props.parentId}
          src={props.src}
          alt={props.alt}
          id={props.id}
          imgname={props.imgname}
        />
      ) : (
        <ImageShow src={props.src} alt={props.alt} id={props.id} />
      )}
    </>
  );
}

export default Image;
