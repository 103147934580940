import React, { useState } from "react";

import { del, post, put } from "../../services/utils.service";
import {
  // CenteredTd,
  // Table,
  // Tbody,
  // Th,
  // Thead,
  // Tr,
  SpecialTr,
  SpecialSecondaryTr,
} from "../table/table.component";
import { CardHeader } from "../paper/paper.component";
import IconButton from "../Buttons/IconButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import {
  // CenteredTd,
  Table,
  Tbody,
  Th,
  Thead,
  Tr,
  IndentedTd,
  EditableTr,
  Td,
} from "../table/EditableTr";

const LightNote = styled.p`
  color: white;
  padding: 0 32px;
  margin-bottom: 16px;
  font-style: italic;
  font-weight: 300;
  font-size: 14px;
`;

const CenteredTdText = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 50px;
  margin: 10px;
`;

const AdminGammes = ({
  allGammes,
  allSubGammes,
  fetchAllGammes,
  fetchAllSubGammes,
}) => {
  const [insertingNewLine, setInsertingNewLine] = useState(false);
  const [insertingSecondaryNewline, setInsertingSecondaryNewline] =
    useState(false);

  const keys = ["title"];

  const canBeModified = {
    title: true,
  };

  const handleGammeUpdate = (newValue) => {
    if (!newValue?.title?.length) return;
    put("/product/gammes", newValue).then((res) => {
      fetchAllGammes();
    });
  };

  const handleGammeAdd = (values) => {
    post("/product/gammes", values).then((res) => {
      fetchAllGammes();
    });
  };

  const handleGammeDelete = (toDelete) => {
    del(`/product/gammes/${toDelete.id}`).then((res) => {
      fetchAllGammes();
    });
  };

  const handleSubGammeUpdate = (newValue) => {
    if (!newValue?.title?.length) return;
    put("/product/subgammes", newValue).then((res) => {
      fetchAllSubGammes();
    });
  };

  const handleSubGammeAdd = (newValue) => {
    post("/product/subgammes", newValue).then((res) => {
      fetchAllSubGammes();
    });
  };

  const handleSubGammeDelete = (newValue) => {
    del(`/product/subgammes/${newValue.id}`).then((res) => {
      fetchAllSubGammes();
    });
  };

  return (
    <div>
      <CardHeader>
        <span className="card-title">Gammes existantes</span>
      </CardHeader>
      <LightNote>
        Deux gammes ne peuvent pas avoir le même nom.
        <br />
        Vos modifications seront ignorées si vous réutilisez un nom existant
        lors de la création ou de la modification d'une gamme.
      </LightNote>
      <div>
        <Table className="responsive-static">
          <Thead>
            <Tr>
              <Th>Nom de gamme </Th>
              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {allGammes.map((gammes, idx: number) => (
              <>
                <EditableTr
                  key={idx}
                  className="hoverable"
                  keys={keys}
                  canBeModified={canBeModified}
                  values={gammes || {}}
                  onSave={handleGammeUpdate}
                  deleteButton
                  onDelete={handleGammeDelete}
                  gammeId={gammes.id}
                />
                {!allSubGammes[gammes.title] ? (
                  !insertingSecondaryNewline ? (
                    <SpecialSecondaryTr className="hoverable">
                      <IndentedTd>
                        <IconButton
                          onClick={() => setInsertingSecondaryNewline(true)}
                        >
                          <CenteredTdText>
                            <FontAwesomeIcon icon={faPlusCircle} />
                          </CenteredTdText>
                        </IconButton>
                      </IndentedTd>
                      <Td></Td>
                    </SpecialSecondaryTr>
                  ) : (
                    <EditableTr
                      className="hoverable"
                      keys={keys}
                      canBeModified={canBeModified}
                      values={{}}
                      onSave={handleSubGammeAdd}
                      gammeId={gammes.id}
                      onCancel={() => setInsertingSecondaryNewline(false)}
                      editByDefault
                    />
                  )
                ) : null}
                {allSubGammes[gammes.title]?.map((subGammes, idx: number) => {
                  if (idx === allSubGammes[gammes.title]?.length - 1)
                    return !insertingSecondaryNewline ? (
                      <>
                        <EditableTr
                          key={idx}
                          className="hoverable"
                          indented={true}
                          keys={keys}
                          canBeModified={canBeModified}
                          values={subGammes || {}}
                          onSave={handleSubGammeUpdate}
                          gammeId={gammes.id}
                          deleteButton
                          onDelete={handleSubGammeDelete}
                        />
                        <SpecialSecondaryTr className="hoverable">
                          <IndentedTd>
                            <IconButton
                              onClick={() => setInsertingSecondaryNewline(true)}
                            >
                              <CenteredTdText>
                                <FontAwesomeIcon icon={faPlusCircle} />
                              </CenteredTdText>
                            </IconButton>
                          </IndentedTd>
                          <Td></Td>
                        </SpecialSecondaryTr>
                      </>
                    ) : (
                      <EditableTr
                        className="hoverable"
                        keys={keys}
                        canBeModified={canBeModified}
                        values={{}}
                        onSave={handleSubGammeAdd}
                        gammeId={gammes.id}
                        onCancel={() => setInsertingSecondaryNewline(false)}
                        editByDefault
                      />
                    );
                  return (
                    <EditableTr
                      key={idx}
                      className="hoverable"
                      indented={true}
                      keys={keys}
                      canBeModified={canBeModified}
                      values={subGammes || {}}
                      onSave={handleSubGammeUpdate}
                      gammeId={gammes.id}
                      deleteButton
                      onDelete={handleSubGammeDelete}
                    />
                  );
                })}
              </>
            ))}
            {!insertingNewLine ? (
              <SpecialTr className="hoverable">
                <Td>
                  <IconButton onClick={() => setInsertingNewLine(true)}>
                    <CenteredTdText>
                      <h4>Ajouter gamme</h4>
                    </CenteredTdText>
                    <FontAwesomeIcon icon={faPlusCircle} />
                  </IconButton>
                </Td>
                <Td></Td>
              </SpecialTr>
            ) : (
              <EditableTr
                className="hoverable"
                keys={keys}
                canBeModified={canBeModified}
                values={{}}
                onSave={handleGammeAdd}
                onCancel={() => setInsertingNewLine(false)}
                editByDefault
              />
            )}
          </Tbody>
        </Table>
      </div>
    </div>
  );
};

export default AdminGammes;
