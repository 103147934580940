import React, { useEffect, useState } from "react";

import Layout from "../../components/Layout/Layout";

import PageHeader from "../../components/PageHeader/PageHeader";

import {
  CenteredTd,
  CenteredTh,
  Table,
  Tbody,
  Thead,
  Tr,
} from "../../components/table/table.component";

import { get } from "../../services/utils.service";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";

const StyledTr = styled(Tr)`
  cursor: pointer;
`;

const GroupedOrderRecap = () => {
  const [ordersFromGroupedOrder, setOrdersFromGroupedOrder] = useState(null);

  const history = useHistory();

  const { groupedOrderId } = useParams<{ groupedOrderId: string }>();

  const columns = [
    "CIP",
    "Statut",
    "Référence de la commande",
    "Statut actuel de la commande",
  ];

  const getGroupedOrder = async (groupedOrderId: string) => {
    const response = await get(`/orders/groupedOrder/${groupedOrderId}`);

    const parseResponse = await response.json();

    const orders = JSON.parse(parseResponse?.orderStatusesAsJSON ?? "[]");

    setOrdersFromGroupedOrder(orders);
  };

  const handleClickOrderReference = (e, orderReference) => {
    e.preventDefault();
    e.stopPropagation();
    history.push(`/order/${orderReference}`);
  };

  useEffect(() => {
    getGroupedOrder(groupedOrderId);
  }, []);

  if (ordersFromGroupedOrder === null || ordersFromGroupedOrder.length === 0)
    return (
      <Layout>
        <></>
      </Layout>
    );
  return (
    <Layout>
      <>
        <PageHeader title={`Récap commande groupée : ${groupedOrderId}`} />

        <Table className="responsive-static">
          <Thead>
            <Tr>
              {columns.map((h) => (
                <CenteredTh key={h}>{h}</CenteredTh>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {ordersFromGroupedOrder.map((ot) => (
              <StyledTr
                onClick={(e) => handleClickOrderReference(e, ot.orderReference)}
                key={ot.id}
              >
                <CenteredTd>{ot.cip}</CenteredTd>

                <CenteredTd>{ot.statusText.toUpperCase()}</CenteredTd>

                <CenteredTd>
                  {ot.orderReference ? (
                    <div>{`${ot.orderReference}`}</div>
                  ) : null}
                </CenteredTd>

                <CenteredTd>
                  {ot.orderReference ? <div>{ot.intitule_status}</div> : null}
                </CenteredTd>
              </StyledTr>
            ))}
          </Tbody>
        </Table>
      </>
    </Layout>
  );
};

export default GroupedOrderRecap;
