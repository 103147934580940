import React, { useState } from "react";
import styled from "styled-components";
import variables from "./../../themes/variables.theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faSave,
  faPen,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";

const Table = styled.table`
  width: 100%;
  border-spacing: 0;
  &.responsive-static {
    @media (max-width: 991px) {
      // width: 800px;
    }
  }
`;
const Thead = styled.thead`
  text-align: left;
`;
const Tbody = styled.tbody``;
const Tr = styled.tr`
  background: ${variables.white};
  border-bottom: 1px solid ${variables.whiteThree};
  :last-child {
    border-bottom: none;
  }
  &.hoverable:hover {
    background: ${variables.whiteFour};
  }
  &.responsive-grid {
    @media (max-width: 991px) {
      display: inline-grid;
      // width: 100%;
      .responsive-grid-item {
        display: flex;
        justify-content: space-around;
        align-items: center;
        span {
          display: inline-block;
          // width: 100px;
        }
      }
    }
  }
`;

const DarkedTr = styled(Tr)`
  background: ${variables.steel};
`;

const ClickableTr = styled(Tr)`
  cursor: pointer;
`;

const ClickableDarkedTr = styled(DarkedTr)`
  cursor: pointer;
`;

const Th = styled.th`
  border-bottom: 1px solid ${variables.whiteThree};
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.06px;
  color: ${variables.darkGrey};
  height: 46px;

  &:first-child {
    padding-left: 16px;
  }
  &:last-child {
    padding-right: 16px;
  }
`;

const SpecialTh = styled.div`
  border-bottom: 1px solid ${variables.whiteThree};
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.06px;
  color: ${variables.darkGrey};
  height: 46px;
  width: 25%;
  :first-child {
    padding-left: 32px;
  }
  :last-child {
    padding-right: 32px;
  }
`;

const CenteredTh = styled(Th)`
  text-align: center;
`;

const Td = styled.td`
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.06px;
  color: ${variables.darkGrey};
  position: relative;
  height: 46px;
  /* display: flex; */
  flex-flow: row wrap;
  /* justify-content: center; */
  /* align-items: center; */

  &:first-child {
    padding-left: 16px;
  }
  &:last-child {
    padding-right: 16px;
  }
`;

const CenteredTd = styled(Td)`
  text-align: center;
`;

const ColumnLabel = styled.span`
  font-family: "Century Gothic";
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.06px;
  color: ${variables.darkGrey};
`;

const TdInput = styled.input`
  // width: 150px;
  height: 35px;
  border-radius: 4px;
  padding: 2px 10px;
  border: 1px solid ${variables.silver};
  :focus {
    border: 1px solid ${variables.darkGrey};
    outline: none;
  }
`;

const DoubleIcon = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
`;

const IconButton = styled.button`
  background: none;
  border: none;
  position: absolute;
  right: 35px;
  top: 14px;
  color: ${variables.charcoalGrey};
  cursor: pointer;
  :focus {
    outline: none;
  }
`;

const ConfirmationButton = styled.div`
  background-color: red;
  border: 1px solid ${variables.darkGrey};
  padding: 6px;
  margin-right: 10px;
  color: white;
  font-weight: bold;
  opacity: 0.7;
  width: 97px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  &:hover {
    opacity: 1;
  }
  svg {
    path {
      fill: white;
    }
    &:hover {
      cursor: pointer;
      path {
        fill: black;
      }
    }
  }
`;

const DeleteButton = styled.div`
  background-color: red;
  border: 1px solid ${variables.darkGrey};
  padding: 6px;
  margin-right: 10px;
  color: white;
  font-weight: bold;
  opacity: 0.7;
  width: 97px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  &:hover {
    cursor: pointer;
    opacity: 1;
  }
`;

const IndentedTr = styled.tr`
  padding-left: 50px;
  height: 20px;
  background: ${variables.whiteTwo};
  border-bottom: 1px solid ${variables.whiteThree};
  :last-child {
    border-bottom: none;
  }

  & td {
    padding-left: 155px;
  }

  &.hoverable:hover {
    background: #fff5eb;
    .editButton {
      display: inline-block;
    }
    td {
      color: ${variables.darkGrey};
    }
  }
  &.responsive-grid {
    @media (max-width: 991px) {
      display: inline-grid;
      width: 100%;
      .responsive-grid-item {
        display: flex;
        justify-content: space-around;
        align-items: center;
        span {
          display: inline-block;
          width: 100px;
        }
      }
    }
  }
`;

const SpecialTr = styled.tr`
  padding-left: 50px;
  height: 20px;
  background: ${variables.whiteThree};
  border-bottom: 1px solid ${variables.whiteThree};
  :last-child {
    border-bottom: none;
  }

  &.hoverable:hover {
    background: #fff5eb;
    .editButton {
      display: inline-block;
    }
    td {
      color: ${variables.darkGrey};
    }
  }
  &.responsive-grid {
    @media (max-width: 991px) {
      display: inline-grid;
      width: 100%;
      .responsive-grid-item {
        display: flex;
        justify-content: space-around;
        align-items: center;
        span {
          display: inline-block;
          width: 100px;
        }
      }
    }
  }
`;

const SpecialSecondaryTr = styled.tr`
  width: 100%;
  height: 20px;
  background: ${variables.whiteFour};
  border-bottom: 1px solid ${variables.whiteThree};
  :first-child {
    padding-left: 50px;
  }
  :last-child {
    border-bottom: none;
  }

  &.indented {
    padding-left: 55px;
  }

  &.hoverable:hover {
    background: #fff5eb;
    .editButton {
      display: inline-block;
    }
    td {
      color: ${variables.darkGrey};
    }
  }
  &.responsive-grid {
    @media (max-width: 991px) {
      display: inline-grid;
      width: 100%;
      .responsive-grid-item {
        display: flex;
        justify-content: space-around;
        align-items: center;
        span {
          display: inline-block;
          width: 100px;
        }
      }
    }
  }
`;

const SpecialTd = styled.td`
  width: 100%;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.06px;
  color: ${variables.darkGrey};
  position: relative;
  height: 46px;

  &:first-child {
    padding-left: 16px;
  }
  &:last-child {
    padding-right: 16px;
  }
`;

function EditableTr(props) {
  const {
    keys,
    canBeModified,
    values,
    onSave,
    deleteButton = false,
    onDelete = () => {},
    onCancel = () => {},
    editByDefault = false,
  } = props;

  const [edit, setEdit] = useState(editByDefault);
  const [editValues, setEditValues] = useState({});
  const [wantToDelete, setWantToDelete] = useState(false);

  const handleEditCancel = () => {
    setEditValues({});
    setEdit(false);
    onCancel();
  };

  const handleEditStart = () => {
    const editVal = keys.reduce((rslt, key) => {
      rslt[key] = values[key] || "";
      return rslt;
    }, {});
    setEditValues(editVal);
    setEdit(true);
  };

  const handleChange = (e, key) => {
    const value = e?.target?.value;
    setEditValues((val) => ({
      ...val,
      [key]: value,
    }));
  };

  const handleSave = () => {
    // DEBUG: very simple error handling
    onSave({
      ...values,
      ...editValues,
    });
    handleEditCancel();
  };

  const handleDeleteCancel = () => {
    setWantToDelete(false);
  };

  const handleDelete = () => {
    handleDeleteCancel();
    onDelete(values);
  };

  return (
    <Tr className="hoverable">
      {keys.map((key, idx) => (
        <Td key={idx}>
          {canBeModified[key] && edit ? (
            <TdInput
              value={editValues[key] || ""}
              onChange={(e) => handleChange(e, key)}
            />
          ) : (
            values[key]
          )}
        </Td>
      ))}
      <Td style={{ textAlign: "right" }}>
        {edit ? (
          <DoubleIcon>
            {deleteButton ? (
              wantToDelete === true ? (
                <ConfirmationButton>
                  <IconButton onClick={handleDelete}>
                    <FontAwesomeIcon icon={faCheck} />
                  </IconButton>
                  Sûr ?
                  <IconButton onClick={handleDeleteCancel}>
                    <FontAwesomeIcon icon={faTimes} />
                  </IconButton>
                </ConfirmationButton>
              ) : (
                <DeleteButton onClick={() => setWantToDelete(true)}>
                  SUPPRIMER
                </DeleteButton>
              )
            ) : null}
            <IconButton onClick={handleSave}>
              <FontAwesomeIcon icon={faSave} />
            </IconButton>
            <IconButton onClick={handleEditCancel}>
              <FontAwesomeIcon icon={faTimes} />
            </IconButton>
          </DoubleIcon>
        ) : (
          <IconButton className="editButton" onClick={handleEditStart}>
            <FontAwesomeIcon icon={faPen} />
          </IconButton>
        )}
      </Td>
    </Tr>
  );
}

export {
  Table,
  Thead,
  Tbody,
  Tr,
  SpecialTr,
  ClickableTr,
  DarkedTr,
  ClickableDarkedTr,
  SpecialSecondaryTr,
  IndentedTr,
  Th,
  SpecialTh,
  CenteredTh,
  Td,
  CenteredTd,
  SpecialTd,
  ColumnLabel,
  EditableTr,
};
