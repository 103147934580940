import React from "react";
import styled from "styled-components";
import { get, postFormData } from "../../services/utils.service";

import { CardHeader } from "../paper/paper.component";
import FileUploader from "../FileUploader/FileUploader";

const LightNote = styled.p`
  color: white;
  padding: 0 32px;
  margin-bottom: 16px;
  font-weight: 300;
  font-size: 14px;
  font-style: italic;
`;

const FileUploaderContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
`;

const AdminCatalogue = (props) => {
  const { catalogueInformations, fetchCatalogueInformations } = props;

  const uploadFile = async (groupement: string, file) => {
    try {
      const groupementLink = {
        LS: 1,
        CP: 3,
        AS: 2,
        GP: 4,
      };

      const data = new FormData();
      data.append("file", file);

      const response = await postFormData(
        `/catalogue/upload/${groupementLink[groupement]}`,
        data
      );
      const parseResponse = await response.json();

      if (parseResponse) await fetchCatalogueInformations();
    } catch (error) {
      console.error(error); //DEBUG: handle this error
    }
  };

  return (
    <>
      <CardHeader>
        <span className="card-title">Catalogue</span>
      </CardHeader>
      <div style={{ margin: "10px" }}></div>
      <LightNote>Seuls les fichiers pdf seront acceptés.</LightNote>
      <FileUploaderContainer>
        <div>
          <FileUploader
            handleFile={(fileUploaded) => uploadFile("LS", fileUploaded)}
            informations={catalogueInformations["ls"]}
            buttonText={"Téléverser un catalogue LS"}
          />
        </div>
        <div>
          <FileUploader
            handleFile={(fileUploaded) => uploadFile("CP", fileUploaded)}
            informations={catalogueInformations["cp"]}
            buttonText={"Téléverser un catalogue CP"}
          />
        </div>
        <div>
          <FileUploader
            handleFile={(fileUploaded) => uploadFile("AS", fileUploaded)}
            informations={catalogueInformations["as"]}
            buttonText={"Téléverser un catalogue AS"}
          />
        </div>
        <div>
          <FileUploader
            handleFile={(fileUploaded) => uploadFile("GP", fileUploaded)}
            informations={catalogueInformations["gp"]}
            buttonText={"Téléverser un catalogue GP"}
          />
        </div>
      </FileUploaderContainer>
    </>
  );
};

export default AdminCatalogue;
