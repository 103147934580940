import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faSave,
  faPen,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import variables from "../../themes/variables.theme";
import { IndentedTr } from "./table.component";
import Checkbox from "../forms/checkbox.component";
import { formatUSDate } from "../../utils/dates.utils";

const Table = styled.table`
  width: 50%;
  border-spacing: 0;
  &.responsive-static {
    @media (max-width: 991px) {
      width: 800px;
    }
  }
`;
const Thead = styled.thead`
  text-align: left;
`;
const Tbody = styled.tbody``;
const Tr = styled.tr`
  height: 10px;
  background: ${variables.white};
  border-bottom: 1px solid ${variables.whiteThree};
  :last-child {
    border-bottom: none;
  }

  &.hoverable:hover {
    background: #fff5eb;
    .editButton {
      display: inline-block;
    }
    td {
      color: ${variables.darkGrey};
    }
  }
  &.responsive-grid {
    @media (max-width: 991px) {
      display: inline-grid;
      width: 100%;
      .responsive-grid-item {
        display: flex;
        justify-content: space-around;
        align-items: center;
        span {
          display: inline-block;
          width: 100px;
        }
      }
    }
  }
`;

const Th = styled.th`
  border-bottom: 1px solid ${variables.whiteThree};
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.06px;
  color: ${variables.darkGrey};
  height: 46px;
  width: 25%;
  :first-child {
    padding-left: 32px;
  }
  :last-child {
    padding-right: 32px;
  }
`;
const Td = styled.td`
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.06px;
  color: ${variables.darkGrey};
  width: 25%;
  position: relative;
  height: 46px;

  :first-child {
    padding-left: 32px;
  }
  :last-child {
    padding: 0 15px;
    @media (max-width: 991px) {
      padding-right: 70px;
    }
  }
  @media (max-width: 991px) {
    width: 200px;
  }
`;

const IndentedTd = styled(Td)`
  &&& {
    :first-child {
      height: 15px;
      padding-left: 80px;
    }
  }
`;

const CenteredTd = styled(Td)`
  display: flex;
  align-items: center;
  align-content: center;
`;

const ColumnLabel = styled.span`
  font-family: "SofiaPro";
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.06px;
  color: ${variables.darkGrey};
`;

const TdInput = styled.input`
  width: 150px;
  height: 20px;
  border-radius: 4px;
  padding: 2px 10px;
  border: 1px solid ${variables.silver};
  :focus {
    border: 1px solid ${variables.darkGrey};
    outline: none;
  }
`;

const SpecialTd = styled.td`
  width: 100%;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.06px;
  color: ${variables.darkGrey};
  position: relative;
  height: 20px;

  &:first-child {
    padding-left: 16px;
  }
  &:last-child {
    padding-right: 16px;
  }
`;

const IconButton = styled.button`
  background: none;
  border: none;
  color: ${variables.charcoalGrey};
  cursor: pointer;
  :focus {
    outline: none;
  }
  :hover {
    color: ${variables.darkGrey};
  }
`;
const DoubleIcon = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
`;

const DeleteButton = styled.div`
  background-color: red;
  border: 1px solid ${variables.darkGrey};
  padding: 6px;
  margin-right: 10px;
  color: white;
  font-weight: bold;
  opacity: 0.7;
  width: 97px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  &:hover {
    cursor: pointer;
    opacity: 1;
  }
`;

const ConfirmationButton = styled.div`
  background-color: red;
  border: 1px solid ${variables.darkGrey};
  padding: 6px;
  margin-right: 10px;
  color: white;
  font-weight: bold;
  opacity: 0.7;
  width: 97px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  &:hover {
    opacity: 1;
  }
  svg {
    path {
      fill: white;
    }
    &:hover {
      cursor: pointer;
      path {
        fill: black;
      }
    }
  }
`;

const EditableTr = (props) => {
  const {
    indented,
    centered,
    keys,
    canBeModified,
    values,
    gammeId,
    onSave,
    deleteButton = false,
    onDelete = () => {},
    onCancel = () => {},
    editByDefault = false,
  } = props;

  const [edit, setEdit] = useState(editByDefault);
  const [editValues, setEditValues] = useState({});
  const [wantToDelete, setWantToDelete] = useState(false);

  const handleEditCancel = () => {
    setEditValues({});
    setEdit(false);
    onCancel();
  };

  const handleEditStart = () => {
    const editVal = keys.reduce((rslt, key) => {
      rslt[key.name ?? key] = values[key.name ?? key] ?? "";
      return rslt;
    }, {});
    setEditValues(editVal);
    setEdit(true);
  };

  const handleChange = (e, key) => {
    let value = undefined;
    switch (key.inputType ?? "text") {
      case "checkbox":
        value = e?.target?.checked;
        break;
      case "date":
        value = formatUSDate(new Date(e?.target?.value));
        break;
      case "number":
        value = parseInt(e?.target?.value);
        break;
      default:
        value = e?.target?.value;
    }
    setEditValues((val) => ({
      ...val,
      [key.name || key]: value,
    }));
  };

  const formatValues = (oldValues) => {
    const formatted = { ...oldValues };
    keys.map((key) => {
      const value = undefined;
      if (formatted[key.name ?? key]) {
        switch (key.inputType ?? "text") {
          case "checkbox":
            // return Boolean(formatted[key.name ?? key]);
            formatted[key.name ?? key] = Boolean(formatted[key.name ?? key]);
            break;
          case "date":
            // return formatUSDate(new Date(formatted[key.name ?? key]));
            formatted[key.name ?? key] = formatUSDate(
              new Date(formatted[key.name ?? key])
            );
            break;
          case "number":
            // return parseInt(formatted[key.name ?? key]);
            formatted[key.name ?? key] = formatted[key.name ?? key]
              ? parseInt(formatted[key.name ?? key])
              : 0;
            break;
        }
      }
    });
    return formatted;
  };

  const handleSave = () => {
    onSave({
      ...values,
      ...editValues,
      rangeId: gammeId ? gammeId : null,
    });
    handleEditCancel();
  };

  const handleDeleteCancel = () => {
    setWantToDelete(false);
  };

  const handleDelete = () => {
    handleDeleteCancel();
    onDelete(values);
  };

  const TrComponent = indented ? IndentedTr : Tr;
  const TdComponent = indented ? IndentedTd : centered ? CenteredTd : Td;

  return (
    <TrComponent className="hoverable">
      {keys.map((key, idx) => (
        <TdComponent key={idx}>
          {canBeModified[key.name ?? key] && edit ? (
            <TdInput
              type={key.inputType ?? "text"}
              value={formatValues(editValues)[key.name ?? key]}
              onChange={(e) => handleChange(e, key)}
              checked={
                key.inputType === "checkbox" && editValues[key.name ?? key]
              }
            />
          ) : (
            formatValues(values)[key.name ?? key]
          )}
        </TdComponent>
      ))}
      <TdComponent style={{ textAlign: "right" }}>
        {edit ? (
          <DoubleIcon>
            {deleteButton ? (
              wantToDelete === true ? (
                <ConfirmationButton>
                  <IconButton onClick={handleDelete}>
                    <FontAwesomeIcon icon={faCheck} />
                  </IconButton>
                  Sûr ?
                  <IconButton onClick={handleDeleteCancel}>
                    <FontAwesomeIcon icon={faTimes} />
                  </IconButton>
                </ConfirmationButton>
              ) : (
                <DeleteButton onClick={() => setWantToDelete(true)}>
                  SUPPRIMER
                </DeleteButton>
              )
            ) : null}
            <IconButton onClick={handleSave}>
              <FontAwesomeIcon icon={faSave} />
            </IconButton>
            <IconButton onClick={handleEditCancel}>
              <FontAwesomeIcon icon={faTimes} />
            </IconButton>
          </DoubleIcon>
        ) : (
          <IconButton className="editButton" onClick={handleEditStart}>
            <FontAwesomeIcon icon={faPen} />
          </IconButton>
        )}
      </TdComponent>
    </TrComponent>
  );
};

export {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  SpecialTd,
  ColumnLabel,
  ConfirmationButton,
  DeleteButton,
  IconButton,
  IndentedTr,
  IndentedTd,
  EditableTr,
};
