import { store } from "../configure.store";
import { SET_FILTERS, RESET_FILTERS } from "../slices/filter.slice";

export const updateFilters = (filter: string) => (dispatch) => {
  const currentFilters = store.getState().filters;

  if (!currentFilters.includes(filter)) {
    const newFilters = currentFilters.concat(filter);
    dispatch({
      type: SET_FILTERS,
      data: newFilters,
    });
  }
};

export const removeFilter = (filter) => (dispatch) => {
  const currentFilters = store.getState().filters;
  const newFilters = currentFilters.filter((value) => value !== filter);
  dispatch({
    type: SET_FILTERS,
    data: newFilters,
  });
};

export const emptyFilters = (dispatch) => {
  dispatch({
    type: RESET_FILTERS,
  });
};
