import React, { useEffect, useState } from "react";

import Layout from "../../components/Layout/Layout";
import PageHeader from "../../components/PageHeader/PageHeader";

import AdminGammes from "../../components/AdminGammes/AdminGammes";
import AdminFrancos from "../../components/AdminFrancos/AdminFrancos";
import AdminCatalogue from "../../components/AdminCatalogue/AdminCatalogue";
import AdminBanner from "../../components/AdminBanner/AdminBanner";
import CovidOrders from "../../components/CovidOrders/CovidOrders";

import { get } from "../../services/utils.service";
import { formatUSDateHHMM } from "../../utils/dates.utils";

const PlatformManagement = () => {
  const [allGammes, setAllGammes] = useState([]);
  const [allSubGammes, setAllSubGammes] = useState({});
  const [groupements, setGroupements] = useState([]);
  const [catalogueInformations, setCatalogueInformations] = useState({});
  const [bannerInformations, setBannerInformations] = useState({});

  const fetchAllGammes = () => {
    get("/product/gammes")
      .then((response) => response.json())
      .then((res) => {
        const gammes = res.map((gamme) => {
          return {
            ...gamme,
          };
        });
        setAllGammes(gammes);
      });
  };

  const fetchAllSubGammes = () => {
    get("/product/subgammes")
      .then((response) => response.json())
      .then((res) => {
        const subGammes = res?.map((subGamme) => {
          return {
            id: subGamme.subFamilyId,
            rangeId: subGamme.subRangeId,
            title: subGamme.subFamilyTitle,
            rangeTitle: subGamme.subRangeTitle,
          };
        });
        const result = {};
        for (const subGamme of subGammes) {
          result[subGamme.rangeTitle] = result[subGamme.rangeTitle]
            ? [...result[subGamme.rangeTitle], subGamme]
            : [subGamme];
        }
        setAllSubGammes(result);
      });
  };

  const fetchAllFrancosAmounts = async () => {
    const response = await get("/francos");
    const parseResponse = await response.json();
    const francos = parseResponse.map((franco) => {
      if (!franco["amount"]) franco.amount = "Non specifié";
      return franco;
    });
    setGroupements(francos);
  };

  const fetchCatalogueInformations = async () => {
    const response = await get("/catalogue/informations");
    const infos = await response.json();
    console.log(infos);
    setCatalogueInformations(infos);
  };

  const fetchBannerInformations = async () => {
    const response = await get("/banner/informations");
    const parseResponse = await response.json();
    const infos = {
      filename: parseResponse.filename,
      date: formatUSDateHHMM(new Date(parseResponse.upload_date)),
      extension: parseResponse.extension,
    };
    setBannerInformations(infos);
  };

  useEffect(() => {
    fetchAllGammes();
    fetchAllSubGammes();
    fetchAllFrancosAmounts();
    fetchCatalogueInformations();
    fetchBannerInformations();
  }, []);

  return (
    <Layout>
      <PageHeader title="Gestion de la Plateforme" />
      <CovidOrders />
      <div style={{ marginBottom: "30px" }}></div>
      <AdminBanner
        fetchBannerInformations={fetchBannerInformations}
        bannerInformations={bannerInformations}
      />
      <AdminCatalogue
        fetchCatalogueInformations={fetchCatalogueInformations}
        catalogueInformations={catalogueInformations}
      />
      <div style={{ marginBottom: "30px" }}></div>
      <AdminFrancos
        groupements={groupements}
        fetchAllFrancosAmounts={fetchAllFrancosAmounts}
      />
      <div style={{ marginBottom: "30px" }}></div>
      <AdminGammes
        allGammes={allGammes}
        allSubGammes={allSubGammes}
        fetchAllGammes={fetchAllGammes}
        fetchAllSubGammes={fetchAllSubGammes}
      />
    </Layout>
  );
};

export default PlatformManagement;
