import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { get, postFormData, put } from "../../services/utils.service";

import { CardHeader } from "../paper/paper.component";
import FileUploader from "../FileUploader/FileUploader";
import variables from "../../themes/variables.theme";
import { selectTheme } from "../../utils/theme.utils";
import { useSelector } from "react-redux";

const LightNote = styled.p`
  color: white;
  padding: 0 32px;
  margin-bottom: 16px;
  font-weight: 300;
  font-size: 14px;
  font-style: italic;
`;

const BannerLinkInput = styled.input`
  font-size: 20px;
  color: #131415;
  margin-bottom: 16px;
  margin-left: 15px;
`;

const Button = styled.button<{ color: string }>`
  width: 250px;
  height: 35px;
  border-radius: 20px;
  background-color: ${(props) => props.color};
  color: ${variables.white};
  margin-left: 15px;
  margin-top: 0;
`;

const AdminBanner = (props) => {
  const { bannerInformations, fetchBannerInformations } = props;

  const user = useSelector((state) => state.user);

  const [linkInput, setLinkInput] = useState("");
  const [linkChanged, setLinkChanged] = useState(false);
  const [printChanged, setPrintChanged] = useState(false);

  const uploadFile = async (file) => {
    try {
      const data = new FormData();
      data.append("file", file);

      const response = await postFormData("/banner/upload", data);
      const parseResponse = await response.json();

      if (parseResponse) {
        await fetchBannerInformations();
      }
    } catch (error) {
      console.error(error); //DEBUG: handle this error
    }
  };

  const handleChangeLinkInput = (event) => {
    setLinkInput(event.target.value);
  };

  const handleOnClickChange = async (event) => {
    const result = await put(`/banner/link/${linkInput}`, {});
    setLinkChanged(!linkChanged);
  };

  useEffect(() => {
    if (!linkInput.length) return;
    setPrintChanged(true);
    const timer = setTimeout(() => {
      setPrintChanged(false);
    }, 2000);
    return () => {
      clearTimeout(timer);
    };
  }, [linkChanged]);

  return (
    <>
      <CardHeader>
        <span className="card-title">Banniere</span>
      </CardHeader>
      <div style={{ margin: "10px" }}></div>
      <LightNote>Seuls les fichiers png et jpeg seront acceptés.</LightNote>
      <FileUploader
        handleFile={uploadFile}
        informations={bannerInformations}
        buttonText={"Téléverser une nouvelle bannière"}
      />
      <LightNote>
        Entrez l'identifiant ean d'un produit et cliquez sur "changer de lien"
        pour changer le lien de la banniere
      </LightNote>
      <BannerLinkInput
        type="text"
        name="banner-link-input"
        value={linkInput}
        onChange={handleChangeLinkInput}
      />
      <Button onClick={handleOnClickChange} color={selectTheme(user.Role)}>
        Changer de lien
      </Button>
      {printChanged ? <p style={{ color: "white" }}>Lien changé !</p> : null}
    </>
  );
};

export default AdminBanner;
