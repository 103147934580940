import { useMutation } from '@tanstack/react-query'
import { get } from '../../services/utils.service'

export type Coupon = {
  id: number
  code: string
  discount_amount: number
  max_uses: number
  start_date: string
  end_date: string
  minimum_total: number
  products: {
    id: number
    title: string
  }[]
  pharmacy_discount_groups: number[]
}

const getCoupon = async (code: string): Promise<Coupon> => {
  const response = await get(`/coupon-codes/check/${code}`)
  if (response.status === 404) {
    throw new Error('Coupon invalide')
  }
  if (!response.ok) {
    throw new Error('Erreur')
  }
  return response.json()
}

export const useGetCoupon = () => {
  return useMutation({
    mutationFn: (code: string) => getCoupon(code),
  })
}
