import {
  SET_DEFAULT_INFORMATION_SETTINGS,
  SET_INFORMATION_SETTINGS,
  SET_PHARMACIES_LIST,
  CHANGE_IMAGE,
  UPLOAD_IMAGE,
  CANCEL_IMAGE,
  SET_SCHEDULE,
  SET_UNIVERS,
  SET_SERVICES,
  UPDATE_PHARMACY_DATA,
  RESET_PHARMACY_DATA,
} from "../actions/information.action";

const initialState = {
  informationState: {},
};

const InformationReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_DEFAULT_INFORMATION_SETTINGS:
      return {
        ...state,
        informationState: {
          ...state.informationState,
          ...action.data,
        },
      };
    case SET_INFORMATION_SETTINGS:
      return {
        ...state,
        informationState: {
          ...state.informationState,
          ...action.data,
        },
      };
    case UPDATE_PHARMACY_DATA:
      return {
        ...state,
        informationState: {
          ...state.informationState,
          ...action.data,
        },
      };
    case RESET_PHARMACY_DATA:
      return {
        ...state,
        informationState: {},
      };
    case CHANGE_IMAGE || UPLOAD_IMAGE || CANCEL_IMAGE:
      return {
        ...state,
        informationState: {
          ...state.informationState,
          ...action.data,
        },
      };
    case SET_SCHEDULE:
      return {
        ...state,
        informationState: {
          ...state.informationState,
          ...action.data,
        },
      };
    case SET_UNIVERS:
      return {
        ...state,
        informationState: {
          ...state.informationState,
          ...action.data,
        },
      };
    case SET_SERVICES:
      return {
        ...state,
        informationState: {
          ...state.informationState,
          ...action.data,
        },
      };
    case SET_PHARMACIES_LIST:
      return {
        ...state,
        ...action.data,
      };
    default:
      return {
        ...state,
      };
  }
};

export default InformationReducer;
