export const formatDiscountLevels = (
  discountLevels: { amount: number; quantity: number }[]
) => {
  const discountLevelsFormatted = discountLevels
    .sort((a, b) => a.quantity - b.quantity)
    .filter((discountLevel) => discountLevel.quantity)
    .map(
      (discountLevel) =>
        `${discountLevel.quantity}u => ${discountLevel.amount}%\n`
    );
  return discountLevelsFormatted.join("");
};

export const roundPrice = (nbr: number): number => {
  if (nbr.toFixed) {
    return parseFloat(nbr.toFixed(2));
  } else {
    console.log(nbr);
    return 0;
  }
};

export const formatAmount = (nbr: number, withEuro = true): string => {
  if (nbr == null) return "- €";
  const roundAmount = roundPrice(nbr);
  const [head = "", tail = ""] = roundAmount.toString().split(".");
  const newTl = (tail + "00").split("").slice(0, 2).join("");
  const spacedHead = [];
  const revHead = head.split("").reverse();
  for (let i = 0; i < revHead.length; i++) {
    spacedHead.push(revHead[i]);
    if (i > 0 && (i + 1) % 3 === 0 && i < revHead.length - 1) {
      spacedHead.push(" ");
    }
  }
  return `${spacedHead.reverse().join("")},${newTl}${withEuro ? " €" : ""}`;
};

export const capitalizeFirstLetter = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const capitalizeAfterNumber = (str) => {
  if (!isNaN(str.charAt(0))) return str.charAt(0) + str.slice(1).toUpperCase();
  return str;
};

export const formatProductTitle = (title) => {
  // DEBUG
  return title
    .trim()
    .split(" ")
    .map((str) => capitalizeAfterNumber(str.toLowerCase()))
    .map((str) => capitalizeFirstLetter(str))
    .join(" ");
};
