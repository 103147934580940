import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useHistory } from "react-router-dom";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faArrowRight } from "@fortawesome/free-solid-svg-icons";

import ProductTitleEan from "../../Products/ProductTitleEan/ProductTitleEan";

import { setMark } from "./../../../store/actions/product-mark.action";
import {
  emptyFilters,
  updateFilters,
} from "./../../../store/actions/filter.action";
import { environment } from "../../../environments";
import variables from "../../../themes/variables.theme";
import { selectTheme } from "../../../utils/theme.utils";

const Wrapper = styled.div<{ color: string }>`
  position: relative;
  cursor: text;
  background-color: ${(props) => props.color};
`;

const SearchBar = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;

  padding-left: 16px;
  svg {
    height: 10px;
    color: white;
    margin-right: 8px;
  }
`;

const Search = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
`;

const SearchIcon = styled.div<{ color: string }>`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;

  border-left: 1px solid white;

  cursor: pointer;
  padding: 11px 11px;
  svg {
    margin: 0;
  }

  &:hover {
    background-color: ${(props) => props.color};
  }
`;

const Input = styled.input<{ color: string }>`
  height: fit-content;
  border: none;

  background-color: ${(props) => props.color};
  color: white;

  font-size: 12px;

  &::placeholder {
    color: white;
    font-size: 12px;
  }

  &:focus {
    outline: none;
    &::placeholder {
      color: transparent;
    }
  }
`;

const ResultPane = styled.div`
  position: absolute;
  top: 38px;
  left: 0;
  background-color: white;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  max-height: 50vh;
  width: 100%;
  overflow-y: scroll;
`;

const Suggestion = styled.div<{ selected?: boolean }>`
  cursor: pointer;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  font-size: 14px;
  padding: 8px;

  background-color: ${({ selected }) => (selected ? "#d8d8d8" : "white")};

  &:hover {
    background-color: #f2f2f2;
  }
`;

const Image = styled.div`
  width: 20px;
  height: 20px;
  margin-right: 8px;
  img {
    max-height: 100%;
    max-width: 100%;
  }
`;

const SearchInput = () => {
  const MIN_SEARCH_CHAR = 3;

  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const user = useSelector((state) => state.user);
  const products = useSelector((state) => state.products.values);

  const wrapperRef = useRef(null);
  const searchRef = useRef(null);
  const inputRef = useRef(null);
  const resultsRef = useRef(null);

  const [value, setValue] = useState("");
  const [shortList, setShortList] = useState([]);
  const [selectedPos, setSelectedPos] = useState(0);

  const changeSortList = (newList) => {
    setSelectedPos(0);
    setShortList(newList);
  };

  const handleResetSearch = useCallback(() => {
    setValue("");
    changeSortList([]);
    inputRef?.current?.blur();
  }, []);

  useEffect(() => {
    const onClick = (e) => {
      if (!wrapperRef.current || !wrapperRef.current.contains(e.target)) {
        handleResetSearch();
      }
    };
    window.addEventListener("click", onClick);
    return () => {
      window.removeEventListener("click", onClick);
    };
  }, [wrapperRef, handleResetSearch]);

  const handleWrapperClick = (e) => {
    if (!resultsRef.current || !resultsRef.current.contains(e.target)) {
      inputRef?.current?.focus();
    }
  };

  const handleOnChange = (e) => {
    const val = e.target.value.toLowerCase();
    setValue(val);
    if (val.length < MIN_SEARCH_CHAR) {
      changeSortList([]);
    } else {
      changeSortList(
        products?.filter(
          (product) =>
            (product.ean_id.includes(val) ||
              product.product_title.toLowerCase().includes(val)) &&
            product.sub_range_title
        ) ?? []
      );
    }
  };

  const handleSuggestionClick = (type, element) => {
    handleFilterValidation(element.ean_id);
    handleResetSearch();
  };

  const handleFilterValidation = (searchValue) => {
    if (searchValue?.length) {
      dispatch(emptyFilters);
      dispatch(updateFilters(searchValue));
      if (location.pathname !== "/") {
        history.push("/");
      }
    }
    handleResetSearch();
  };

  const makeSureSelectedInView = useCallback((idx) => {
    if (idx < 0) return;
    const parentBounding = resultsRef.current.getBoundingClientRect();
    const child = resultsRef.current.childNodes[idx];
    const childBounding =
      resultsRef.current.childNodes[idx].getBoundingClientRect();
    if (parentBounding.top > childBounding.top) {
      child.parentNode.scrollTop = child.offsetTop;
    } else if (parentBounding.bottom < childBounding.bottom) {
      child.parentNode.scrollTop =
        child.offsetTop + child.offsetHeight - parentBounding.height;
    }
  }, []);

  useEffect(() => {
    if (!shortList.length) return;
    makeSureSelectedInView(selectedPos - 1);
  }, [selectedPos, shortList, makeSureSelectedInView]);

  const handleKey = (e) => {
    switch (e.key) {
      case "Escape":
        handleResetSearch();
        break;
      case "Enter":
        if (selectedPos > shortList.length || selectedPos < 0) break;
        if (selectedPos === 0) {
          handleFilterValidation(value);
          break;
        }
        const product = shortList[selectedPos - 1];
        handleSuggestionClick("product", product);
        break;
      case "ArrowUp":
        if (!shortList.length) break;
        setSelectedPos((idx) => (idx === 0 ? shortList.length : idx - 1));
        break;
      case "ArrowDown":
        if (!shortList.length) break;
        setSelectedPos((idx) => (idx + 1 > shortList.length ? 0 : idx + 1));
        break;
      default:
        break;
    }
  };

  return (
    <Wrapper ref={wrapperRef} color={selectTheme(user.Role)}>
      <SearchBar>
        <Search ref={searchRef} onClick={handleWrapperClick}>
          <FontAwesomeIcon icon={faSearch} />
          <Input
            ref={inputRef}
            placeholder="Recherche d'un EAN ou intitulé"
            size={38}
            onChange={handleOnChange}
            value={value}
            onKeyDown={handleKey}
            color={selectTheme(user.Role)}
          />
        </Search>
        <SearchIcon
          onClick={() => handleFilterValidation(value)}
          color={selectTheme(user.Role)}
        >
          <FontAwesomeIcon icon={faArrowRight} />
        </SearchIcon>
      </SearchBar>
      {!shortList || !shortList.length ? null : (
        <ResultPane ref={resultsRef} onBlur={handleResetSearch}>
          {shortList.map((e, idx) => (
            <Link to="/" key={idx}>
              <Suggestion
                onClick={() => handleSuggestionClick("product", e)}
                selected={idx === selectedPos - 1}
              >
                <Image>
                  {!e.image_path ? null : (
                    <img
                      src={`${environment.imageBasePath}${e.image_path}`}
                      alt={`${e.product_title}`}
                    />
                  )}
                </Image>
                <ProductTitleEan title={e.product_title} ean={e.ean_id} />
              </Suggestion>
            </Link>
          ))}
        </ResultPane>
      )}
    </Wrapper>
  );
};

export default SearchInput;
