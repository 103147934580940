import { createGlobalStyle } from "styled-components";

import CenturyGothic from "./../assets/fonts/CenturyGothic.woff";
import CenturyGothicBold from "./../assets/fonts/CenturyGothicBold.woff";

export const GlobalFonts = createGlobalStyle`
  @font-face {
    font-family: 'Century Gothic';
    src: url(${CenturyGothic}) format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Century Gothic';
    src: url(${CenturyGothicBold}) format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }
`;

import { Global } from "@mantine/core";
import React from "react";

export function CustomMantineFonts() {
  return (
    <Global
      styles={[
        {
          "@font-face": {
            fontFamily: "Century Gothic",
            src: `url(${CenturyGothic}) format('woff')`,
            fontWeight: "normal",
            fontStyle: "normal",
            fontDisplay: "swap",
          },
        },
        {
          "@font-face": {
            fontFamily: "Century Gothic",
            src: `url(${CenturyGothicBold}) format('woff');`,
            fontWeight: "bold",
            fontStyle: "normal",
            fontDisplay: "swap",
          },
        },
      ]}
    />
  );
}
