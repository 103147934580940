import { get, post, postFile, put } from "./../../services/utils.service";

export const SET_DEFAULT_INFORMATION_SETTINGS = "SET_DEFAULT_LAYOUT_SETTINGS";
export const SET_INFORMATION_SETTINGS = "SET_LAYOUT_SETTINGS";
export const SET_PHARMACIES_LIST = "SET_PHARMACIES_LIST";
export const CHANGE_IMAGE = "CHANGE_IMAGE";
export const UPLOAD_IMAGE = "UPLOAD_IMAGE";
export const CANCEL_IMAGE = "CANCEL_IMAGE";
export const SET_SCHEDULE = "SET_SCHEDULE";
export const SET_UNIVERS = "SET_UNIVERS";
export const SET_SERVICES = "SET_SERVICES";
export const UPDATE_PHARMACY_DATA = "UPDATE_PHARMACY_DATA";
export const RESET_PHARMACY_DATA = "RESET_PHARMACY_DATA";
export const EXCEPTION_ARTISAN = "EXCEPTION_ARTISAN";

export const fetchPharmacyList = (dispatch) => {
  get("/pharmacies")
    .then((response) => response.json())
    .then((res) => {
      if (!res) return;
      const cleanedData = res
        .map((pharmacy) => {
          return {
            ...pharmacy,
            LegalName: pharmacy.LegalName?.trim(),
          };
        })
        .sort((a, b) => ("" + a.LegalName).localeCompare(b.LegalName));
      dispatch({
        type: SET_PHARMACIES_LIST,
        data: { pharmacies: cleanedData },
      });
    })
    .catch((err) => {
      return;
    });
};

export const setPharmacyIdCookie = (data) => (dispatch) => {
  post("/users/pharmacy-id", { pharmacyId: data?.pharmacyId?.toString() }).then(
    () => {
      dispatch(setDefaultInformationSettings);
    }
  );
};

export const setDefaultInformationSettings = (dispatch) => {
  get("/pharmacies/user-pharmacy")
    .then((response) => response.json())
    .then((p) => {
      p?.statusCode && p.statusCode === 404
        ? dispatch({ type: SET_DEFAULT_INFORMATION_SETTINGS, data: {} })
        : dispatch({
            type: SET_DEFAULT_INFORMATION_SETTINGS,
            data: p,
          });
    });
  // get('/responsable-universe')
  //   .then((response) => response.json())
  //   .then((res) => {
  //     dispatch({
  //       type: SET_DEFAULT_INFORMATION_SETTINGS,
  //       data: { univers: res },
  //     });
  //   });
  // get('/pharmacy-services')
  //   .then((response) => response.json())
  //   .then((res) => {
  //     dispatch({
  //       type: SET_DEFAULT_INFORMATION_SETTINGS,
  //       data: { services: res },
  //     });
  //   });
};

// DEBUG: If it crashes, what will happen?
export const setInformationSettings = (data) => (dispatch) => {
  put(`/pharmacies/${data.PharmacyId}`, {
    fullSchedule: data.fullSchedule,
  }).then((res) => {});
  put(`/responsable-universe/${data.PharmacyId}`, {
    ...data.univers,
  }).then((res) => {});
  put("/pharmacy-services", { ...data.services }).then((res) => {});
  dispatch({
    type: SET_INFORMATION_SETTINGS,
    data: { modified: false },
  });
};

export const getPharmacyData = (data) => (dispatch) => {
  get(`/pharmacies/user-pharmacy/${data}`)
    .then((response) => response.json())
    .then((result) => {
      dispatch({
        type: UPDATE_PHARMACY_DATA,
        data: result,
      });
    });
};

export const updateImage = (data) => (dispatch) => {
  const key = data.key;
  const filePath = URL.createObjectURL(data.file);
  dispatch({
    type: CHANGE_IMAGE,
    data: { [key]: filePath },
  });
};

export const uploadImage = (data) => (dispatch) => {
  const key = data.key;

  if (data.file !== null) {
    const filePath = URL.createObjectURL(data.file);
    postFile("/img", [data.key, data.file]).then((res) => {
      dispatch({
        type: UPLOAD_IMAGE,
        data: { [key]: filePath },
      });
    });
  }
};
export const cancelImage = (data) => (dispatch) => {
  const key = data.key;
  const filePath = data.file;
  dispatch({
    type: CANCEL_IMAGE,
    data: { [key]: filePath },
  });
};

export const setSchedule = (data) => (dispatch) => {
  let modified = false;
  if (
    data.scheduleData[parseInt(data.dateIndex)][0][parseInt(data.timeIndex)] !==
    data.value
  ) {
    data.scheduleData[parseInt(data.dateIndex)][0][parseInt(data.timeIndex)] =
      data.value;
    modified = true;
  }
  dispatch({
    type: SET_SCHEDULE,
    data: { fullSchedule: data.scheduleData, modified: modified },
  });
};

export const setUnivers = (data) => (dispatch) => {
  dispatch({
    type: SET_SCHEDULE,
    data: { universe: data.scheduleData, modified: true },
  });
};
export const setServices = (data) => (dispatch) => {
  dispatch({
    type: SET_SERVICES,
    data: { services: data, modified: true },
  });
};
