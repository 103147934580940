import React, { useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus } from "@fortawesome/free-solid-svg-icons"
import { Button, Center, Group, Loader, Modal, Paper, Text } from "@mantine/core"
import { useDisclosure } from "@mantine/hooks"
import { useQuery } from "@tanstack/react-query"
import ErrorBox from "../../components/ErrorBox"
import { getCoupons } from "../../services/discounts.service"
import CouponCodeTable from "./CouponCodeTable"
import { CreateCouponCodeForm, EditCouponCodeForm } from "./CouponCodeForm"
import CouponCodeDetail from "./CouponCodeDetail"
import { Coupon } from "../basket/useGetCoupon"

export function CouponCodes() {
  const {
    data: coupons,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["coupons"],
    queryFn: getCoupons,
  })

  const [opened, { open, close }] = useDisclosure(false)
  const [selectedCoupon, setSelectedCoupon] = useState<Coupon>()
  const [viewDetail, setViewDetail] = useState(false)

  const onEdit = (coupon) => {
    setSelectedCoupon(coupon)
    setViewDetail(false)
    open()
  }

  const onViewDetail = (coupon) => {
    setSelectedCoupon(coupon)
    setViewDetail(true)
    open()
  }

  const onCreate = () => {
    setSelectedCoupon(undefined)
    setViewDetail(false)
    open()
  }

  const onFormCancel = () => {
    setSelectedCoupon(undefined)
    close()
  }

  if (isLoading) {
    return (
      <Center my="lg">
        <Loader />
      </Center>
    )
  }

  if (error) {
    return <ErrorBox />
  }

  return (
    <>
      <Modal
        opened={opened}
        onClose={close}
        title={viewDetail ? "Commandes" : "Modifier les coupons"}
        size="auto"
      >
        {selectedCoupon &&
          (viewDetail ? (
            <CouponCodeDetail coupon={selectedCoupon} />
          ) : (
            <EditCouponCodeForm couponId={selectedCoupon.id} onFormCancel={onFormCancel} />
          ))}
        {!selectedCoupon && <CreateCouponCodeForm onFormCancel={onFormCancel} />}
      </Modal>

      <Paper>
        <Group position="apart">
          <Text size="xl" weight={700}>
            Codes Promo
          </Text>
          <Group>
            <Button onClick={onCreate} leftIcon={<FontAwesomeIcon icon={faPlus} />}>
              Ajouter
            </Button>
          </Group>
        </Group>
        <Text mb="sm" size="sm" color="gray">
          Les codes doivent être uniques. La journée de fin de validité est incluse.
        </Text>
        <CouponCodeTable coupons={coupons} onEdit={onEdit} onViewDetail={onViewDetail} />
      </Paper>
    </>
  )
}

export default CouponCodes
