import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 32px 0;
`;

const PageTitle = styled.h4`
  font-size: 24px;
  font-weight: 500;
  color: white;
  margin: 0;
`;

const PageHeader = ({ title }) => {
  return (
    <Wrapper>
      <PageTitle>{title.toUpperCase()}</PageTitle>
    </Wrapper>
  );
};

export default PageHeader;
