import { get } from "./../../services/utils.service";

export const SET_PRODUCTS = "SET_PRODUCTS";
export const SET_ALL_PRODUCTS = "SET_ALL_PRODUCTS";
export const RESET_PRODUCTS = "RESET_PRODUCTS";

export const objectTranscript = (product) => ({
  product_id: product.productId,
  product_title: product.title,
  description: product.description,
  image_path: product.imagePath,
  ean_id: product.eanId,
  tva_percentage: product.tva,
  price_eur: product.priceEur,
  adviced_price: product.advicedPrice,
  stock: product.stock,
  restock_date: product.restock_date,
  discount_groups: product.discountGroups,
  batch_quantity: product.batchQuantity,
  activated: product.activated,
  sub_range_title: product.subRange,
  subRangeId: product.subRangeId,
  family_title: product.familyTitle,
  familyId: product.familyId,
  linkedProducts: product.linkedProducts,
});

export const fetchProducts = (setIsFetchingProducts) => (dispatch) => {
  // Fetch ACTIVATED products details & calculates orderedProducts
  get("/product")
    .then((response) => response.json())
    .then((res) => {
      if (!res) return;
      const values = res.map((product: unknown) => ({
        ...objectTranscript(product),
        // ...product,
      }));
      const orderedProducts = res.reduce((rslt, element: any) => {
        const product = objectTranscript(element);
        //console.log("product advised price: ", product);

        const subRange = product.sub_range_title;
        const family = product.family_title || "null";
        if (!subRange) return rslt;

        if (!rslt[subRange]) {
          rslt[subRange] = {
            [family]: [
              {
                ...product,
              },
            ],
          };
        } else if (rslt[subRange] && !rslt[subRange][family]) {
          rslt[subRange][family] = [
            {
              ...product,
            },
          ];
        } else {
          rslt[subRange][family].push({
            ...product,
          });
        }
        return rslt;
      }, {});
      Object.keys(orderedProducts).forEach((key) => {
        Object.keys(orderedProducts[key]).forEach((subKey) => {
          orderedProducts[key][subKey].sort((a, b) =>
            a.product_title.localeCompare(b.product_title)
          );
        });
      });
      dispatch({
        type: SET_PRODUCTS,
        data: {
          values,
          orderedProducts,
        },
      });
      setIsFetchingProducts(false);
    })
    .catch((err) => {
      console.log(err);
      setIsFetchingProducts(false);
      return;
    });

  // EXACT same logic as before, but with ALL products
  get("/product/all")
    .then((response) => response.json())
    .then((res) => {
      if (!res) return;
      const values = res.map((product: unknown) => ({
        ...objectTranscript(product),
        // ...product,
      }));
      const orderedProducts = res.reduce((rslt, element: any) => {
        const product = objectTranscript(element);
        const subRange = product.sub_range_title;
        const family = product.family_title || "null";
        if (!subRange) return rslt;

        if (!rslt[subRange]) {
          rslt[subRange] = {
            [family]: [
              {
                ...product,
              },
            ],
          };
        } else if (rslt[subRange] && !rslt[subRange][family]) {
          rslt[subRange][family] = [
            {
              ...product,
            },
          ];
        } else {
          rslt[subRange][family].push({
            ...product,
          });
        }
        return rslt;
      }, {});
      Object.keys(orderedProducts).forEach((key) => {
        Object.keys(orderedProducts[key]).forEach((subKey) => {
          orderedProducts[key][subKey].sort((a, b) =>
            a.product_title.localeCompare(b.product_title)
          );
        });
      });
      dispatch({
        type: SET_ALL_PRODUCTS,
        data: {
          allValues: values,
          allOrderedProducts: orderedProducts,
        },
      });
      setIsFetchingProducts(false);
    })
    .catch((err) => {
      console.log(err);
      setIsFetchingProducts(false);
      return;
    });
};
