import { get } from "./../../services/utils.service";

export const SET_USER = "SET_USER";
export const RESET_USER = "RESET_USER";

export const fetchUser = (dispatch) => {
  get("/users/")
    .then((response) => response.json())
    .then((res) => {
      if (!res) return;
      dispatch({
        type: SET_USER,
        data: res,
      });
    })
    .catch((err) => {
      return;
    });
};
