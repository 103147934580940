import React, { useState } from "react";
import styled from "styled-components";
import { keepBestDiscounts } from "../../utils/prices";

const MiniBoldP = styled.div`
  margin-top: 5px;
  font-size: 15px;
  font-weight: bold;
  color: #131415;
`;

const MiniP = styled.div`
  margin-top: 10px;
  font-size: 12px;
  font-weight: lighter;
  color: #131415;
`;

const DiscountGroupLi = styled.li`
  list-style: none;
  margin-top: 10px;
  padding: 5px;
  border-radius: 7px;
  background-color: #f0f0f0;
`;

const Discount = styled.div`
  margin: 16px 0;
  p {
    font-size: 16px;
  }
`;

const BoldP = styled.div`
  font-size: 19px;
  font-weight: bold;
  color: #131415;
`;

function DiscountsThresholds({ discounts }) {
  const bestDiscount = keepBestDiscounts(discounts);
  const sortedDiscounts = [...bestDiscount]
    .sort((a, b) => a.quantity - b.quantity)
    .filter((discount) => discount.amount > 0);
  const formatDiscount = (discount) =>
    `${discount.quantity > 0 ? `${discount.quantity} unités` : "Base"} : ${
      discount.amount
    }%`;

  if (sortedDiscounts.length === 0) return <></>;

  return (
    <Discount>
      <BoldP>Paliers de remise :</BoldP>
      <ul>
        {sortedDiscounts.map((discount) => {
          return (
            <li key={discount.quantity} style={{ listStyle: "none" }}>
              {formatDiscount(discount)}
            </li>
          );
        })}
      </ul>
    </Discount>
  );
}

export default DiscountsThresholds;

{
  /* return Object.keys(formattedDiscounts).map((discount) => {
            console.log(formattedDiscounts[discount])
        return formattedDiscounts[discount].map((e) => <p>{e.additional_discount}</p>)
    }) */
}
// {!basicDiscounts || !basicDiscounts.length ? null : (
//     <Discount>
//       <BoldP>Paliers de remise :</BoldP>
//       <ul>
//         {user.Role === "user"
//           ? basicDiscountsSorted[pharmacyData.PharmacyGroup]
//             ?.sort((a, b) => a.amount - b.amount)
//             ?.map((discount, idx) =>
//               !discount.id ? (
//                 <></>
//               ) : (
//                 <li key={idx} style={{ listStyle: "none" }}>
//                   {`${discount.quantity > 0
//                     ? discount.quantity
//                     : batchQuantity
//                     } unités : ${getDiscountLevel(
//                       getDiscountLevels(
//                         products,
//                         filterDiscountsByGroups(
//                           pharmacyData.DiscountGroups,
//                           discounts
//                         ),
//                         discountsThresholds,
//                         basket,
//                         product,
//                         pharmacyData.DiscountGroups ?? []
//                       ),
//                       0
//                     )}${
//                     // discount.amount - baseDiscount ?
//                     ` + ${discount.amount - baseDiscount}%`
//                     // : `%`
//                     }`}
//                 </li>
//               )
//             )
//           : Object.keys(basicDiscountsSorted).map(
//             (discountsPharmacyGroup) => {
//               const discountList =
//                 basicDiscountsSorted[discountsPharmacyGroup];
//               return (
//                 <>
//                   <li>
//                     <MiniBoldP>{discountsPharmacyGroup}</MiniBoldP>
//                     {discountList
//                       // .sort((a, b) => a.amount - b.amount)
//                       .map((discount, idx) =>
//                         !discount.id ? (
//                           <></>
//                         ) : (
//                           <p key={idx}>
//                             {`${discount.quantity > 0
//                               ? discount.quantity
//                               : batchQuantity
//                               } unités : ${discount.baseDiscount}${
//                               // discount.amount - basicDiscount ?
//                               ` + ${discount.amount}%`
//                               // : `%`
//                               }`}
//                           </p>
//                         )
//                       )
//                     }
//                   </li>
//                 </>
//               );
//             }
//           )}
//       </ul>
//     </Discount>
//   )}
