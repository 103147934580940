import { userInfo } from "os";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { put } from "../../services/utils.service";

import variables from "../../themes/variables.theme";
import { selectTheme } from "../../utils/theme.utils";

const Button = styled.button<{ color: string }>`
  width: 250px;
  height: 50px;
  background-color: ${(props) => props.color};
  color: ${variables.white};
  margin: 15px;
`;

const GammeChanger = (props) => {
  const { startingGamme, startingSubGamme, productId, gammes, gammeIds } =
    props;

  const user = useSelector((state) => state.user);

  const [gammeValue, setGammeValue] = useState(startingGamme);
  const [subGammeValue, setSubGammeValue] = useState(startingSubGamme);

  const history = useHistory();

  const handleClick = (event) => {
    event.preventDefault();
    const productsRange = {
      rangeId: gammeValue.id,
      rangeIntitule: gammeValue.intitule,
      familyId: subGammeValue.id,
      familyIntitule: subGammeValue.intitule,
    };
    put(`/product/gammes/${productId}`, productsRange).then((res) =>
      history.go(0)
    );
  };

  const handleChangeGamme = (event) => {
    event.preventDefault();
    const newGamme = {
      id: gammeIds[event.target.value],
      intitule: event.target.value,
    };
    const newSubGamme = gammes[event.target.value][0];
    setGammeValue(newGamme);
    if (!!newSubGamme) setSubGammeValue(newSubGamme);
    else setSubGammeValue({});
  };

  const handleChangeSubGamme = (event) => {
    event.preventDefault();
    const [id, intitule] = event.target.value.split(";");
    setSubGammeValue({ id: Number(id), intitule });
  };

  // console.log(gammeValue, subGammeValue)

  return (
    <>
      <Button color={selectTheme(user.Role)} onClick={handleClick}>
        Changer gamme / sous-gammes{" "}
      </Button>
      <select
        id="gamme-select"
        style={{ marginLeft: "25px" }}
        value={gammeValue.intitule ?? ""}
        onChange={handleChangeGamme}
      >
        {Object.keys(gammes)?.map((gamme, idx) => {
          return (
            <option value={`${gamme}`} key={idx}>
              {gamme}
            </option>
          );
        })}
      </select>
      <select
        id="subgamme-select"
        style={{ marginLeft: "25px" }}
        value={`${subGammeValue.id};${subGammeValue.intitule}` ?? ""}
        onChange={handleChangeSubGamme}
      >
        {gammes[gammeValue.intitule]?.map((subgamme, idx) => {
          return (
            <option value={`${subgamme.id};${subgamme.intitule}`} key={idx}>
              {subgamme.intitule}
            </option>
          );
        })}
      </select>
    </>
  );
};

export default GammeChanger;
