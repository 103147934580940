import React, { useEffect, useState } from "react";
import {
  getAdjustedLevels,
  getDiscountLevel,
  getDiscountLevels,
  getTotalWithTaxes,
  getTotalWithoutTaxes,
  priceWithDiscount,
} from "../../utils/prices";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import CtaButton from "../../components/Buttons/CtaButton";
import Layout from "../../components/Layout/Layout";
import OrderRecapTable from "../../components/Products/OrderRecapTable";
import PageHeader from "../../components/PageHeader/PageHeader";
import Product from "../../components/Products/Product.interface";
import { fetchProducts } from "../../store/actions/products.action";
import { formatAmount } from "../../utils/text-formatting.utils";
import { get } from "../../services/utils.service";
import { isPharmacyUser } from "../../utils/client.utils";
// import Cookies from "js-cookie";
import { setDefaultInformationSettings } from "../../store/actions/information.action";
import styled from "styled-components";
import { useDiscounts } from "../../hooks/useDiscounts";

// import Cookies from "js-cookie";

const ProductList = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  min-width: fit-content;
`;

const AddBasketFooter = styled.div`
  width: 100%;
  height: 32px;
  background-color: rgba(255, 255, 255, 0.9);
`;

const SumUpTable = styled.div`
  background-color: white;
`;

const TotalWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  width: 100%;
  margin-top: 32px;
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  padding: 16px;
  background-color: white;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  padding: 5px;

  &.main {
    margin: 32px 0;
  }
`;

const Label = styled.div`
  margin-right: 64px;
  font-weight: bold;
`;

const Text = styled.div`
  &.bold {
    font-weight: bold;
  }

  &.white {
    color: white;
  }

  &.is20 {
    font-size: 20px;
  }
`;

const AlignRightWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 32px;
`;

// DEBUG: for TVA, map with all possible values
const OrderRecap = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const basket = useSelector((state) => state.basket);

  const user = useSelector((state) => state.user);
  const pharmacyData = useSelector(
    (state) => state.information.informationState
  );
  const products = useSelector((state) => state.products.values);
  const discountsBase = useSelector((state) => state.products.discounts);

  const { baseDiscountsByRange, discountThresholds, discountGroupProducts } =
    useDiscounts();

  const [isFetchingProducts, setIsFetchingProducts] = useState(false);
  const [orderedProducts, setOrderedProducts] = useState([]);
  const [orderProductsQuantities, setOrderProductsQuantities] = useState({});
  const [orderProductsData, setOrderProductsData] = useState(null);
  const [amountWithoutTaxes, setAmountWithoutTaxes] = useState(0);
  const [amountWithTaxes, setAmountWithTaxes] = useState(0);
  const [discountSum, setDiscountSum] = useState(0);
  const [taxes, setTaxes] = useState({});

  // console.log("orderedProducts", orderedProducts);

  const { orderReference } = useParams<{ orderReference: string }>();

  //   const orders = { "158": 36 };

  //We need primitive value to trigger useEffect on basket change
  const actualOrdersQuantities = Object.values(orderProductsQuantities);
  const actualOrderTotalQuantity = actualOrdersQuantities.length
    ? actualOrdersQuantities.reduce((acc, current) => +acc + +current)
    : 0;

  const getOrder = async (orderRef: string) => {
    const response = await get(`/orders/order/${orderRef}`);
    const parseResponse = await response.json();
    const parsedOrdersQuantities = parseResponse.reduce((rslt, product) => {
      return {
        ...rslt,
        [`${product.product_id}-${product.discountLevel}`]: product.quantity,
      };
    }, {});
    const parsedOrdersData = parseResponse.reduce((rslt, product) => {
      return {
        ...rslt,
        [`${product.product_id}-${product.discountLevel}`]: {
          ...product,
          quantity: product.quantity,
        },
      };
    }, {});
    console.log("parsedOrdersQuantities", parsedOrdersQuantities);
    console.log("parsedOrdersData", parsedOrdersData);
    setOrderProductsQuantities(parsedOrdersQuantities);
    setOrderProductsData(parsedOrdersData);
  };

  const handleGoHistory = () => {
    if (isPharmacyUser(user)) return history.push("/historique");
    history.push("/compte-client");
  };

  useEffect(() => {
    setIsFetchingProducts(true);
    dispatch(setDefaultInformationSettings);
    getOrder(orderReference);
    dispatch(fetchProducts(setIsFetchingProducts));
  }, []);

  useEffect(() => {
    if (orderProductsData === null) return;

    const filterProducts = (products): Product[] => {
      if (!products) return [];
      const values: Product[] = Object.values(products);
      return !values.length
        ? []
        : values.filter((product: Product) => {
            return Object.keys(orderProductsQuantities)
              .map((key) => key.split("-")[0])
              .includes(`${product.product_id.toString()}`);
          });
    };

    const filteredProducts: Product[] = filterProducts(products);

    // Reset all counters
    setAmountWithoutTaxes(0);
    setAmountWithTaxes(0);
    setDiscountSum(0);
    setTaxes({});

    const updatedProductsInOrder = Object.keys(orderProductsQuantities).map(
      (key) => {
        const [pId, pDiscountLevel] = key.split("-");
        const product = filteredProducts.find((p) => p.product_id === +pId);
        const productData = orderProductsData[key];
        const priceEuros = productData.priceEur ?? product.price_eur;
        const quantity = Number.parseInt(productData.quantity, 10);
        const totalPrice = priceEuros * quantity;
        // DEBUG

        const discountLevels = product.subRangeId
          ? getDiscountLevels(
              baseDiscountsByRange[product.subRangeId],
              discountThresholds.filter((d) =>
                product.discount_groups?.includes(d.discount_group_id)
              )
            )
          : null;

        const adjustedLevels = discountLevels
          ? getAdjustedLevels(
              product.product_id,
              discountLevels,
              Object.fromEntries(
                Object.entries(discountGroupProducts).filter(([key]) =>
                  product.discount_groups?.includes(+key)
                )
              ),
              basket
            )
          : null;
        // DEBUG
        const discountLevel: number = pDiscountLevel
          ? +pDiscountLevel
          : adjustedLevels
          ? getDiscountLevel(adjustedLevels, quantity)
          : 0;

        const totalWithoutTaxes = getTotalWithoutTaxes(
          priceEuros,
          quantity,
          discountLevel
        );
        const tvaPctg = productData.tvaPercentage ?? product.tva_percentage;
        const totalWithTaxes = getTotalWithTaxes(totalWithoutTaxes, tvaPctg);

        setAmountWithoutTaxes((sum) => sum + totalWithoutTaxes);
        setAmountWithTaxes((sum) => sum + totalWithTaxes);
        setDiscountSum((sum) => sum + totalPrice - totalWithoutTaxes);
        setTaxes((values) => {
          return {
            ...values,
            [tvaPctg]: values[tvaPctg]
              ? values[tvaPctg] + (totalWithTaxes - totalWithoutTaxes)
              : totalWithTaxes - totalWithoutTaxes,
          };
        });

        return {
          ...product,
          price_eur: priceEuros,
          tva_percentage: tvaPctg,
          discountLevel,
          quantity,
          priceWithDiscount: priceWithDiscount(priceEuros, discountLevel),
          totalWithoutTaxes,
          totalWithTaxes,
        };
      }
    );
    setOrderedProducts(updatedProductsInOrder);
  }, [
    actualOrderTotalQuantity,
    products,
    orderProductsData,
    orderProductsQuantities,
  ]);

  if (isFetchingProducts)
    return (
      <Layout>
        <></>
      </Layout>
    );
  return (
    <Layout>
      <>
        <PageHeader title={"Récap commande"} />
        <>
          <AlignRightWrapper>
            <CtaButton onClick={handleGoHistory}>
              <p>Revenir à l'historique</p>
            </CtaButton>
          </AlignRightWrapper>
          <SumUpTable>
            <ProductList>
              <OrderRecapTable
                productsQuantity={orderProductsQuantities}
                products={orderedProducts}
                baseDiscounts={baseDiscountsByRange}
                discountThresholds={discountThresholds}
                discountGroupProducts={discountGroupProducts}
              />
              <AddBasketFooter />
            </ProductList>
          </SumUpTable>
        </>
        {orderedProducts && orderedProducts.length ? (
          <TotalWrapper>
            <List>
              <Row>
                <Label>Nombre de lignes saisies</Label>
                <div>{Object.keys(orderProductsQuantities)?.length}</div>
              </Row>
              <Row>
                <Label>Total TTC</Label>
                <div>{formatAmount(amountWithTaxes)}</div>
              </Row>
              <Row>
                <Label>Remise totale</Label>
                <div>{formatAmount(discountSum)}</div>
              </Row>
              {!taxes || !Object.keys(taxes).length
                ? null
                : Object.keys(taxes)
                    .sort((a, b) => parseFloat(a) - parseFloat(b))
                    .map((key, idx) => (
                      <Row key={idx}>
                        <Label>{`TVA ${key}%`}</Label>
                        <div>{formatAmount(taxes[key])}</div>
                      </Row>
                    ))}
              <Row className="main">
                <Label>TOTAL HT</Label>
                <Text className="bold">{formatAmount(amountWithoutTaxes)}</Text>
              </Row>
              <CtaButton onClick={handleGoHistory}>
                <p style={{ marginRight: "10px" }}>Revenir à l'historique</p>
              </CtaButton>
            </List>
          </TotalWrapper>
        ) : null}
      </>
    </Layout>
  );
};

export default OrderRecap;
