import React, { useState, useEffect, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, Redirect, useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import Switch from "react-switch";
import imageCompression from "browser-image-compression";

import Layout from "../../components/Layout/Layout";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import Image from "../../components/forms/image.component";
import ProductDetails from "../../components/Products/ProductDetails";
import ProductMiniature from "../../components/Products/ProductMiniature";
import CtaButton from "../../components/Buttons/CtaButton";
import PageHeader from "../../components/PageHeader/PageHeader";
import GammeChanger from "./GammeChanger";

import { environment } from "../../environments";
import { formatAmount } from "../../utils/text-formatting.utils";

import {
  get,
  put,
  postFormData,
  del,
  post,
} from "../../services/utils.service";

import variables from "../../themes/variables.theme";
import { fetchProducts } from "../../store/actions/products.action";
import {
  CloseIcon,
  EditIcon,
  SaveIcon,
  UploadIcon,
} from "../../assets/icons/icons";
import IconButton from "../../components/Buttons/IconButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { selectTheme } from "../../utils/theme.utils";

type ProductParams = {
  id: string;
};

const ConfirmationButton = styled.div`
  background-color: #e55e38;
  border: 1px solid ${variables.darkGrey};
  padding: 5px;
  margin-right: 10px;
  color: white;
  font-weight: lighter;
  font-size: smaller;
  opacity: 0.7;
  width: 97px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  margin-left: 10px;

  &:hover {
    opacity: 1;
  }
  svg {
    path {
      fill: white;
    }
    &:hover {
      cursor: pointer;
      path {
        fill: black;
      }
    }
  }
`;

const DeleteButton = styled.div`
  background-color: #e55e38;
  border: 1px solid ${variables.darkGrey};
  padding: 6px;
  margin-right: 10px;
  color: white;
  opacity: 0.7;
  width: 97px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  margin-left: 10px;
  &:hover {
    cursor: pointer;
    opacity: 1;
  }
`;

const EditBox = styled.div`
  width: 30%;
  height: 32px;
  right: 16px;
  top: 16px;
  background: ${variables.whiteThree};
  display: flex;
  align-items: center;
  padding: 0 10px;
  border-radius: 4px;
  z-index: 1;
  span {
    font-size: 14px;
  }
  input {
    :focus {
      outline: none;
    }
  }
  button {
    background: none;
    border: none;
    margin-left: 10px;
    padding: 0;
    cursor: pointer;
    :focus {
      outline: none;
    }
  }
`;

const EditBoxImage = styled.div`
  width: 60%;
  height: 32px;
  right: 16px;
  top: 16px;
  background: ${variables.whiteThree};
  display: flex;
  align-items: center;
  padding: 0 10px;
  border-radius: 4px;
  z-index: 1;
  span {
    font-size: 14px;
  }
  input {
    :focus {
      outline: none;
    }
  }
  button {
    background: none;
    border: none;
    margin-left: 10px;
    padding: 0;
    cursor: pointer;
    :focus {
      outline: none;
    }
  }
`;

const ProductDetail = styled.div`
  min-height: 450px;
  margin-top: 32px;
  margin-bottom: 49px;

  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: minmax(0, 1fr) 82px minmax(0, 1fr);
  }
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`;

const GridSub = styled.div<{ gridColumnStart: string }>`
  grid-column-start: ${({ gridColumnStart }) => gridColumnStart};
  display: flex;
  align-items: center;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  background-color: white;
`;

const MainImg = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  height: 90%;
  width: 90%;

  padding: 48px;
  img {
    max-width: 100%;
    max-height: 100%;
    max-height: 50vh;
  }
`;

const LinkedProducts = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: fit-content;
`;

const LinkedProductsWrapper = styled.div`
  overflow-x: scroll;
`;

const Paragraph = styled.div`
  margin: 16px 0;
  padding: 16px;
  background-color: white;

  h4 {
    margin-bottom: 16px;
  }
`;

const CenteredTitle = styled.h4`
  font-size: 24px;
  font-weight: 500;
  color: white;
  margin: 0;
  text-align: center;
  font-weight: bold;
`;

// DEBUG: tmp
const FakeMainImg = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  height: 100%;
  &:before {
    content: "image";
  }
`;

const CenteredText = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 64px;
  background-color: white;

  div {
    margin-left: 32px;
    p {
      text-align: center;
    }
  }
`;

const PreviewBox = styled.label`
  width: 100%;
  border-radius: 4px;
  padding: 1px;
  border: 2px dashed ${variables.white};
  display: block;
  cursor: pointer;
  margin-bottom: 0;
  position: relative;

  * {
    pointer-events: none;
  }
  .upload-button {
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 4px;

    position: absolute;
    width: 100px;
    height: 100px;
    top: calc(50% - 50px);
    left: calc(50% - 50px);
    text-align: center;

    border: none;
    padding: 0;
    z-index: 2;
    :focus {
      outline: none;
    }
    svg {
      width: 40px;
      height: 40px;
    }
    .upload-text {
      color: ${variables.white};
      font-size: 12px;
    }
  }
  input {
    opacity: 0;
  }
  :hover {
    img,
    div {
      opacity: 0.7;
    }
    .upload-button {
      cursor: pointer;
    }
  }
`;

const StyledLink = styled.a<{ color: string }>`
  text-decoration: none;
  :hover {
    color: ${(props) => props.color};
    cursor: pointer;
  }
`;

const EditLine = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
`;

const ErrorText = styled.p`
  font-size: 20px;
  color: #9b1219;
  margin-bottom: 30px;
`;

const AdvicesInput = styled.input`
  /* margin-bottom: 10px; */
`;

const Card = styled.div`
  position: relative;
  background-color: white;
  width: 330px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  margin: 20px;

  padding: 16px;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
`;

const ErrorContainer = styled.div`
  margin-top: 16px;
  padding: 8px 3px;
  background-color: white;
`;

const ProductPage = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const productsSelector = useSelector((state) => state.products.allValues);
  const params: ProductParams = useParams();

  const [isFetchingProducts, setIsFetchingProducts] = useState(false);
  const [product, setProduct] = useState(null);
  const [products, setProducts] = useState(null);
  const [productActivated, setProductActivated] = useState(
    product?.activated ?? false
  );
  const [linkedProducts, setLinkedProducts] = useState([]);
  const [advicedProducts, setAdvicedProducts] = useState([]);
  const [errorWithProducts, setErrorWithProducts] = useState(false);
  const [gammes, setGammes] = useState({});
  const [gammeIds, setGammeIds] = useState({});
  const [isEditingDescription, setIsEditingDescription] = useState(false);
  const [isEditingAdvicedPrice, setIsEditingAdvicedPrice] = useState(false);
  const [isEditingImage, setIsEditingImage] = useState(false);
  const [isEditingAdvices, setIsEditingAdvices] = useState(false);
  const [isEditingLinkedProducts, setIsEditingLinkedProducts] = useState(false);
  const [file, setFile] = useState(null);
  const [fileUrl, setFileUrl] = useState(null);
  const [descriptionInput, setDescriptionInput] = useState(
    product?.description
  );
  const [advicedPriceInput, setAdvicedPriceInput] = useState(
    product?.adviced_price
  );
  const [advicedProductsInputs, setAdvicedProductsInputs] = useState([]);
  const [linkedProductInput, setLinkedProductInput] = useState("");
  const [wantToDeleteAdviced, setWantToDeleteAdviced] = useState({});
  const [wantToDeleteLinked, setWantToDeleteLinked] = useState({});
  const [isAddingLinked, setIsAddingLinked] = useState(false);
  const [isDeletingLinked, setIsDeletingLinked] = useState(false);
  const [deleteLinkedError, setDeleteLinkedError] = useState(false);
  const [linkedProductAdded, setLinkedProductAdded] = useState(false);
  const [advicedProductsError, setAdvicedProductsError] = useState(false);
  const [linkedProductsError, setLinkedProductsError] = useState(false);
  const [networkError, setNetworkError] = useState(false);

  const location = useLocation();

  const history = useHistory();

  useEffect(() => {
    fetchProductsLink();
  }, [location.pathname, products]);

  const fetchLinkedProducts = async (id) => {
    try {
      const response = await get(`/product/linked-products/${id}`);
      return await response.json();
    } catch (error) {
      console.error(error);
      setErrorWithProducts(true);
    }
  };

  const fetchAdvicedProducts = async (id) => {
    try {
      const response = await get(`/product/adviced-products/${id}`);
      return await response.json();
    } catch (error) {
      console.error(error);
      setErrorWithProducts(true);
    }
  };

  const fetchProductsLink = async () => {
    try {
      const id = parseInt(params.id, 10);
      if (!products) {
        setIsFetchingProducts(true);
        dispatch(fetchProducts(setIsFetchingProducts));
        return;
      }

      const product = products.find((p) => p.product_id === id);
      const parseResponse = await fetchLinkedProducts(product.product_id);
      const linked = [];
      for (const p of parseResponse) linked.push(p.linked_product_id);
      setProduct(product);
      setProductActivated(product.activated);
      setDescriptionInput(product.description);
      setLinkedProducts(linked);
    } catch (error) {
      console.error(error);
      setErrorWithProducts(true);
    }
  };

  // const fetchProductsAdviced = async () => {
  //   try {
  //     const id = parseInt(params.id, 10);
  //     if (!products) {
  //       dispatch(fetchProducts);
  //       return;
  //     }
  //     const product = products.find((p) => p.product_id === id);
  //     const parseResponse = await fetchAdvicedProducts(product.product_id);
  //     const adviced = [];
  //     for (const p of parseResponse) adviced.push(p);
  //     setProduct(product);
  //     setProductActivated(product.activated);
  //     setDescriptionInput(product.description);
  //     setAdvicedProducts(adviced);
  //   } catch (error) {
  //     console.error(error);
  //     setErrorWithProducts(true);
  //   }
  // };

  const fetchProductsDatas = async () => {
    try {
      const id = parseInt(params.id, 10);
      if (!products) {
        dispatch(fetchProducts(setIsFetchingProducts));
        if (products === undefined && productsSelector === undefined) {
          setProducts(null);
        } else {
          setProducts(productsSelector);
        }
        return;
      }
      const product = products.find((p) => p.product_id === id);
      const parseAdviced = await fetchAdvicedProducts(product.product_id);
      const parseLinked = await fetchLinkedProducts(product.product_id);
      const adviced = [];
      const linked = [];
      for (const p of parseAdviced) adviced.push(p);
      for (const p of parseLinked) linked.push(p.linked_product_id);
      setProduct(product);
      setProductActivated(product.activated);
      setDescriptionInput(product.description);
      setAdvicedProducts(adviced);
      setAdvicedProductsInputs(
        adviced
          ? adviced.concat([
              {
                adviced_product_id: 0,
                title: "",
                ean_id: "",
              },
            ])
          : [
              {
                adviced_product_id: 0,
                title: "",
                ean_id: "",
              },
            ]
      );
      setLinkedProducts(linked);

      setWantToDeleteAdviced(
        adviced?.reduce(
          (accumulateur, advcd) => ({
            ...accumulateur,
            [advcd.adviced_product_id]: false,
          }),
          {}
        )
      );
      setWantToDeleteLinked(
        linked?.reduce(
          (accumulateur, linked_id) => ({
            ...accumulateur,
            [linked_id]: false,
          }),
          {}
        )
      );
    } catch (error) {
      console.error(error);
      setErrorWithProducts(true);
    }
  };

  const onMounting = async () => {
    const gammesResponse = await get("/product/gammes");
    const parseGammes = await gammesResponse.json();
    const newGammeIds = parseGammes.reduce((rslt, value) => {
      rslt[value.title] = value.id;
      return rslt;
    }, {});

    const subGammesResponse = await get("/product/subgammes");
    const parseSubGammes = await subGammesResponse.json();
    const newGammes = {};
    for (const rangeTitle in newGammeIds) {
      newGammes[rangeTitle] = parseSubGammes
        .filter((subgamme) => {
          return subgamme.subRangeId === newGammeIds[rangeTitle];
        })
        .map((subgamme) => {
          return {
            id: subgamme.subFamilyId,
            intitule: subgamme.subFamilyTitle,
          };
        });
    }
    setGammes(newGammes);
    setGammeIds(newGammeIds);
    await fetchProductsDatas();
  };

  useEffect(() => {
    onMounting();
  }, [products]);

  const handleOpenAdvicedPriceEditMode = (event) => {
    event.preventDefault();
    setAdvicedPriceInput(product?.adviced_price);
    setIsEditingAdvicedPrice(true);
  };

  const handleCloseAdvicedPriceEditMode = (event) => {
    event.preventDefault();
    // setAdvicedPriceInput(product.adviced_price);
    setIsEditingAdvicedPrice(false);
  };

  const handleSaveAdvicedPrice = async (event) => {
    try {
      event.preventDefault();
      const response = await put(
        `/product/adviced_price/${product.product_id}`,
        {
          adviced_price: advicedPriceInput,
        }
      );

      if (!response.ok) throw "wrong datas";

      const parseResponse = await response.json();
      if (parseResponse) history.go(0);
    } catch (error) {
      console.error(error);
      setAdvicedPriceInput(product.adviced_price);
    } finally {
      setIsEditingAdvicedPrice(false);
    }
  };

  const handleChangeAdvicedPriceValue = (event) => {
    event.preventDefault();
    setAdvicedPriceInput(event.target.value);
  };
  //handle edit data
  const handleOpenDescriptionEditMode = (event) => {
    event.preventDefault();
    setDescriptionInput(product?.description);
    setIsEditingDescription(true);
  };

  const handleCloseDescriptionEditMode = (event) => {
    event.preventDefault();
    setIsEditingDescription(false);
  };

  const handleSaveDescription = async (event) => {
    try {
      event.preventDefault();
      const response = await put(`/product/description/${product.product_id}`, {
        description: descriptionInput,
      });

      if (!response.ok) throw "wrong datas";

      const parseResponse = await response.json();
      if (parseResponse) history.go(0);
    } catch (error) {
      console.error(error);
      setDescriptionInput(product.description);
    } finally {
      setIsEditingDescription(false);
    }
  };

  const handleChangeDescriptionValue = (event) => {
    event.preventDefault();
    setDescriptionInput(event.target.value);
  };

  const handleOpenEditImage = (event) => {
    event.preventDefault();
    setIsEditingImage(true);
  };

  const handleCloseEditImage = (event) => {
    event.preventDefault();
    setIsEditingImage(false);
    setFile(null);
    setFileUrl(null);
  };

  const handleNewFile = async (event) => {
    if (!event.target.files[0]) return;
    event.preventDefault();
    const imageFile = event.target.files[0];
    const compressedFile = await imageCompression(imageFile, {
      maxWidthOrHeight: 4999,
    });
    const f = new File([compressedFile], `compressed-${imageFile.name}`, {
      type: compressedFile.type,
    });
    setFile(f);
    setFileUrl(URL.createObjectURL(f));
  };

  const handleValidateNewFile = async () => {
    if (!file) return;

    const data = new FormData();
    const fileUrlToList = fileUrl.split("/");
    const filename = fileUrlToList[fileUrlToList.length - 1];
    data.append("file", file, filename);
    const response = await postFormData(
      `/product/image/${product.product_id}`,
      data
    );

    const parseResponse = await response.json();

    if (parseResponse) {
      history.go(0);
    }

    setIsEditingImage(false);
  };

  const handleProductActivation = async (event) => {
    const response = await put("/product/activation", {
      productId: product.product_id,
      activate: !productActivated,
    });
    const updatedProduct = await response.json();
    setProductActivated(updatedProduct.activated);
  };

  const handleOpenAdvicesEditMode = (event) => {
    event.preventDefault();
    // setDescriptionInput(product?.description);
    setIsEditingAdvices(true);
  };

  const handleCloseAdvicesEditMode = (event) => {
    event.preventDefault();
    setAdvicedProductsInputs(
      advicedProducts
        ? advicedProducts.concat([
            {
              adviced_product_id: 0,
              title: "",
              ean_id: "",
            },
          ])
        : [
            {
              adviced_product_id: 0,
              title: "",
              ean_id: "",
            },
          ]
    );
    setIsEditingAdvices(false);
  };

  const handleSaveAdvices = async (event) => {
    try {
      let result = true;
      const lastElementIndex = advicedProductsInputs.length - 1;
      if (advicedProductsInputs[lastElementIndex].ean_id) {
        const response = await post(
          `/product/adviced-products/${product.product_id}`,
          {
            ean_id: advicedProductsInputs[lastElementIndex].ean_id,
          }
        );
        const parseResponse = await response.json();
        if (!parseResponse.result) result = false;
      }

      if (!result) {
        setAdvicedProductsError(true);
        setTimeout(() => {
          setAdvicedProductsError(false);
        }, 3000);
      } else history.go(0);
    } catch (error) {
      console.error(error);
      setNetworkError(true);
      setTimeout(() => {
        setNetworkError(false);
      }, 3000);
    }
  };

  const handleDelete = async (toDelete) => {
    try {
      // console.log(toDelete);
      // handleDeleteCancel();
      const response = await del(
        `/product/adviced-products/${product.product_id}/${toDelete}`
      );

      const parseResponse = await response.json();

      if (parseResponse.result) history.go(0);
    } catch (error) {
      console.error(error);
      //DEBUG: error handling
    }
  };

  const handleDeleteCancel = () => {
    setWantToDeleteAdviced(
      advicedProductsInputs?.reduce(
        (accumulateur, basicDiscount) => ({
          ...accumulateur,
          [basicDiscount.id]: false,
        }),
        {}
      )
    );
  };

  const handleChangeAdvicedProduct = (value, idx) => {
    const result = advicedProductsInputs.map((advicedProductInput) => {
      return { ...advicedProductInput };
    });
    result[idx]["ean_id"] = value;
    setAdvicedProductsInputs(result);
  };

  const handleCloseLinkedProductsEditMode = (event) => {
    event.preventDefault();
    setLinkedProductInput("");
    setIsEditingLinkedProducts(false);
  };

  const handleOpenLinkedProductsEditMode = (event) => {
    event.preventDefault();
    setIsEditingLinkedProducts(true);
  };

  const handleSaveLinkedProducts = async (event) => {
    event.preventDefault();
    if (isAddingLinked) return;
    setIsAddingLinked(true);

    const response = await post(
      `/product/linked-products/${product.product_id}`,
      { ean_id: linkedProductInput }
    );

    const parseResponse = await response.json();

    if (!parseResponse.result) {
      setLinkedProductsError(true);
      setTimeout(() => {
        setLinkedProductsError(false);
        setIsAddingLinked(false);
      }, 3000);
      return;
    }

    const parseLinked = await fetchLinkedProducts(product.product_id);
    const linked = [];

    for (const p of parseLinked) linked.push(p.linked_product_id);

    setLinkedProducts(linked);

    setWantToDeleteLinked((prevState) =>
      linked?.reduce(
        (accumulateur, linked_id) => ({
          ...accumulateur,
          [linked_id]: prevState[linked_id] ?? false,
        }),
        {}
      )
    );

    setLinkedProductInput("");
    setLinkedProductAdded(true);
    setTimeout(() => {
      setLinkedProductAdded(false);
      setIsAddingLinked(false);
    }, 3000);
  };

  const handleDeleteLinked = async (event, id) => {
    event.preventDefault();
    if (isDeletingLinked) return;
    setIsDeletingLinked(true);

    const response = await del(
      `/product/linked-products/${product.product_id}/${id}`
    );

    const parseResponse = await response.json();

    if (!parseResponse.result) {
      setDeleteLinkedError(true);
      setTimeout(() => {
        setDeleteLinkedError(false);
      }, 3000);
      return;
    }

    const parseLinked = await fetchLinkedProducts(product.product_id);
    const linked = [];

    for (const p of parseLinked) linked.push(p.linked_product_id);

    setLinkedProducts(linked);

    setWantToDeleteLinked((prevState) =>
      linked?.reduce(
        (accumulateur, linked_id) => ({
          ...accumulateur,
          [linked_id]: prevState[linked_id],
        }),
        {}
      )
    );

    setIsDeletingLinked(false);
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [product]);

  if (errorWithProducts)
    return (
      <Layout>
        <PageHeader title={"Produit - Erreur"} />
        <CenteredText>
          <p>
            Nous ne sommes pas en mesure de récupérer les informations propres à
            ce produit. Toutes nos excuses pour ce désagrément. Nous vous
            invitons à recharger la page.
          </p>
          <CtaButton onClick={() => history.go(0)}>
            <p>Recharger la page</p>
          </CtaButton>
        </CenteredText>
      </Layout>
    );
  else if (product === null) return null;
  else if (product === undefined) return <Redirect to="/" />;

  const pathToProduct = !product
    ? [
        {
          label: "Saisir une commande",
          href: "/",
        },
      ]
    : [
        {
          label: "Saisir une commande",
          href: "/",
        },
        {
          label: product.sub_range_title,
          href: `/?category=${product.sub_range_title}`,
        },
        {
          label: product.family_title,
          href: `/?category=${product.sub_range_title}&family=${product.family_title}`,
        },
        {
          label: product.product_title,
        },
      ];

  const image = product.image_path ? (
    <MainImg>
      <img
        src={`${environment.imageBasePath}${product.image_path}`}
        alt={`${product.product_title}`}
      />
    </MainImg>
  ) : (
    <FakeMainImg />
  );

  const previewImage = (
    <PreviewBox htmlFor={product.product_id}>
      {fileUrl ? (
        <Image src={fileUrl} alt={"product_image" + product.product_id} />
      ) : (
        image
      )}
      <div className="upload-button">
        <UploadIcon color={variables.white} />
        <p className="upload-text">Cliquer pour téléverser une image</p>
      </div>
      <form>
        <input
          type="file"
          name="image"
          id={product.product_id}
          onChange={handleNewFile}
        />
      </form>
    </PreviewBox>
  );

  if (isFetchingProducts)
    return (
      <Layout>
        <></>
      </Layout>
    );
  else
    return (
      <Layout>
        <Breadcrumb steps={pathToProduct} />
        <ProductDetail>
          <GridSub gridColumnStart="1">
            {user.Role === "admin" ? (
              <EditBoxImage>
                <span>Modifier l'image du produit</span>
                {isEditingImage ? (
                  <>
                    <button onClick={handleValidateNewFile}>
                      <SaveIcon color={variables.charcoalGrey} />
                    </button>
                    <button onClick={handleCloseEditImage}>
                      <CloseIcon color={variables.charcoalGrey} />
                    </button>
                  </>
                ) : (
                  <button onClick={handleOpenEditImage}>
                    <EditIcon color={variables.charcoalGrey} />
                  </button>
                )}
              </EditBoxImage>
            ) : null}

            {isEditingImage ? previewImage : image}
          </GridSub>
          <GridSub gridColumnStart="3">
            <ProductDetails
              product={product}
              id={product.product_id}
              title={product.product_title}
              subRange={product.sub_range_title}
              ean={product.ean_id}
              batchQuantity={product.batch_quantity}
              price={product.price_eur}
              stock={product.stock}
              restock_date={product.restock_date}
            />
          </GridSub>
        </ProductDetail>
        {user.Role === "admin" ? (
          <Paragraph
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Switch
              onChange={handleProductActivation}
              checked={productActivated ?? false}
            />
            {"    "}
            {productActivated ? "Produit activé" : "Produit désactivé"} <br />
          </Paragraph>
        ) : null}

        {user.Role === "admin" ? (
          <Paragraph>
            <GammeChanger
              startingGamme={{
                id: product.subRangeId,
                intitule: product.sub_range_title,
              }}
              startingSubGamme={{
                id: product.familyId,
                intitule: product.family_title,
              }}
              productId={product.product_id}
              gammes={gammes}
              gammeIds={gammeIds}
            />
          </Paragraph>
        ) : null}

        <Paragraph>
          {user.Role === "admin" ? (
            <EditBox>
              <span>Modifier le prix de vente conseillé</span>
              {isEditingAdvicedPrice ? (
                <>
                  <button onClick={handleSaveAdvicedPrice}>
                    <SaveIcon color={variables.charcoalGrey} />
                  </button>
                  <button onClick={handleCloseAdvicedPriceEditMode}>
                    <CloseIcon color={variables.charcoalGrey} />
                  </button>
                </>
              ) : (
                <button onClick={handleOpenAdvicedPriceEditMode}>
                  <EditIcon color={variables.charcoalGrey} />
                </button>
              )}
            </EditBox>
          ) : null}
          {isEditingAdvicedPrice ? (
            <>
              <p>
                Prix de vente conseillé :{" "}
                <input
                  type="number"
                  name="adviced_price"
                  value={advicedPriceInput}
                  onChange={handleChangeAdvicedPriceValue}
                />{" "}
                €
              </p>{" "}
            </>
          ) : (
            `Prix de vente conseillé : ${formatAmount(
              product.adviced_price ? product.adviced_price : 0
            )}`
          )}
        </Paragraph>
        {user.Role !== "admin" && !product.description ? null : (
          <Paragraph>
            {user.Role === "admin" ? (
              <EditBox>
                <span>Modifier la description du produit</span>
                {isEditingDescription ? (
                  <>
                    <button onClick={handleSaveDescription}>
                      <SaveIcon color={variables.charcoalGrey} />
                    </button>
                    <button onClick={handleCloseDescriptionEditMode}>
                      <CloseIcon color={variables.charcoalGrey} />
                    </button>
                  </>
                ) : (
                  <button onClick={handleOpenDescriptionEditMode}>
                    <EditIcon color={variables.charcoalGrey} />
                  </button>
                )}
              </EditBox>
            ) : null}
            <h4>Description</h4>
            {!isEditingDescription ? (
              <p>
                {product.description ? product.description : "En construction"}
              </p>
            ) : (
              <textarea
                name="product_description"
                value={descriptionInput}
                onChange={handleChangeDescriptionValue}
                rows={5}
                cols={65}
              ></textarea>
            )}
          </Paragraph>
        )}
        {user.Role !== "admin" && !advicedProducts?.length ? null : (
          <Paragraph>
            {user.Role === "admin" ? (
              <EditBox>
                <span>Modifier les conseils associés</span>
                {isEditingAdvices ? (
                  <>
                    <button onClick={handleSaveAdvices}>
                      <SaveIcon color={variables.charcoalGrey} />
                    </button>
                    <button onClick={handleCloseAdvicesEditMode}>
                      <CloseIcon color={variables.charcoalGrey} />
                    </button>
                  </>
                ) : (
                  <button onClick={handleOpenAdvicesEditMode}>
                    <EditIcon color={variables.charcoalGrey} />
                  </button>
                )}
              </EditBox>
            ) : null}
            <h4>Conseil associé</h4>
            {!isEditingAdvices ? (
              <>
                {advicedProducts?.length ? (
                  <>
                    <h6>
                      {product.product_title} se marie bien avec le(s)
                      produit(s) suivant(s):
                    </h6>

                    <br />
                    {advicedProducts.map((advicedProduct) => (
                      <Fragment key={advicedProduct.adviced_product_id}>
                        <p>
                          <StyledLink
                            href={`/produit/${advicedProduct.adviced_product_id}`}
                            color={selectTheme(user.Role)}
                          >
                            {advicedProduct.title}
                          </StyledLink>
                        </p>
                      </Fragment>
                    ))}
                  </>
                ) : (
                  "En construction"
                )}
              </>
            ) : (
              <>
                {advicedProductsError ? (
                  <ErrorText>
                    Erreur: Erreur code ean introuvable ou dupliqué
                  </ErrorText>
                ) : null}
                {networkError ? (
                  <ErrorText>
                    Erreur: Erreur réseau, veuillez réessayer
                  </ErrorText>
                ) : null}
                {advicedProductsInputs.map((advicedProduct, idx) =>
                  idx !== advicedProductsInputs.length - 1 ? (
                    <EditLine>
                      <p>{advicedProductsInputs[idx].ean_id}</p>
                      {wantToDeleteAdviced[
                        advicedProductsInputs[idx].adviced_product_id
                      ] === true ? (
                        <ConfirmationButton>
                          <IconButton
                            onClick={() =>
                              handleDelete(
                                advicedProductsInputs[idx].adviced_product_id
                              )
                            }
                          >
                            <FontAwesomeIcon icon={faCheck} />
                          </IconButton>
                          Sûr ?
                          <IconButton onClick={handleDeleteCancel}>
                            <FontAwesomeIcon icon={faTimes} />
                          </IconButton>
                        </ConfirmationButton>
                      ) : (
                        <DeleteButton
                          onClick={() =>
                            setWantToDeleteAdviced({
                              ...wantToDeleteAdviced,
                              [advicedProductsInputs[idx].adviced_product_id]:
                                true,
                            })
                          }
                        >
                          SUPPRIMER
                        </DeleteButton>
                      )}
                    </EditLine>
                  ) : (
                    <>
                      <p>Ajouter un conseil associé ?</p>{" "}
                      <EditLine>
                        <AdvicesInput
                          name=""
                          value={advicedProductsInputs[idx].ean_id}
                          placeholder="Ean du produit"
                          onChange={(e) =>
                            handleChangeAdvicedProduct(e.target.value, idx)
                          }
                        />
                      </EditLine>
                    </>
                  )
                )}
              </>
            )}
          </Paragraph>
        )}

        {/* <Paragraph>Merch</Paragraph> */}

        <>
          <CenteredTitle>Ventes associées</CenteredTitle>
          {user?.Role === "admin" && (
            <EditBox>
              <span>Modifier les ventes associées</span>
              {isEditingLinkedProducts ? (
                <>
                  <button onClick={handleCloseLinkedProductsEditMode}>
                    <CloseIcon color={variables.charcoalGrey} />
                  </button>
                </>
              ) : (
                <button onClick={handleOpenLinkedProductsEditMode}>
                  <EditIcon color={variables.charcoalGrey} />
                </button>
              )}
            </EditBox>
          )}
          <LinkedProductsWrapper>
            {deleteLinkedError ? (
              <ErrorContainer>
                <ErrorText>
                  Erreur: Erreur lors de la suppression, veuillez réessayer
                </ErrorText>
              </ErrorContainer>
            ) : null}
            {linkedProductsError ? (
              <ErrorContainer>
                <ErrorText>
                  Erreur: Erreur code ean introuvable ou dupliqué
                </ErrorText>
              </ErrorContainer>
            ) : null}
            {linkedProductAdded ? (
              <p
                style={{
                  fontSize: "18px",
                  color: "white",
                  marginTop: "12px",
                }}
              >
                Le produit a bien été associé !
              </p>
            ) : null}
            <LinkedProducts>
              {isEditingLinkedProducts ? (
                <Card>
                  <p>Ajouter une vente associé ?</p>
                  <div>
                    <input
                      name=""
                      placeholder="EAN du produit"
                      value={linkedProductInput}
                      onChange={(event) =>
                        setLinkedProductInput(event.target.value)
                      }
                    />
                    <button
                      onClick={handleSaveLinkedProducts}
                      style={{
                        background: selectTheme(user.Role),
                        cursor: "pointer",
                      }}
                    >
                      <SaveIcon color="white" />
                    </button>
                  </div>
                </Card>
              ) : null}
              {linkedProducts.map((id, idx) => (
                <ProductMiniature
                  key={idx}
                  id={id}
                  isEditing={isEditingLinkedProducts}
                  wantToDeleteLinked={wantToDeleteLinked}
                  setWantToDeleteLinked={setWantToDeleteLinked}
                  handleDeleteLinked={handleDeleteLinked}
                />
              ))}
            </LinkedProducts>
          </LinkedProductsWrapper>
        </>
      </Layout>
    );
};

export default ProductPage;
