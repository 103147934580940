import { faTrashAlt, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  MultiSelect,
  Stack,
  Group,
  NumberInput,
  ActionIcon,
  Button,
  Text,
} from "@mantine/core";
import React from "react";
import { useState } from "react";
import ProductSelector from "./ProductSelector";

// TODO? Using controlled fields here causes a lot of unnecessary re-renders.
function DiscountGroupForm({
  discountGroup,
  pharmacyDiscountGroups,
  onFormSubmit,
  onFormCancel,
  onFormDelete,
}) {
  const [formValues, setFormValues] = useState(discountGroup);
  // New levels have negative ids before they are sent to the db to get their final value.
  const [newLevelIndex, setNewLevelIndex] = useState(-1);

  const pharmacyDiscountGroupData = pharmacyDiscountGroups.map(
    (pharmacyDiscountGroup) => ({
      value: pharmacyDiscountGroup.id.toString(),
      label: pharmacyDiscountGroup.name,
    })
  );

  // Add use query to get products when searchable updated.

  const onAddLevel = () => {
    setFormValues((prevState) => ({
      ...prevState,
      discounts: [
        ...(prevState?.discounts ?? []),
        { id: newLevelIndex, quantity: 0, additional_discount: 0 },
      ],
    }));
    setNewLevelIndex((oldIndex) => oldIndex - 1);
  };

  const onDeleteLevel = (id: number) => {
    setFormValues((prevState) => ({
      ...prevState,
      discounts: prevState?.discounts?.filter((discount) => discount.id !== id),
    }));
  };

  const onEditLevel = (id: number, field: string, value: number | "") => {
    const newValue = parseInt(value.toString()) || 0; // If value is empty, set to 0.
    setFormValues((prevState) => ({
      ...prevState,
      discounts: prevState?.discounts?.map((discount) => {
        if (discount.id === id) {
          return { ...discount, [field]: newValue };
        }
        return discount;
      }),
    }));
  };

  const groupValues = formValues?.pharmacyGroups
    ?.map((group) => group.id?.toString())
    ?.filter((x) => x !== undefined);

  // const [selectedGroups, setSelectedGroups] = useState(groupValues)

  const setSelectedGroups = (groupValues) => {
    setFormValues((prevState) => ({
      ...prevState,
      pharmacyGroups:
        pharmacyDiscountGroups?.filter((group) =>
          groupValues.includes(group.id.toString())
        ) ?? [],
    }));
  };

  const onProductAdd = (product) => {
    setFormValues((prevState) => ({
      ...prevState,
      products: [...(prevState?.products ?? []), product],
    }));
  };

  const onProductDelete = (product) => {
    setFormValues((prevState) => ({
      ...prevState,
      products: prevState?.products?.filter((p) => p.id !== product.id),
    }));
  };

  function onSubmit(e) {
    e.preventDefault();
    onFormSubmit(formValues);
  }
  return (
    <form onSubmit={onSubmit}>
      <ProductSelector
        selectedProducts={formValues?.products ?? []}
        onAdd={onProductAdd}
        onRemove={onProductDelete}
      />
      <MultiSelect
        dropdownPosition="bottom"
        my="md"
        label="Groupes de réductions de pharmacies"
        data={pharmacyDiscountGroupData}
        onChange={setSelectedGroups}
        value={groupValues}
      />

      <Text fz="sm" mt="xs" mb="xs" fw={500} color="dark">
        Paliers de réductions
      </Text>
      <Stack spacing="xs">
        {formValues?.discounts?.map((discount) => (
          <Group noWrap key={discount.id}>
            <NumberInput
              value={discount.quantity}
              onChange={(newQuantity) =>
                onEditLevel(discount.id, "quantity", newQuantity)
              }
            />
            <Text>unités:</Text>
            <NumberInput
              value={discount.additional_discount}
              onChange={(newQuantity) =>
                onEditLevel(discount.id, "additional_discount", newQuantity)
              }
            />
            %
            <ActionIcon
              color="primary"
              variant="subtle"
              onClick={() => onDeleteLevel(discount.id)}
            >
              <FontAwesomeIcon icon={faTrashAlt} />
            </ActionIcon>
          </Group>
        ))}
        <Button
          variant="subtle"
          leftIcon={<FontAwesomeIcon icon={faPlus} />}
          onClick={onAddLevel}
        >
          Ajouter un palier
        </Button>
      </Stack>

      <Group mt="md" position={discountGroup?.id ?? false ? "apart" : "right"}>
        {discountGroup?.id && (
          <Button variant="subtle" onClick={onFormDelete}>
            Supprimer
          </Button>
        )}
        <Group position="right">
          <Button variant="outline" onClick={onFormCancel}>
            Annuler
          </Button>
          {/*  */}
          <Button type="submit">Valider</Button>
        </Group>
      </Group>
    </form>
  );
}

export default DiscountGroupForm;
