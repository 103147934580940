import React, { useRef, useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";
import {
  updateBasketCookie,
  removeItemBasketCookie,
} from "../../store/actions/basket.action";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import IconButton from "../Buttons/IconButton";

import { Table, Th, Tr, Td, Thead, Tbody } from "../table/table.component";
import Product from "./Product.interface";
import ProductBasketControls from "./ProductBasketControls";
import ProductTitleEan from "./ProductTitleEan/ProductTitleEan";
import BasketUpdated from "./BasketUpdated";

import {
  formatAmount,
  formatDiscountLevels,
} from "../../utils/text-formatting.utils";
import { resetMark } from "../../store/actions/product-mark.action";
import { environment } from "../../environments";
import {
  getDiscountLevel,
  priceWithDiscount,
  getTotalWithoutTaxes,
  getTotalWithTaxes,
  getDiscountLevels,
  getAdjustedLevels,
  keepBestDiscounts,
} from "../../utils/prices";

// import Cookies from "js-cookie";
import Cookies from "universal-cookie";
import { formatUSDate } from "../../utils/dates.utils";

const StyledTr = styled(Tr)<{ color?: string; bottomColor?: string }>`
  background-color: ${({ color }) => (color ? color : "white")};
  border-bottom: ${({ bottomColor }) =>
    bottomColor ? `1px solid ${bottomColor} !important;` : "none"};
  *::selection {
    background-color: transparent;
  }
`;

const StyledTh = styled(Th)<{
  bottomColor?: string;
  centered?: boolean;
  minWidth?: string;
}>`
  text-align: ${({ centered }) => (centered ? "center" : "left")};
  border-bottom: ${({ bottomColor }) =>
    bottomColor ? `1px solid ${bottomColor} !important;` : "none"};
  min-width: ${({ minWidth }) => (minWidth ? minWidth : "")};
  padding: 0 8px;
`;

const StyledTd = styled(Td)<{ centered?: boolean }>`
  text-align: ${({ centered }) => (centered ? "center" : "left")};
  padding: 0 8px;
`;

// DEBUG: this is temporary
const Img = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
  height: 40px;
  width: 40px;

  img {
    max-height: 100%;
    max-width: 100%;
  }
`;

const ImageTd = styled(Td)`
  padding: 0 !important;
`;

const NoIconDiv = styled.div`
  min-width: 30px;
`;

// DEBUG: I should have a way to directly open & scroll to the right category from a parameter.
const ProductRow = ({
  id,
  imagePath,
  title,
  brand,
  ean,
  batch,
  tva,
  price,
  stock,
  isCovidProduct,

  product,

  color = undefined,
  priceWithTaxesColumn,
  baseDiscounts,
  discountThresholds,
  discountGroupProducts,
}) => {
  const cookies = new Cookies();
  const dispatch = useDispatch();
  const rowRef = useRef(null);
  const basketUpdateRef = useRef(null);

  const productMark = useSelector((state) => state.productMark);
  const pharmacyData = useSelector(
    (state) => state.information.informationState
  );
  const basket = useSelector(
    (state) => state.basket[cookies.get("pharmacy") ?? "0"] ?? {}
  );

  const [hover, setHover] = useState(false);
  const [orderQuantity, setOrderQuantity] = useState("0");

  const [basketType, setBasketType] = useState(null);

  useEffect(() => {
    if (!productMark.type) return;
    if (productMark.type === "product" && id === productMark.id) {
      dispatch(resetMark);
      rowRef.current.scrollIntoView({
        alignToTop: true,
        behavior: "smooth",
      });
    }
  }, [productMark, dispatch, id]);

  useEffect(() => {
    if (!basket) return;

    // set basket type to block adding product to basket if product is the other type type
    const basketIds = Object.keys(basket);
    if (basketIds.length) {
      const productValues = products.find(
        (el) => el.product_id == basketIds[0]
      );
      setBasketType(
        productValues.sub_range_title === "PRODUITS COVID" ? "covid" : "basic"
      );
    } else setBasketType(null);

    // set product quantity
    if (basket[id]) {
      setOrderQuantity(basket[id]);
    } else {
      setOrderQuantity("0");
    }
  }, [id, basket]);

  const handleRemoveFromBasket = (id) => {
    dispatch(removeItemBasketCookie(+cookies.get("pharmacy"), id)); //DEBUG_BASKET
  };

  const handleSetOrderQuantity = (val) => {
    dispatch(updateBasketCookie(+cookies.get("pharmacy"), id, val.toString()));
    setOrderQuantity(val);
    if (basketUpdateRef.current) {
      basketUpdateRef.current.handleTemporaryDisplay();
    }
  };

  const handleOnMouseEnter = (event) => {
    setHover(true);
  };

  const handleOnMouseLeave = (event) => {
    setHover(false);
  };

  const products = useSelector((state) => state.products.values);

  const orderQuantityInt = parseInt(orderQuantity, 10);

  const discountLevels = getDiscountLevels(baseDiscounts, discountThresholds);
  const adjustedLevels = getAdjustedLevels(
    product.product_id,
    discountLevels,
    discountGroupProducts,
    basket
  );

  const discountLevelsFormatted = formatDiscountLevels(
    keepBestDiscounts(discountLevels)
  );

  const discountLevel = getDiscountLevel(adjustedLevels, orderQuantityInt);
  const pricePostDiscount = priceWithDiscount(price, discountLevel);
  const totalWithoutTaxes = getTotalWithoutTaxes(
    price,
    orderQuantityInt,
    discountLevel
  );
  const totalWithTaxes = getTotalWithTaxes(totalWithoutTaxes, tva);

  const noStockColumnSpan = priceWithTaxesColumn ? 5 : 4;

  return (
    <StyledTr ref={rowRef} color={color} bottomColor="#f3f3f4">
      <ImageTd>
        <Img>
          <img
            src={`${environment.imageBasePath}${imagePath}`}
            // DEBUG
            // alt={`${title}`}
            alt={"image"}
          />
        </Img>
      </ImageTd>
      <StyledTd>
        <Link to={`/produit/${id}`}>
          <ProductTitleEan
            title={title}
            ean={ean}
            brand={brand}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
        </Link>
      </StyledTd>
      <StyledTd centered>
        {hover ? (
          <pre>{discountLevelsFormatted}</pre>
        ) : (
          <pre style={{ color: "rgba(0, 0, 0, 0)" }}>
            {discountLevelsFormatted}
          </pre>
        )}
      </StyledTd>
      <StyledTd centered>{batch ? batch : "0"}</StyledTd>
      <StyledTd centered>{`${tva ? tva : "0"}%`}</StyledTd>
      <StyledTd centered>{formatAmount(price ? price : "0")}</StyledTd>
      <StyledTd centered>{`${discountLevel}%`}</StyledTd>
      <StyledTd centered>{formatAmount(pricePostDiscount)}</StyledTd>
      {(isCovidProduct && basketType === "basic") ||
      (!isCovidProduct && basketType === "covid") ? (
        <StyledTd colSpan={noStockColumnSpan} centered>
          <p>Produit incompatible avec votre panier</p>
        </StyledTd>
      ) : stock < (batch ? batch : 0) ? (
        <StyledTd colSpan={noStockColumnSpan} centered>
          {product.restock_date &&
          new Date(product.restock_date) > new Date() ? (
            <p>Retour le {formatUSDate(new Date(product.restock_date))}</p>
          ) : (
            <p>Produit victime de son succès</p>
          )}
        </StyledTd>
      ) : (
        <>
          <StyledTd centered>
            <ProductBasketControls
              orderQuantity={orderQuantity}
              stock={stock ? stock : 0}
              setOrderQuantity={handleSetOrderQuantity}
              batchQuantity={batch ? batch : 0}
            />
          </StyledTd>
          <StyledTd centered>{formatAmount(totalWithoutTaxes)}</StyledTd>
          {!priceWithTaxesColumn ? null : (
            <StyledTd centered>{formatAmount(totalWithTaxes)}</StyledTd>
          )}
          <Td>
            <BasketUpdated ref={basketUpdateRef} />
          </Td>
          {!orderQuantity || orderQuantity === "0" ? (
            <>
              <Td colSpan={1}>
                <NoIconDiv />
              </Td>
            </>
          ) : (
            <>
              <Td>
                <IconButton onClick={() => handleRemoveFromBasket(id)}>
                  <FontAwesomeIcon icon={faTrash} />
                </IconButton>
              </Td>
            </>
          )}
        </>
      )}
    </StyledTr>
  );
};

const ProductsTable = ({
  products,
  priceWithTaxesColumn = false,
  baseDiscounts,
  discountThresholds,
  discountGroupProducts,
}) => {
  // DEBUG
  const columns = [
    "",
    "Produit",
    "",
    "Volume",
    "TVA",
    "Prix",
    "Remise",
    "Prix net",
    "Qté",
    "Total HT",
  ];

  if (priceWithTaxesColumn) {
    columns.push("Total TTC");
  }

  return (
    products &&
    products.length && (
      <Table className="responsive-static">
        <Thead>
          <StyledTr color="rgba(255, 255, 255, 0.9)">
            {columns.map((columnName, idx) => (
              <StyledTh
                key={idx}
                bottomColor="#f3f3f4"
                centered={idx !== 1}
                minWidth={idx >= columns.length - 2 ? "90px" : undefined}
              >
                {columnName}
              </StyledTh>
            ))}
            <StyledTh colSpan={2} />
          </StyledTr>
        </Thead>
        <Tbody>
          {products.map((product: Product, idx) => (
            <ProductRow
              key={idx}
              id={product.product_id}
              imagePath={product.image_path}
              title={product.product_title}
              brand={product.brand}
              ean={product.ean_id}
              stock={product.stock}
              batch={product.batch_quantity}
              tva={product.tva_percentage}
              price={product.price_eur}
              isCovidProduct={product.sub_range_title == "PRODUITS COVID"}
              product={product}
              color={idx % 2 === 0 ? "rgba(255, 255, 255, 0.9)" : "#f3f9f9"}
              priceWithTaxesColumn={priceWithTaxesColumn}
              baseDiscounts={baseDiscounts[product.subRangeId]}
              discountThresholds={discountThresholds.filter((d) =>
                product.discount_groups?.includes(d.discount_group_id)
              )}
              discountGroupProducts={Object.fromEntries(
                Object.entries(discountGroupProducts).filter(([key]) =>
                  product.discount_groups?.includes(+key)
                )
              )}
            />
          ))}
        </Tbody>
      </Table>
    )
  );
};

export default ProductsTable;
