import { faTrash, faPen, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Accordion,
  ActionIcon,
  Button,
  Divider,
  Group,
  Paper,
  Stack,
  Text,
} from "@mantine/core";
import React from "react";

// Todo: setup theme to use correct colors for each user type
function BaseDiscountsPanel({
  onEdit,
  onCreate,
  onDelete,
  baseDiscounts,
  ranges,
  pharmacyDiscountGroups,
}) {
  return (
    <>
      <Paper>
        <Group position="apart">
          <Text size="xl" weight={700}>
            Réductions par Gammes
          </Text>
          <Button
            onClick={onCreate}
            leftIcon={<FontAwesomeIcon icon={faPlus} />}
          >
            Ajouter
          </Button>
        </Group>
        <Text mb="md" size="sm" color="gray">
          La valeur indiquée ici sera la réduction par défaut pour cette gamme
          et ce groupement de pharmacies, avant éventuelles réductions par
          paliers.
        </Text>

        <Accordion radius="xs" chevronPosition="left">
          {Object.keys(baseDiscounts).map((rangeId) => {
            const rangeName =
              ranges.find((range) => range.id == rangeId)?.title ?? rangeId;
            return (
              <Accordion.Item key={rangeId} value={rangeName}>
                <Accordion.Control
                  fs="md"
                  style={{ textTransform: "capitalize" }}
                >
                  {rangeName}
                </Accordion.Control>
                <Accordion.Panel>
                  <Stack spacing="xs">
                    {baseDiscounts[rangeId].map((baseDiscount) => {
                      const groupName = pharmacyDiscountGroups.find(
                        (group) =>
                          group.id === baseDiscount.pharmacy_discount_group_id
                      )?.name;
                      return (
                        <Group
                          key={baseDiscount.pharmacy_discount_group_id}
                          position="apart"
                        >
                          <span>
                            <b>{`${groupName}: `}</b>{" "}
                            {`${baseDiscount.discount}%`}
                          </span>
                          <Group spacing={"xs"}>
                            <ActionIcon
                              color="primary"
                              variant="light"
                              title="delete"
                              onClick={() => onDelete(baseDiscount)}
                            >
                              <FontAwesomeIcon icon={faTrash} />
                            </ActionIcon>
                            <ActionIcon
                              color="primary"
                              variant="filled"
                              title="edit"
                              onClick={() => onEdit(baseDiscount)}
                            >
                              <FontAwesomeIcon icon={faPen} />
                            </ActionIcon>
                          </Group>
                        </Group>
                      );
                    })}
                  </Stack>
                </Accordion.Panel>
              </Accordion.Item>
            );
          })}
        </Accordion>
      </Paper>
    </>
  );
}

export default BaseDiscountsPanel;
