import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faCircleNotch } from "@fortawesome/free-solid-svg-icons";

import Layout from "../../components/Layout/Layout";
import ProductsTable from "../../components/Products/ProductsTable";
import ProductsOrderRecap from "../../components/Products/ProductsOrderRecap";

import PageHeader from "../../components/PageHeader/PageHeader";
import CtaButton from "../../components/Buttons/CtaButton";

import { emptyBasket } from "../../store/actions/basket.action";
import Product from "../../components/Products/Product.interface";
import {
  getDiscountLevel,
  getTotalWithoutTaxes,
  getTotalWithTaxes,
  getDiscountLevels,
  getAdjustedLevels,
  getProductCouponDiscountLevel,
} from "../../utils/prices";
import { formatAmount } from "../../utils/text-formatting.utils";
import {  post } from "../../services/utils.service";
import { fetchProducts } from "../../store/actions/products.action";
import Cookies from "universal-cookie";
import {
  RESET_PHARMACY_DATA,
  setDefaultInformationSettings,
} from "../../store/actions/information.action";
import { useDiscounts } from "../../hooks/useDiscounts";
import { Center } from "@mantine/core";
import { isPharmacyUser } from "../../utils/client.utils";
import CouponInput from "./CouponInput";

const ProductList = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  min-width: fit-content;
`;

const AddBasketFooter = styled.div`
  width: 100%;
  height: 32px;
  background-color: rgba(255, 255, 255, 0.9);
`;

const SumUpTable = styled.div`
  background-color: white;
  overflow-x: scroll;
`;

const TotalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-end;
  min-width: fit-content;
  max-width: 40%;

  gap: 1em;
  margin-top: 2em;
  margin-left: auto;
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  padding: 16px;
  background-color: white;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-content: center;
  padding: 5px;

  &.main {
    margin: 32px 0;
  }
`;

const Label = styled.div`
  margin-right: 64px;
  font-weight: bold;
`;

const Text = styled.div`
  &.bold {
    font-weight: bold;
  }

  &.white {
    color: white;
  }

  &.is20 {
    font-size: 20px;
  }
`;

const AlignRightWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 32px;
`;

const EmptyBasketIcon = styled.div`
  margin-left: 8px;
  svg {
    path {
      fill: white;
    }
  }
`;

const CenteredText = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 64px;
  background-color: white;

  div {
    margin-left: 32px;
    p {
      text-align: center;
    }
  }
`;

const CenteredTexts = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 32px;
  margin-bottom: 32px;
  background-color: white;

  p {
    text-align: center;
  }
`;

// DEBUG: for TVA, map with all possible values
const Basket = () => {
  const cookies = new Cookies();
  const dispatch = useDispatch();
  const pharmacyData = useSelector(
    (state) => state.information.informationState
  );

  const basket = useSelector(
    (state) => state.basket[cookies.get("pharmacy") ?? "0"] ?? {}
  );
  const products = useSelector((state) => state.products.values);

  const [isFetchingProducts, setIsFetchingProducts] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [submitted, setSubmitted] = useState(false);
  const [errorWithOrder, setErrorWithOrder] = useState(false);
  const [francoError, setFrancoError] = useState({
    error: false,
    amount: null,
  });
  const [confirmedBasket, setConfirmedBasket] = useState([]);
  const [orderRef, setOrderRef] = useState("");

  const [validatedCoupon, setValidatedCoupon] = useState<{
    code: string
    discountedProducts: number[]
    discountAmount: number
    minimumTotal?: number
  }>();

  useEffect(() => {
    setIsFetchingProducts(true);
    dispatch(setDefaultInformationSettings);
    // TODO, Why do we fetch all products, and not only the ones in the cart ?
    dispatch(fetchProducts(setIsFetchingProducts));
  }, []);

  const productsInBasket: Product[] = !products
    ? []
    : (Object.values(products).filter((p: Product) =>
        Object.keys(basket).includes(p.product_id.toString())
      ) as Product[]);

  const { baseDiscountsByRange, discountThresholds, discountGroupProducts } =
    useDiscounts();

  const productsInBasketWithPrice = productsInBasket.map((product) => {
    const quantity = +basket[product.product_id];
    const discountLevels = getDiscountLevels(
      baseDiscountsByRange[product.subRangeId],
      discountThresholds.filter((d) =>
        product.discount_groups?.includes(d.discount_group_id)
      )
    );
    const adjustedLevels = getAdjustedLevels(
      product.product_id,
      discountLevels,
      Object.fromEntries(
        Object.entries(discountGroupProducts).filter(([key]) =>
          product.discount_groups?.includes(+key)
        )
      ),
      basket
    );
    const discountLevel: number = getDiscountLevel(adjustedLevels, quantity);


    return {
      ...product,
      quantity: quantity,
      discountLevel,
     
    };
  });

  const getPriceData = () => {
    const data: {
      totalPrice: number;
      totalWithoutTaxes: number;
      totalWithTaxes: number;
      taxes: { [key: string]: number };
      discountSum: number;
      totalWithTaxesBeforeCoupon: number;
    } = {
      totalPrice: 0,
      totalWithoutTaxes: 0,
      totalWithTaxes: 0,
      taxes: {},
      discountSum: 0,
      totalWithTaxesBeforeCoupon: 0,
    };

    const totalWithoutTaxesBeforeCoupon = productsInBasketWithPrice.reduce(
      (acc, product) => acc + getTotalWithoutTaxes(
        product.price_eur,
        product.quantity,
        product.discountLevel
      ),
      0
    );

    for (const product of productsInBasketWithPrice) {

      let discountLevel = product.discountLevel
      if (validatedCoupon && totalWithoutTaxesBeforeCoupon > validatedCoupon?.minimumTotal) {
        discountLevel = getProductCouponDiscountLevel(validatedCoupon, product)
      }

      const totalWithoutTaxes = getTotalWithoutTaxes(
        product.price_eur,
        product.quantity,
        discountLevel
      );
      const totalWithTaxes = getTotalWithTaxes(
        totalWithoutTaxes,
        product.tva_percentage
      ); 

      const totalPrice = product.price_eur * product.quantity;
      data.totalPrice += totalPrice;
      data.totalWithTaxes += totalWithTaxes;
      data.totalWithoutTaxes += totalWithoutTaxes;
      data.discountSum += totalPrice - totalWithoutTaxes;
      data.taxes = {
        ...data.taxes,
        [product.tva_percentage]: data.taxes[product.tva_percentage]
          ? data.taxes[product.tva_percentage] +
            (totalWithTaxes - totalWithoutTaxes)
          : totalWithTaxes - totalWithoutTaxes,
      };
    }

    return data;
  };

  const priceData = getPriceData();


  const handleBasketEmptying = () => {
    dispatch(emptyBasket);
  };

  const isBasketContainingCovidAndNonCovidProducts = (inBasketProducts) => {
    if (inBasketProducts.length === 0) return false;

    const productRangeTitles = Array.from(
      new Set(inBasketProducts.map((x) => x.sub_range_title))
    );

    if (
      productRangeTitles.includes("PRODUITS COVID") &&
      productRangeTitles.length > 1
    )
      return true;
    return false;
  };

  // DEBUG: please add a verification and all the other needed features around that call
  const handleBasketLaunch = async () => {
    if (isSubmitting) return;
    setIsSubmitting(true);
    const formattedBasket = Object.keys(basket).map((key) => ({
      id: key,
      quantity: basket[key],
    }));

    // Condition to doucle-check if there are not mixed products
    if (isBasketContainingCovidAndNonCovidProducts(productsInBasketWithPrice)) {
      console.log(
        "Basket contains mixed products: covid and not covid. cannot process order."
      );
      alert(
        `Votre panier contient des produits covid ainsi que des produits non covid.
La commande ne peut pas être lancée.
Veuillez retirer les produits covid ou bien les produits non covid du panier pour continuer.`
      );
      setIsSubmitting(false);
      return;
    }

    let errorFranco = false;
    const res = await post("/orders", {
      products: formattedBasket,
      couponCode: validatedCoupon?.code,
    });
    const parseRes = await res.json();
    // GERER ERREUR FRANCO
    if (!parseRes.orderReference && parseRes.francoAmount) {
      errorFranco = true;
      setFrancoError({ error: true, amount: parseRes.francoAmount });
    } else {
      setOrderRef(parseRes.orderReference);
    }
    // DEBUG
    if (res.ok && !errorFranco) {
      setSubmitted(true);
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    } else {
      setErrorWithOrder(true);
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
    setIsSubmitting(false);
    if (res.ok && !errorFranco) {
      setConfirmedBasket([...productsInBasketWithPrice]);
    }
  };


  return (
    <Layout>
      {errorWithOrder ? (
        <>
          <PageHeader title={"Commande - Erreur"} />
          <CenteredText>
            {francoError.error ? (
              <p>
                Le montant minimum de commande pour votre groupement est de{" "}
                {francoError.amount} € H.T.
              </p>
            ) : (
              <p>
                Il y a eu un problème lors du passage de votre commande. Votre
                panier n'a pas été vidé, nous vous invitons à réessayer
                ultérieurement.
              </p>
            )}
            <CtaButton
              onClick={() => {
                setSubmitted(false);
                setErrorWithOrder(false);
                setFrancoError({ error: false, amount: null });
              }}
            >
              <p>Retourner sur le panier</p>
            </CtaButton>
          </CenteredText>
        </>
      ) : submitted ? (
        <ConfirmationPage
          inBasketProducts={confirmedBasket}
          amountWithoutTaxes={priceData.totalWithoutTaxes}
          discountSum={priceData.discountSum}
          taxes={priceData.taxes}
          amountWithTaxes={priceData.totalWithTaxes}
          order_reference={orderRef}
          handleBasketEmptying={handleBasketEmptying}
          pharmacyData={pharmacyData}
          baseDiscounts={baseDiscountsByRange}
          discountThresholds={discountThresholds}
          discountGroupProducts={discountGroupProducts}
        />
      ) : (
        <>
          <PageHeader title={"Panier"} />
          {productsInBasketWithPrice && productsInBasketWithPrice.length ? (
            <>
              <AlignRightWrapper>
                <CtaButton onClick={handleBasketEmptying}>
                  <p>Vider votre panier</p>
                  <EmptyBasketIcon>
                    <FontAwesomeIcon icon={faTrash} />
                  </EmptyBasketIcon>
                </CtaButton>
              </AlignRightWrapper>
              <SumUpTable>
                <ProductList>
                  <ProductsTable
                    products={productsInBasketWithPrice}
                    baseDiscounts={baseDiscountsByRange}
                    discountThresholds={discountThresholds}
                    discountGroupProducts={discountGroupProducts}
                  />
                  <AddBasketFooter />
                </ProductList>
              </SumUpTable>
            </>
          ) : (
            <Center>
              <Text className="white is20">Votre panier est vide</Text>
            </Center>
          )}
          {productsInBasketWithPrice && productsInBasketWithPrice.length ? (
            <TotalWrapper>
              <CouponInput onValidateCoupon={(coupon) => setValidatedCoupon(coupon)} />
              <List>
                <Row>
                  <Label>Nombre de lignes saisies</Label>
                  <div>{Object.keys(basket)?.length}</div>
                </Row>
                <Row>
                  <Label>Total avant remises</Label>
                  <div>{formatAmount(priceData.totalPrice)}</div>
                </Row>
                <Row>
                  <Label>Remise totale</Label>
                  <div>{formatAmount(priceData.discountSum)}</div>
                </Row>
                <Row>
                  <Label>Total TTC</Label>
                  <div>{formatAmount(priceData.totalWithTaxes)}</div>
                </Row>
                {!priceData.taxes || !Object.keys(priceData.taxes).length
                  ? null
                  : Object.keys(priceData.taxes)
                      .sort((a, b) => parseFloat(a) - parseFloat(b))
                      .map((key, idx) => (
                        <Row key={idx}>
                          <Label>{`TVA ${key}%`}</Label>
                          <div>{formatAmount(priceData.taxes[key])}</div>
                        </Row>
                      ))}
                <Row className="main">
                  <Label>TOTAL HT</Label>
                  <Text className="bold">
                    {formatAmount(priceData.totalWithoutTaxes)}
                  </Text>
                </Row>
                <CtaButton disabled={isSubmitting} onClick={handleBasketLaunch}>
                  <p style={{ marginRight: "10px" }}>Passer commande</p>
                  {isSubmitting && (
                    <FontAwesomeIcon icon={faCircleNotch} color="white" spin />
                  )}
                </CtaButton>
              </List>
            </TotalWrapper>
          ) : null}
        </>
      )}
    </Layout>
  );
};

const ConfirmationPage = ({
  inBasketProducts,
  amountWithoutTaxes,
  discountSum,
  taxes,
  amountWithTaxes,
  order_reference,
  handleBasketEmptying,
  pharmacyData,
  // Get rid of these props
  baseDiscounts,
  discountThresholds,
  discountGroupProducts,
}) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const { FirstName } = user;

  useEffect(() => {
    const alertUser = (e) => {
      handleBasketEmptying();
      if (!isPharmacyUser(user)) {
        const cookies = new Cookies();
        cookies.remove("pharmacy", {
          path: "/",
          domain: ".leadersante-groupe.fr",
        });
      }
      dispatch({
        type: RESET_PHARMACY_DATA,
      });
      e.preventDefault();
    };
    window.addEventListener("beforeunload", alertUser);
    return () => {
      handleBasketEmptying();
      if (!isPharmacyUser(user)) {
        const cookies = new Cookies();
        cookies.remove("pharmacy", {
          path: "/",
          domain: ".leadersante-groupe.fr",
        });
      }
      dispatch({
        type: RESET_PHARMACY_DATA,
      });
      window.removeEventListener("beforeunload", alertUser);
    };
  }, []);

  return (
    <>
      <PageHeader title={"Confirmation de commande"} />
      <CenteredTexts>
        <p>Merci {FirstName ? FirstName : ""} pour votre commande</p>
        <p>
          <strong>{pharmacyData.LegalName}</strong> (CIP: {pharmacyData.CIP})
        </p>
        <p>
          Référence de la commande : <strong>{order_reference}</strong>
        </p>
        <br />
        <p>Vous la recevrez d'ici 5 jours ouvrés.</p>
        <p>
          Attention d'écrire « sous réserve » sur votre BL à réception de la
          commande.
        </p>
      </CenteredTexts>
      <SumUpTable>
        <ProductList>
          <ProductsOrderRecap
            products={inBasketProducts}
            baseDiscounts={baseDiscounts}
            discountThresholds={discountThresholds}
            discountGroupProducts={discountGroupProducts}
          />
          <AddBasketFooter />
        </ProductList>
      </SumUpTable>
      <TotalWrapper>
        <List>
          <Row>
            <Label>Total HT</Label>
            <div>{formatAmount(amountWithoutTaxes)}</div>
          </Row>
          <Row>
            <Label>Remise totale</Label>
            <div>{formatAmount(discountSum)}</div>
          </Row>
          {!taxes || !Object.keys(taxes).length
            ? null
            : Object.keys(taxes)
                .sort((a, b) => parseFloat(a) - parseFloat(b))
                .map((key, idx) => (
                  <Row key={idx}>
                    <Label>{`TVA ${key}%`}</Label>
                    <div>{formatAmount(taxes[key])}</div>
                  </Row>
                ))}
          <Row className="main">
            <Label>TOTAL TTC</Label>
            <Text className="bold">{formatAmount(amountWithTaxes)}</Text>
          </Row>
        </List>
      </TotalWrapper>
    </>
  );
};

export default Basket;
