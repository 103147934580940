import React from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

import Layout from "../../components/Layout/Layout";
import PageHeader from "../../components/PageHeader/PageHeader";
import CtaButton from "../../components/Buttons/CtaButton";

const CenteredText = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 64px;
  background-color: white;
  gap: 1em;
  div {
    margin-left: 32px;
    p {
      text-align: center;
    }
  }
`;

const NoCommands = ({ text }) => {
  const history = useHistory();

  const handleRedirect = () => {
    history.push("/");
  };

  return (
    <>
      <CenteredText>
        <p>{text}</p>
        <CtaButton onClick={handleRedirect}>
          <p>Passer une commande</p>
        </CtaButton>
      </CenteredText>
    </>
  );
};

export default NoCommands;
