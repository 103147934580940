import React, { useEffect, useState } from "react";

import { get } from "../../services/utils.service";
import { Group, Paper, Table, Text } from "@mantine/core";
import ProductCard from "../ProductCard";
import { DatePickerInput } from "@mantine/dates";
import dayjs from "dayjs";
import Layout from "../Layout/Layout";
import { formatCurrency } from "../../utils/prices";
import { useMediaQuery } from "@mantine/hooks";

const TopProducts = () => {
  const [topProducts, setTopProducts] = useState([]);
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
    dayjs().subtract(1, "month").toDate(),
    new Date(),
  ]);

  useEffect(() => {
    if (dateRange[0] && dateRange[1]) {
      fetchTopProducts();
    }
  }, [dateRange]);

  const fetchTopProducts = async () => {
    const params = new URLSearchParams();
    params.append("start", dateRange[0].toISOString().split("T")[0]);
    params.append("end", dateRange[1].toISOString().split("T")[0]);
    const response = await get("/product/top-products/all?" + params);
    const topProducts = await response.json();
    setTopProducts(topProducts);
  };

  const smallHeight = useMediaQuery("(min-height: 40em)");

  return (
    <Layout>
      <Paper w="100%" p="md">
        <Group position="apart" mb="md">
          <div>
            <Text fz="xl" fw="bold">
              Top produits
            </Text>
            <Text fz="sm" color="dimmed">
              Produits les plus commandés selon une période donnée.
            </Text>
          </div>
          <DatePickerInput
            valueFormat="DD MMMM YYYY"
            type="range"
            dropdownType={smallHeight ? "popover" : "modal"}
            allowSingleDateInRange
            maxDate={new Date()}
            value={dateRange}
            onChange={setDateRange}
          />
        </Group>

        {!topProducts || !topProducts.length ? (
          <p>Pas de produits commandés sur cette période.</p>
        ) : (
          <Table>
            <thead>
              <tr>
                <th></th>
                <th>Produit</th>
                <th>Quantité (U)</th>
                <th>CA (HT)</th>
              </tr>
            </thead>
            <tbody>
              {topProducts.map((product, idx) => (
                <tr key={product.id}>
                  <td>{idx + 1}</td>
                  <td>
                    <ProductCard
                      productId={product.id}
                      productName={product.title}
                      productImage={product.image}
                      productEanId={product.ean_id}
                    />
                  </td>
                  <td>{product.qty_sold}</td>
                  <td>{formatCurrency(product.revenue ?? 0)}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </Paper>
      ;
    </Layout>
  );
};

export default TopProducts;
