import React from "react";
import styled from "styled-components";
import { get } from "../../services/utils.service";
import variables from "../../themes/variables.theme";
import moment from "moment";

import { CardHeader } from "../paper/paper.component";
import { selectTheme } from "../../utils/theme.utils";
import { useSelector } from "react-redux";

const LightNote = styled.p`
  color: white;
  padding: 0 32px;
  margin-bottom: 16px;
  font-weight: 300;
  font-size: 14px;
  font-style: italic;
`;

const Button = styled.button<{ color: string }>`
  width: 250px;
  height: 50px;
  border-radius: 20px;
  background-color: ${(props) => props.color};
  color: ${variables.white};
  margin-left: 15px;
`;

const CovidOrders = (props) => {
  // const { catalogueInformations, fetchCatalogueInformations } = props;
  const user = useSelector((state) => state.user);

  const downloadFile = async () => {
    try {
      const response = await get("/orders/covidCSV");
      const stringCSV = await response.json();

      const url = window.URL.createObjectURL(new Blob([stringCSV[0]]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `Commandes PRODUITS COVID du ${moment().format("DD-MM-YYYY")}.csv`
      );
      document.body.appendChild(link);
      // Start download
      link.click();
      // Clean up and remove the link
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error("error", error); //DEBUG: handle this error
    }
  };

  return (
    <>
      <CardHeader>
        <span className="card-title">Commandes covid</span>
      </CardHeader>
      <div style={{ margin: "10px" }}></div>
      <LightNote>
        Vous pouvez télécharger ici les commandes de la journée au format csv
      </LightNote>
      <Button
        onClick={() => downloadFile()}
        style={{ cursor: "pointer" }}
        color={selectTheme(user.Role)}
      >
        Télécharger les commandes COVID
      </Button>
    </>
  );
};

export default CovidOrders;
