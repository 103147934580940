import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import variables from "../../themes/variables.theme";
import { selectTheme } from "../../utils/theme.utils";

const Button = styled.div<{ color: string }>`
  cursor: pointer;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;

  &:hover {
    transition: 0.3s;
    background-color: ${(props) => props.color};
    svg {
      path {
        transition: 0.1s;
        fill: white;
      }
    }
  }
`;

const IconButton = ({ onClick = (e) => {}, children }) => {
  const user = useSelector((state) => state.user);

  return (
    <Button onClick={onClick} color={selectTheme(user.Role)}>
      {children}
    </Button>
  );
};

export default IconButton;
