import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import styled from "styled-components";

import variables from "../../themes/variables.theme";

const CheckedBasket = styled.div`
  margin-left: 10px;
  height: 20px;
  width: 20px;
`;

const BasketUpdated = forwardRef((props, ref) => {
  const [change, setChange] = useState(null);
  const [wasUpdated, setWasUpdated] = useState(false);

  useEffect(() => {
    if (change === null) return;
    setWasUpdated(true);
    const timer = setTimeout(() => setWasUpdated(false), 1500);
    return () => {
      clearTimeout(timer);
    };
  }, [change]);

  useImperativeHandle(ref, () => ({
    handleTemporaryDisplay() {
      setChange(!change);
    },
  }));

  return (
    <CheckedBasket>
      {!wasUpdated ? null : (
        <svg version="1.1" x="40px" y="40px" viewBox="0 0 512 512">
          <path
            fill={variables.charcoalGreyTwo}
            d="M394.667,256C318.122,255.906,256.094,193.878,256,117.333c0-3.605,0.277-7.125,0.533-10.667H96
            c-5.891,0.005-10.662,4.785-10.657,10.677c0.001,0.655,0.062,1.309,0.182,1.953l32,170.667c0.944,5.043,5.344,8.699,10.475,8.704
            h292.992c26.9,0.003,49.592-20.027,52.928-46.72l2.901-23.168C453.072,246.443,424.265,255.988,394.667,256z"
          />
          <g>
            <circle
              fill={variables.charcoalGreyTwo}
              cx="394.667"
              cy="458.667"
              r="53.333"
            />
            <circle
              fill={variables.charcoalGreyTwo}
              cx="181.333"
              cy="458.667"
              r="53.333"
            />
            <path
              fill={variables.charcoalGreyTwo}
              d="M437.333,384H191.125c-35.561-0.074-66.163-25.156-73.216-60.011L65.92,64H10.667
              C4.776,64,0,59.224,0,53.333s4.776-10.667,10.667-10.667h64c5.07-0.001,9.439,3.566,10.453,8.533l53.717,268.587
              c5.035,24.896,26.888,42.817,52.288,42.88h246.208c5.891,0,10.667,4.776,10.667,10.667S443.224,384,437.333,384z"
            />
          </g>
          <circle fill="#4CAF50" cx="394.667" cy="117.333" r="117.333" />
          <path
            fill="#FAFAFA"
            d="M373.333,170.667c-2.831,0.005-5.548-1.115-7.552-3.115l-42.667-42.667
            c-4.092-4.237-3.975-10.99,0.262-15.083c4.134-3.992,10.687-3.992,14.82,0l35.136,35.115l77.781-77.781
            c4.093-4.237,10.845-4.354,15.083-0.262s4.354,10.845,0.262,15.083c-0.086,0.089-0.173,0.176-0.262,0.262l-85.333,85.333
            C378.865,169.546,376.157,170.666,373.333,170.667z"
          />
        </svg>
      )}
    </CheckedBasket>
  );
});

export default BasketUpdated;
