import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import Layout from "../../components/Layout/Layout";
import PageHeader from "../../components/PageHeader/PageHeader";
import {
  Table,
  Tbody,
  Thead,
  Tr,
  CenteredTh,
  ClickableTr,
  ClickableDarkedTr,
  CenteredTd,
  SpecialSecondaryTr,
} from "../../components/table/table.component";
import { Td, IconButton, EditableTr } from "../../components/table/EditableTr";
import { get, post, put } from "../../services/utils.service";
import { formatProductTitle } from "../../utils/text-formatting.utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";

const LightNote = styled.p`
  color: white;
  padding: 0 32px;
  margin-bottom: 16px;
  font-weight: 300;
  font-size: 14px;
  font-style: italic;
`;

const RedCenteredTd = styled(CenteredTd)`
  color: red;
`;

const CenteredTdText = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 50px;
  margin: 10px;
`;

const columns = ["Intitule produit", "Identifiant ean", "Prix"];

const editableKeys = ["Intitule produit", "Identifiant ean"];

const canBeModified = {
  "Intitule produit": true,
  "Identifiant ean": true,
  Prix: false,
};

const Catalogue = () => {
  const [products, setProducts] = useState([]);
  const [insertingSecondaryNewLine, setInsertingSecondaryNewline] =
    useState(false);

  const history = useHistory();

  const getUpdates = async () => {
    try {
      const response = await get("/product/update");
      const parseResponse = await response.json();
      setProducts(parseResponse);
    } catch (error) {
      console.error(error);
    }
  };

  const handleProductRedirect = (event, productId: number) => {
    event.preventDefault();
    history.push(`produit/${productId}`);
  };

  const handleProductCreate = async (event, product) => {
    event.preventDefault();
    const response = await post("/product/update", product);
    const parseResponse = await response.json();
    if (parseResponse) history.go(0);
  };

  const createNewProduct = async (productInfos) => {
    if (
      !productInfos ||
      !productInfos["Intitule produit"] ||
      !productInfos["Identifiant ean"]
    )
      return;
    const toSave = {
      title: productInfos["Intitule produit"].trim().toUpperCase(),
      ean: productInfos["Identifiant ean"],
    };

    const response = await post("/product/", toSave);
    const parseResponse = await response.json();
    if (parseResponse) history.go(0);
  };

  useEffect(() => {
    getUpdates();
  }, []);

  return (
    <Layout>
      <PageHeader
        title="Catalogue Produit"
        // buttonText="Télécharger le catalogue"
        // searchPlaceholder="Recherche d'un EAN, gamme ou d'un intitulé"
      />
      <LightNote>
        * les lignes grisés correspondent aux produits d'eurodep.
        <br /> Vous pouvez cliquer dessus pour les ajouter a vos produits et les
        customiser.
        <br /> Les produits dont l'identifiant ean est deja present chez
        Leadersante ne seront pas réajouté.
      </LightNote>
      <LightNote>
        * les lignes avec un texte en rouge correspondent aux produits non
        activés.
      </LightNote>
      <Table className="responsive-static">
        <Thead>
          <Tr>
            {columns.map((column, idx) => (
              <CenteredTh key={idx}>{column}</CenteredTh>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {!insertingSecondaryNewLine ? (
            <SpecialSecondaryTr>
              <Td>
                <IconButton onClick={() => setInsertingSecondaryNewline(true)}>
                  <CenteredTdText>
                    <FontAwesomeIcon icon={faPlusCircle} />
                    Créer un produit
                  </CenteredTdText>
                </IconButton>
              </Td>
              <Td />
              <Td />
            </SpecialSecondaryTr>
          ) : (
            <EditableTr
              className="hoverable"
              keys={editableKeys}
              canBeModified={canBeModified}
              values={{}}
              onSave={createNewProduct}
              onCancel={() => setInsertingSecondaryNewline(false)}
              editByDefault
            />
          )}
          {products.map((product, idx) =>
            product.update ? (
              <ClickableDarkedTr
                key={idx}
                onClick={(e) => handleProductCreate(e, product)}
              >
                <CenteredTd>{formatProductTitle(product.title)}</CenteredTd>
                <CenteredTd>{product.ean_id}</CenteredTd>
                <CenteredTd>{product.price_eur}</CenteredTd>
              </ClickableDarkedTr>
            ) : (
              <ClickableTr
                key={idx}
                onClick={(e) => handleProductRedirect(e, product.id)}
              >
                {product.activated ? (
                  <>
                    <CenteredTd>{formatProductTitle(product.title)}</CenteredTd>
                    <CenteredTd>{product.ean_id}</CenteredTd>
                    <CenteredTd>{product.price_eur}</CenteredTd>
                  </>
                ) : (
                  <>
                    <RedCenteredTd>
                      {formatProductTitle(product.title)}
                    </RedCenteredTd>
                    <RedCenteredTd>{product.ean_id}</RedCenteredTd>
                    <RedCenteredTd>{product.price_eur}</RedCenteredTd>
                  </>
                )}
              </ClickableTr>
            )
          )}
          {products.length ? (
            <SpecialSecondaryTr>
              <Td>
                <IconButton onClick={() => setInsertingSecondaryNewline(true)}>
                  <CenteredTdText>
                    <FontAwesomeIcon icon={faPlusCircle} />
                    Créer un produit
                  </CenteredTdText>
                </IconButton>
              </Td>
              <Td />
              <Td />
            </SpecialSecondaryTr>
          ) : null}
        </Tbody>
      </Table>
    </Layout>
  );
};

export default Catalogue;
