import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import Layout from "../../components/Layout/Layout";
import TopProducts from "../../components/Metrics/TopProducts";
import GrossSalesPerPeriod from "../../components/Metrics/GrossSalesPerPeriod";
import PageHeader from "../../components/PageHeader/PageHeader";
import {
  Table,
  Th,
  Tr,
  Td,
  Thead,
  Tbody,
} from "../../components/table/table.component";
import NoPharmacyError from "../../components/EmptyPage/NoPharmacyError";

import variables from "../../themes/variables.theme";
import { selectTheme } from "../../utils/theme.utils";

const Section = styled.div`
  width: 100%;
  padding: 32px;
  margin-bottom: 32px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: flex-start;
  background-color: white;
`;

const Title = styled.h2``;

const Subtitle = styled.h3`
  margin-top: 32px;
`;

const Note = styled.p`
  font-style: italic;
  font-size: 14px;
  margin-bottom: 16px;
`;

const ListBloc = styled.div`
  padding-left: 32px;
`;

const BulletPointWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 8px;
`;

const Dot = styled.div<{ color: string }>`
  width: 6px;
  height: 6px;
  margin-right: 8px;
  border-radius: 50%;
  background-color: ${(props) => props.color};
`;

const StyledTable = styled(Table)`
  max-width: 400px;
`;

const ErrorMessage = styled.p`
  color: ${variables.danger};
`;

const BulletPoint = ({ text }) => {
  const user = useSelector((state) => state.user);

  return (
    <BulletPointWrapper>
      <Dot color={selectTheme(user.Role)} />
      <p>{text}</p>
    </BulletPointWrapper>
  );
};

const Metrics = () => {
  const pharmacyData = useSelector(
    (state) => state.information.informationState
  );

  const grossQuarterSalesApothicare = pharmacyData.quarterly;
  const grossYearSalesApothicare = pharmacyData.yearly;

  if (pharmacyData.statusCode && pharmacyData.pharmacyData !== 200) {
    return <NoPharmacyError title="Mes Chiffres" />;
  }

  return (
    <Layout>
      <PageHeader title="Mes Chiffres" />
      <Section>
        <Title>Mes top produits</Title>
        <Note>Produits les plus commandés sur la période sélectionnée</Note>
        <TopProducts />
      </Section>
    </Layout>
  );
};

export default Metrics;
