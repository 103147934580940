import {
  Badge,
  Button,
  Card,
  Group,
  Paper,
  SimpleGrid,
  Text,
  Image,
  List,
  Avatar,
  Table,
  ActionIcon,
  Modal,
  TextInput,
  MultiSelect,
  NumberInput,
  Container,
  Stack,
  Select,
  Space,
  Pagination,
} from "@mantine/core";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import React, { forwardRef, useState } from "react";
import {
  getDiscountGroups,
  getPharmacyDiscountGroups,
  getProductSearchResults,
  updateDiscountGroup,
} from "../../services/discounts.service";
import { environment } from "../../environments";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPen,
  faPlus,
  faSearch,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { useDebouncedState, useDisclosure } from "@mantine/hooks";
import { isFunctionExpression } from "typescript";
import DiscountGroupRow from "./DiscountGroupRow";

function DiscountGroupTable({ discountGroups, onEdit }) {
  return (
    <>
      {/* <SimpleGrid cols={3} verticalSpacing="sm">
                {discountGroups?.map((discountGroup) => (
                    <DiscountCard key={discountGroup.id} discountGroup={discountGroup} />
                ))}
            </SimpleGrid> */}

      <Table highlightOnHover>
        <thead>
          <tr>
            <th>Produits</th>
            <th>Groupes de réduction</th>
            <th>Réductions</th>
          </tr>
        </thead>
        <tbody>
          {discountGroups?.map((discountGroup) => (
            <DiscountGroupRow
              key={discountGroup.id}
              discountGroup={discountGroup}
              onEdit={() => onEdit(discountGroup)}
            />
          ))}
        </tbody>
      </Table>
    </>
  );
}

export default DiscountGroupTable;
