import React, { useRef } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import variables from "../../themes/variables.theme";
import { selectTheme } from "../../utils/theme.utils";

const Button = styled.button<{ color: string }>`
  width: 250px;
  height: 50px;
  border-radius: 20px;
  background-color: ${(props) => props.color};
  color: ${variables.white};
  margin: 15px;
`;

const LightNote = styled.p`
  color: white;
  padding: 0 32px;
  margin-bottom: 16px;
  font-weight: 300;
  font-size: 20px;
`;

const FileUploader = ({ informations, handleFile, buttonText }) => {
  const hiddenFileInput = useRef(null);

  const user = useSelector((state) => state.user);

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };
  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    handleFile(fileUploaded);
  };

  if (!informations) return <></>;

  return (
    <>
      <Button
        onClick={handleClick}
        style={{ cursor: "pointer" }}
        color={selectTheme(user.Role)}
      >
        {buttonText}
      </Button>
      <input
        type="file"
        ref={hiddenFileInput}
        onChange={handleChange}
        style={{ display: "none" }}
      />
      <LightNote>
        {" "}
        {informations.filename} - téléversé le {informations.date}
      </LightNote>
    </>
  );
};

export default FileUploader;
