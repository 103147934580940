import { get } from "./utils.service";

export const getMe = async () => {
  try {
    const res = await get("/users/");
    return await res.json();
  } catch (e) {
    return null;
  }
};

export const getUser = async (userId: number) => {
  try {
    const res = await get(`/users/${userId}`);
    return await res.json();
  } catch (error) {
    return null;
  }
};
