export const SET_FILTERS = "SET_FILTERS";
export const RESET_FILTERS = "RESET_FILTERS";

const initialState = [];

const FilterReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_FILTERS:
      return [...action.data];
    case RESET_FILTERS:
      return initialState;
    default:
      return [...state];
  }
};

export default FilterReducer;
