import React, { useState } from "react";
import styled from "styled-components";
import { get, put } from "../../services/utils.service";
import { CardHeader } from "../paper/paper.component";
import { Table, Tbody, Th, Thead, Tr, EditableTr } from "../table/EditableTr";

const LightNote = styled.p`
  color: white;
  padding: 0 32px;
  margin-bottom: 16px;
  font-style: italic;
  font-weight: 300;
  font-size: 14px;
`;

const AdminFrancos = ({ groupements, fetchAllFrancosAmounts }) => {
  const keys = ["intitule", "amount"];

  const canBeModified = {
    intitule: false,
    amount: true,
  };

  const handleGammeUpdate = (newValue) => {
    put("/francos", newValue).then((res) => {
      fetchAllFrancosAmounts();
    });
  };

  const handleGammeDelete = (newValue) => {
    newValue["amount"] = "0";
    put("/francos", newValue).then((res) => {
      fetchAllFrancosAmounts();
    });
  };

  return (
    <>
      <CardHeader>
        <span className="card-title">Francos</span>
      </CardHeader>
      <div style={{ marginBottom: "20px" }}></div>
      <LightNote>
        Vous devez entre un nombre pour modifier le montant
        <br />
        Vos modifications seront ignorées si vous laissez un vide ou vous
        utilisez des caracteres non numériques
      </LightNote>
      <div style={{ marginBottom: "20px" }}></div>
      <div>
        <Table className="responsive-static">
          <Thead>
            <Tr>
              <Th>Groupement</Th>
              <Th>Montant</Th>
              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {groupements.map((groupement, idx: number) => {
              return (
                <>
                  <EditableTr
                    key={idx}
                    className="hoverable"
                    keys={keys}
                    canBeModified={canBeModified}
                    values={groupement || {}}
                    onSave={handleGammeUpdate}
                    deleteButton
                    onDelete={handleGammeDelete}
                  />
                </>
              );
            })}
          </Tbody>
        </Table>
      </div>
    </>
  );
};

export default AdminFrancos;
