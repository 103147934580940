import React from "react";
import styled from "styled-components";

const Button = styled.div<{ content: string; variant?: string; color: string }>`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: center;

  background-color: ${(props) => props.color}; // DEBUG: orange
  height: ${({ variant }) => (variant === "big" ? "42px" : "26px")};
  width: ${({ variant }) => (variant === "big" ? "42px" : "26px")};
  border-radius: 50%;

  > div {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-content: center;

    font-size: ${({ variant }) => (variant === "big" ? "18px" : "14px")};
    line-height: ${({ variant }) => (variant === "big" ? "42px" : "26px")};

    width: 100%;
    height: 100%;
    color: white;
  }

  &:hover {
    background-color: ${(props) => props.color}; // DEBUG: orange-light
    cursor: pointer;
  }
`;

const RoundButton = ({
  content,
  onClick = () => {},
  variant = undefined,
  color,
}) => {
  return (
    <Button variant={variant} content={content} onClick={onClick} color={color}>
      <div>{content}</div>
    </Button>
  );
};

export default RoundButton;
