import React from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { formatAmount } from "../../utils/text-formatting.utils";
import { List, Paper, Text } from "@mantine/core";
import { isPharmacyUser } from "../../utils/client.utils";

const Wrapper = styled.div`
  width: max-content;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  width: max-content;
  margin-bottom: 20px;
  @media (max-width: 1032px) {
    margin-top: 10px;
    margin-bottom: 0;
  }
`;

const PageTitle = styled.h4`
  font-size: 24px;
  font-weight: 500;
  color: white;
  margin: 0;
`;

const PageText = styled.p`
  font-size: 16px;
  font-weight: 300;
  color: white;
`;

const LastOrder = (props: {
  person?: string;
  orderDate?: string;
  amount?: number;
  error?: boolean;
}) => {
  const user = useSelector((state) => state.user);

  const { error, person, orderDate, amount } = props;

  if (isPharmacyUser(user)) return null;

  return (
    <Paper p="sm" h="100%" sx={{ backgroundColor: "rgba(255, 255, 255, 0.9)" }}>
      <Text size="lg" fw="bold">
        Dernière commande
      </Text>
      {error && (
        <Text>
          Erreur lors de la récuperation d'informations sur la dernière commande
        </Text>
      )}
      {!error && (!person || !orderDate || !amount) && (
        <Text>Pas encore de commandes pour cette pharmacie</Text>
      )}
      {!error && person && orderDate && amount && (
        <List>
          <List.Item>
            <Text>Commanditaire : {person}</Text>
          </List.Item>
          <List.Item>
            <Text>Date: {orderDate}</Text>
          </List.Item>
          <List.Item>
            <Text>Montant: {formatAmount(amount)}</Text>
          </List.Item>
        </List>
      )}
    </Paper>
  );
};

export default LastOrder;
