import { faTruck, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
// import Cookies from "js-cookie";
import Cookies from "universal-cookie";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import IconButton from "../../components/Buttons/IconButton";

import Layout from "../../components/Layout/Layout";
import PageHeader from "../../components/PageHeader/PageHeader";
import PharmacieSelector from "../../components/PharmacieSelector/PharmacieSelector";
import PaginateClientAccount from "../../components/Paginate/PaginateClientAccount";
import {
  CenteredTd,
  CenteredTh,
  Table,
  Tbody,
  Thead,
  Tr,
} from "../../components/table/table.component";
import { get } from "../../services/utils.service";
import {
  fetchPharmacyList,
  getPharmacyData,
  setDefaultInformationSettings,
} from "../../store/actions/information.action";
import { formatUSDate } from "../../utils/dates.utils";
import { formatAmount } from "../../utils/text-formatting.utils";
import {
  emptyBasket,
  updateBasketCookie,
} from "../../store/actions/basket.action";
import { useHistory } from "react-router-dom";

import CancelOrderModal from "../../components/CancelOrderModal/CancelOrderModal";
import { Center, Paper, Stack, Text } from "@mantine/core";
import NoCommands from "../../components/EmptyPage/NoCommands";

const PharmacyTitle = styled.h4`
  font-size: 20px;
  font-weight: 500;
  color: white;
  margin: 0;
`;

const CenteredDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const ClientAccount = () => {
  const cookies = new Cookies();
  const dispatch = useDispatch();
  const pharmacyData = useSelector(
    (state) => state.information.informationState
  );
  const pharmacies = useSelector((state) => state.information.pharmacies);
  const user = useSelector((state) => state.user);

  const [pharmacyOrders, setPharmacyOrders] = useState([]);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [orderReferenceToBeCancelled, setOrderReferenceToBeCancelled] =
    useState(null);

  const history = useHistory();

  useEffect(() => {
    dispatch(fetchPharmacyList);
  }, []);

  const columns = [
    "Date",
    "Référence",
    "Statut",
    "Total HT (€)",
    "Total TTC (€)",
    "Effectué par",
    "Dupliquer",
    "Annuler / Refaire",
  ];

  const fetchPharmacyOrders = async (
    pageNumber?: string,
    numberPerPage?: string
  ) => {
    try {
      let query = "/orders/pharmacy";
      if (!(pageNumber === null || pageNumber === undefined)) {
        const perPage = numberPerPage ? +numberPerPage : 5;
        query += "?offset=" + +pageNumber * perPage;
        query += "&limit=" + perPage;
      }
      const response = await get(query);
      const orders = await response.json();
      if (orders.statusCode && orders.statusCode != 200)
        throw "Error while fetching orders";
      const formattedOrders = orders.map((order) => {
        return {
          ...order,
          order_date: formatUSDate(new Date(order.order_date)),
        };
      });
      setPharmacyOrders(formattedOrders);
    } catch (error) {
      console.error(error); //DEBUG: manage this error
    } finally {
    }
  };

  useEffect(() => {
    fetchPharmacyOrders("0", "30");
  }, [pharmacyData]);

  const fetchPharmacies = async (pharmacyId: number) => {
    const response = await get("/users/pharmacy-id/" + pharmacyId);
    const cookieId = await response.json();
    dispatch(getPharmacyData(cookieId));
  };

  const fetchOrder = async (order_reference: string) => {
    try {
      const response = await get(`/orders/order/${order_reference}`);
      const orders = await response.json();
      dispatch(emptyBasket);
      for (const order of orders) {
        dispatch(
          updateBasketCookie(
            +cookies.get("pharmacy"),
            order.product_id,
            order.quantity
          )
        );
      }
    } catch (error) {
      console.error(error); //DEBUG: manage this error
    }
  };

  const handlePharmacySet = (pharmacyId: number) => {
    fetchPharmacies(pharmacyId);
  };

  const handleDuplication = (event, orderReference: string) => {
    event.stopPropagation();
    fetchOrder(orderReference);
    history.push("/basket");
  };

  const handleCheckDetails = (orderReference: string) => {
    history.push(`/order/${orderReference}`);
  };

  const handleCancelOrder = async (event, orderReference: string) => {
    event.stopPropagation();
    setOrderReferenceToBeCancelled(orderReference);
    setModalIsOpen(true);
  };

  const displayTrash = (order) =>
    order.order_status_fk === 6 && !order.is_covid; // display trash if order is pending

  useEffect(() => {
    dispatch(setDefaultInformationSettings);
  }, [dispatch]);

  return (
    <Layout>
      <PageHeader title="Voir compte client" />

      <Paper
        p="sm"
        mb="md"
        sx={{ backgroundColor: "rgba(255, 255, 255, 0.9)" }}
      >
        <Stack h="100%" spacing="xs">
          <Text size="lg" fw="bold">
            Sélecteur de Pharmacie
          </Text>
          <PharmacieSelector
            idDecorator="desktop"
            pharmacyData={pharmacyData}
            pharmacies={pharmacies}
            onSelection={handlePharmacySet}
          />
          <Text size="sm">
            {pharmacyData?.PharmacyGroup
              ? "Pharmacie " +
                pharmacyData?.PharmacyGroup +
                (pharmacyData?.CIP ? ` (${pharmacyData?.CIP})` : "")
              : "Aucune pharmacie sélectionnée"}
          </Text>
        </Stack>
      </Paper>

      {pharmacyData?.PharmacyGroup &&
        (!pharmacyOrders || !pharmacyOrders.length) && (
          <NoCommands text="Vous n'avez pas encore passé de commande pour cette pharmacie" />
        )}

      {pharmacyOrders && pharmacyOrders.length && (
        <Paper>
          <Table className="responsive-static">
            <Thead>
              <Tr>
                {columns.map((h, idx) => (
                  <CenteredTh key={idx}>{h}</CenteredTh>
                ))}
              </Tr>
            </Thead>
            <Tbody>
              {pharmacyOrders.map((order, idx) => (
                <Tr
                  className="hoverable"
                  key={idx}
                  onClick={() => handleCheckDetails(order.order_reference)}
                  style={{ cursor: "pointer" }}
                >
                  <CenteredTd>{order.order_date}</CenteredTd>

                  <CenteredTd>{order.order_reference}</CenteredTd>

                  <CenteredTd>{order.intitule_status}</CenteredTd>
                  <CenteredTd>
                    {`${formatAmount(order.total_ht, false)}`}
                  </CenteredTd>
                  <CenteredTd>
                    {`${formatAmount(order.total_ttc, false)}`}
                  </CenteredTd>
                  <CenteredTd>
                    {order.user_fk === user.UserId
                      ? "Vous"
                      : order.userRole === "admin"
                      ? "Admin"
                      : ["user", "userAccountant"].includes(order.userRole)
                      ? "Pharmacien"
                      : order.userRole === "salesperson"
                      ? "Commercial"
                      : ""}
                  </CenteredTd>
                  <CenteredTd>
                    <CenteredDiv>
                      <IconButton
                        onClick={(e) =>
                          handleDuplication(e, order.order_reference)
                        }
                      >
                        <FontAwesomeIcon icon={faTruck} />
                      </IconButton>
                    </CenteredDiv>
                  </CenteredTd>
                  <CenteredTd>
                    <CenteredDiv>
                      {displayTrash(order) && (
                        <IconButton
                          onClick={(e) =>
                            handleCancelOrder(e, order.order_reference)
                          }
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </IconButton>
                      )}
                    </CenteredDiv>
                  </CenteredTd>
                </Tr>
              ))}
            </Tbody>
          </Table>
          <Center>
            <PaginateClientAccount
              pharmacyData={pharmacyData}
              fetchPharmacyOrders={fetchPharmacyOrders}
            />
          </Center>
        </Paper>
      )}

      <CancelOrderModal
        isVisible={modalIsOpen}
        setIsVisible={setModalIsOpen}
        orderReferenceToBeCancelled={orderReferenceToBeCancelled}
        setOrderReferenceToBeCancelled={setOrderReferenceToBeCancelled}
        fetchOrders={fetchPharmacyOrders}
        fetchOrder={fetchOrder}
      />
    </Layout>
  );
};

export default ClientAccount;
