import React from "react";
import { MultiSelect } from "@mantine/core";

function PharmacyDiscountGroupSelector({ data, links, onAdd, onRemove }) {
  const value = links
    ?.map((link) => link.pharmacy_discount_group_id?.toString())
    ?.filter((x) => x !== undefined);

  const onChange = (newValue) => {
    const difference = newValue
      .filter((x) => !value.includes(x))
      .concat(value.filter((x) => !newValue.includes(x)));

    if (difference.length !== 1) return;

    if (newValue.length > value.length) {
      onAdd(difference[0]);
    } else if (newValue.length < value.length) {
      onRemove(difference[0]);
    }
  };

  return <MultiSelect data={data} value={value} onChange={onChange} />;
}

export default PharmacyDiscountGroupSelector;
