import BeatLoader from "react-spinners/BeatLoader";
import CtaButton from "../../components/Buttons/CtaButton";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Modal from "react-modal";
import { useHistory } from "react-router-dom";

import { get } from "../../services/utils.service";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const ModalTextContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-bottom: 10px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 30px;
  height: 40px;

  .btn-modal {
    min-width: 60px;
  }
`;

interface Props {
  isVisible: boolean;
  setIsVisible: (boolean) => void;
  orderReferenceToBeCancelled: string | null;
  setOrderReferenceToBeCancelled: (string) => void;
  fetchOrders: any;
  fetchOrder: any;
}

const CancelOrderModal = ({
  isVisible,
  setIsVisible,
  orderReferenceToBeCancelled,
  setOrderReferenceToBeCancelled,
  fetchOrders,
  fetchOrder,
}: Props) => {
  const initialModalText = "Confirmez-vous l´annulation de cette commande ?";

  const [showLoadingSpinner, setShowLoadingSpinner] = useState(false);
  const [isCancellationSuccessful, setIsCancellationSuccessful] =
    useState(null);
  const [modalText, setModalText] = useState(initialModalText);
  const [showModalButtons, setShowModalButtons] = useState(true);

  const history = useHistory();

  const resetModalVariables = () => {
    setModalText(initialModalText);
    setOrderReferenceToBeCancelled(null);
    setShowLoadingSpinner(false);
    setIsCancellationSuccessful(null);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const cancelOrder = async () => {
    if (!isVisible || !orderReferenceToBeCancelled) return;
    setModalText("Annulation en cours...");
    setShowLoadingSpinner(true);
    setTimeout(() => {}, 2000);
    const isSuccess = await get(
      `/orders/orderchangePtoC/${orderReferenceToBeCancelled}`
    );
    setIsCancellationSuccessful(isSuccess);
  };

  const handleDuplication = (event, orderReference: string) => {
    event.stopPropagation();
    fetchOrder(orderReference);
    history.push("/basket");
  };

  const cancelOrderAndFillPanier = async () => {
    await handleDuplication(event, orderReferenceToBeCancelled);
    setTimeout(() => {}, 2000);
    await cancelOrder();
  };

  useEffect(() => {
    if (isCancellationSuccessful === null) return;
    if (isCancellationSuccessful) {
      fetchOrders();
      setShowModalButtons(false);
      setModalText("La commande a bien été annulée");
      setShowLoadingSpinner(false);
      setTimeout(() => {
        setIsVisible(false);
        resetModalVariables();
      }, 2000);
    } else {
      setModalText("La commande n´a pas pu être annulée");
      setTimeout(() => {
        setIsVisible(false);
        resetModalVariables();
      }, 2000);
    }
  }, [isCancellationSuccessful]);

  return (
    <Modal
      shouldCloseOnOverlayClick={true}
      isOpen={isVisible}
      style={customStyles}
    >
      <ModalTextContainer>{modalText}</ModalTextContainer>
      {showLoadingSpinner ? (
        <ButtonsContainer>
          <BeatLoader color="black" loading={showLoadingSpinner} size={15} />
        </ButtonsContainer>
      ) : (
        showModalButtons && (
          <ButtonsContainer>
            <CtaButton className="btn-modal" onClick={cancelOrder}>
              Oui
            </CtaButton>
            <CtaButton className="btn-modal" onClick={cancelOrderAndFillPanier}>
              Oui (et remplir le panier avec les mêmes produits/quantités)
            </CtaButton>
            <CtaButton
              className="btn-modal"
              onClick={() => setIsVisible(false)}
            >
              Non
            </CtaButton>
          </ButtonsContainer>
        )
      )}
    </Modal>
  );
};

export default CancelOrderModal;
