export const formatUSDate = (date: Date): string => {
  const dd = String(date.getDate()).padStart(2, "0");
  const mm = String(date.getMonth() + 1).padStart(2, "0");
  const yyyy = date.getFullYear();

  return `${yyyy}-${mm}-${dd}`;
};

export const formatUSDateHHMM = (date: Date): string => {
  const m = String(date.getMinutes()).padStart(2, "0");
  const hh = String(date.getHours() + 2).padStart(2, "0");
  const dd = String(date.getDate()).padStart(2, "0");
  const mm = String(date.getMonth() + 1).padStart(2, "0");
  const yyyy = date.getFullYear();

  return `${yyyy}-${mm}-${dd} ${hh}:${m}`;
};

export const getTodayUS = () => formatUSDate(new Date());

export const getPastDate = (dateStr, format) => {
  const date = new Date(dateStr);

  const [minusYear, minusMonth, minusDay] = format
    .split("-")
    .map((str) => parseInt(str, 10));

  date.setFullYear(date.getFullYear() - minusYear);
  date.setMonth(date.getMonth() - minusMonth);
  date.setDate(date.getDate() - minusDay);

  return formatUSDate(date);
};
