import { faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Avatar,
  Badge,
  List,
  ActionIcon,
  Text,
  Image,
  Group,
  HoverCard,
  Stack,
  Divider,
} from "@mantine/core";
import { environment } from "../../environments";
import React from "react";
import { group } from "console";
import ProductCard from "../../components/ProductCard";

function DiscountGroupRow({ discountGroup, onEdit }) {
  return (
    <tr>
      <td>
        {/* Todo: extract as 'ImageGroup' Component with maxItems Prop */}
        {(discountGroup.products?.length ?? 0) == 0 ? (
          <Text span color="dimmed">
            Pas de produits
          </Text>
        ) : (
          <HoverCard shadow="md" openDelay={200}>
            <HoverCard.Target>
              {(discountGroup.products?.length ?? 0) > 3 ? (
                <Group>
                  {discountGroup.products?.slice(0, 3).map((product) => (
                    <Image
                      withPlaceholder
                      placeholder={
                        <Text
                          sx={{ textOverflow: "ellipsis", overflow: "hidden" }}
                          align="center"
                          size="xs"
                        >
                          {product.title.split(" ")[0]}
                        </Text>
                      }
                      fit="scale-down"
                      width={50}
                      height={50}
                      key={product.id}
                      src={`${environment.imageBasePath}${product.image}`}
                    />
                  ))}
                  <Avatar size="lg">
                    +{discountGroup.products?.length - 3}
                  </Avatar>
                </Group>
              ) : (
                <Group>
                  {discountGroup.products?.map((product) => (
                    <Image
                      withPlaceholder
                      placeholder={
                        <Text
                          sx={{ textOverflow: "ellipsis", overflow: "hidden" }}
                          align="center"
                          size="xs"
                        >
                          {product.title.split(" ")[0]}
                        </Text>
                      }
                      fit="scale-down"
                      width={50}
                      height={50}
                      key={product.id}
                      src={`${environment.imageBasePath}${product.image}`}
                    />
                  ))}
                </Group>
              )}
            </HoverCard.Target>
            <HoverCard.Dropdown>
              {discountGroup.products?.map((product, index) => (
                <>
                  <ProductCard
                    productId={product.id}
                    productEanId={product.ean_id}
                    productImage={product.image}
                    productName={product.title}
                  />
                  {index !== discountGroup.products.length - 1 && (
                    <Divider my="xs" />
                  )}
                </>
              ))}
            </HoverCard.Dropdown>
          </HoverCard>
        )}
      </td>
      <td>
        {(discountGroup.pharmacyGroups?.length ?? 0) == 0 ? (
          <Text span color="dimmed">
            Aucun groupe
          </Text>
        ) : (
          discountGroup.pharmacyGroups?.map((pharmacyGroup) => (
            <Badge size="md" variant="light">
              {pharmacyGroup.name}
            </Badge>
          ))
        )}
      </td>
      <td>
        {(discountGroup.discounts?.length ?? 0) == 0 ? (
          <Text span color="dimmed">
            Pas de paliers
          </Text>
        ) : (
          <List>
            {discountGroup.discounts.map((discount) => (
              <List.Item>
                <Text span>{discount.quantity} unités:</Text>{" "}
                <Text span fw={700}>
                  +{discount.additional_discount}%
                </Text>
              </List.Item>
            ))}
          </List>
        )}
      </td>
      <td>
        <ActionIcon
          color="primary"
          variant="light"
          title="edit"
          onClick={onEdit}
        >
          <FontAwesomeIcon icon={faPen} />
        </ActionIcon>
      </td>
    </tr>
  );
}

export default DiscountGroupRow;
