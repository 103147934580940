import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";

import Layout from "../../components/Layout/Layout";
import { Accordion, AccordionRow } from "../../components/Accordion/Accordion";
import ProductsTable from "../../components/Products/ProductsTable";
import PageHeader from "../../components/PageHeader/PageHeader";

import { fetchProducts } from "../../store/actions/products.action";
import { removeFilter } from "../../store/actions/filter.action";
import FacebookLogo from "./../../assets/imgs/facebook-logo.png";
import InstagramLogo from "./../../assets/imgs/instagram_logo.svg";

import LogoShop from "../../assets/imgs/logo-shop.png";
import PharmacieSelector from "../../components/PharmacieSelector/PharmacieSelector";
import { get } from "../../services/utils.service";
import {
  fetchPharmacyList,
  getPharmacyData,
  setDefaultInformationSettings,
} from "../../store/actions/information.action";
import LastOrder from "../../components/LastOrder/LastOrder";
import { formatUSDateHHMM } from "../../utils/dates.utils";
import CtaButton from "../../components/Buttons/CtaButton";
import { environment } from "../../environments";
import { getUser } from "../../services/users.service";
import { selectTheme } from "../../utils/theme.utils";
import { useDiscounts } from "../../hooks/useDiscounts";
import {
  Center,
  Grid,
  Group,
  List,
  Loader,
  Paper,
  Stack,
  Text,
} from "@mantine/core";
import { isPharmacyUser } from "../../utils/client.utils";

const Logo = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  justify-content: center;
  margin-bottom: 32px;

  img {
    max-height: 150px;
    max-width: 150px;
  }
`;

const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;

  @media (max-width: 1378px) {
    flex-direction: column;
  }
`;

const NewsBanner = styled.div`
  overflow: hidden;
  width: 100%;
  height: fit-content;
  margin-bottom: 32px;

  img {
    max-height: 100%;
    height: auto;
    width: 100%;
  }
`;

const ProductList = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  min-width: fit-content;
`;

const AddBasketFooter = styled.div`
  width: 100%;
  height: 32px;
  background-color: rgba(255, 255, 255, 0.9);
`;

const Footer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  margin: 20px 0 0px;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 36px;
`;

const FooterCta = styled.p`
  text-align: center;
  font-size: 14px;
  padding: 0;
  margin-bottom: 12px;
  line-height: 13px;
  color: #003e32;
`;

const Bold = styled.span`
  font-weight: bold;
`;

const BannerImage = styled.img`
  height: 100%;
  width: 100%;
  margin: auto;
`;
const BannerImageContainer = styled.div`
  max-width: 90%;
  background-color: transparent;
  margin: auto;
`;

const Logos = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;

  margin-top: 10px;

  img:first-child {
    margin-right: 10px;
  }
`;

const Filters = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 16px;
`;

const Filter = styled.div<{ color: string }>`
  padding: 6px 8px;
  background-color: ${(props) => props.color};
  color: white;
  font-size: 12px;
  margin-right: 16px;
  margin-bottom: 16px;

  &:last-child {
    margin-right: 0;
  }

  svg {
    margin-left: 8px;
    &:hover {
      cursor: pointer;
      color: #d8d8d8;
    }
  }
`;

const OrderPage = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const pharmacies = useSelector((state) => state.information.pharmacies);
  const orderedProducts = useSelector(
    (state) => state.products.orderedProducts
  );

  const filters = useSelector((state) => state.filters);
  const pharmacyData = useSelector(
    (state) => state.information.informationState
  );
  const basket = useSelector((state) => state.basket);

  const [isFetchingProducts, setIsFetchingProducts] = useState(false);
  const [products, setProducts] = useState({});
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [lastOrderData, setLastOrderData] = useState({
    person: "",
    orderDate: "",
    amount: 0,
    error: false,
  });
  const [bannerInformations, setBannerInformations] = useState({
    product_id: null,
    filename: null,
  });

  const [networkAnimator, setNetworkAnimator] = useState({
    name: "",
    email: "",
    phone: "",
  });

  const { baseDiscountsByRange, discountThresholds, discountGroupProducts } =
    useDiscounts();

  const history = useHistory();

  useEffect(() => {
    if (!isPharmacyUser(user)) {
      dispatch(fetchPharmacyList);
    }
    dispatch(setDefaultInformationSettings);
    setIsFetchingProducts(true);
    dispatch(fetchProducts(setIsFetchingProducts));
  }, [dispatch]);

  useEffect(() => {
    fetchLastOrder();
  }, [pharmacyData]);

  useEffect(() => {
    fetchBannerInformations();
  }, []);

  useEffect(() => {
    const isFiltersCompatible = (filters: string[], str: string): boolean => {
      for (const filter of filters) {
        if (str.toLowerCase().includes(filter.toLowerCase())) {
          return true;
        }
      }
      return false;
    };

    if (!orderedProducts) return;
    if (!filters || !filters.length) {
      setProducts(orderedProducts);
      setFilteredProducts([]);
      return;
    }

    const filteredProducts = Object.keys(orderedProducts).reduce(
      (rslt, range) => {
        const products = [];
        for (const family of Object.keys(orderedProducts[range])) {
          for (const product of orderedProducts[range][family]) {
            if (
              isFiltersCompatible(filters, product.product_title) ||
              isFiltersCompatible(filters, product.ean_id)
            ) {
              products.push(product);
            }
          }
        }
        return rslt.concat(products);
      },
      []
    );

    setFilteredProducts(filteredProducts);
  }, [orderedProducts, filters]);

  const handleRemoveFilter = (value) => {
    dispatch(removeFilter(value));
  };

  const fetchPharmacies = async (pharmacyId: number) => {
    const response = await get("/users/pharmacy-id/" + pharmacyId);
    const cookieId = await response.json();
    dispatch(getPharmacyData(cookieId));
    dispatch(fetchProducts(setIsFetchingProducts));
  };

  const fetchLastOrder = async () => {
    try {
      const response = await get("/orders/pharmacy");
      if (response.status !== 200)
        throw "Erreur lors de la recuperation de la derniere commande";
      const orders = await response.json();
      if (!orders.length) return;
      const lastOrder = {
        person: orders[0].user_fk,
        orderDate: formatUSDateHHMM(new Date(orders[0].order_date)),
        amount: orders[0].total_ttc,
        error: false,
      };
      setLastOrderData(lastOrder);
    } catch (error) {
      setLastOrderData({
        person: "",
        orderDate: "",
        amount: 0,
        error: true,
      });
    }
  };

  const fetchBannerInformations = async () => {
    try {
      const response = await get("/banner/informations");
      const parseResponse = await response.json();
      const { product_id, filename } = parseResponse;
      setBannerInformations({ product_id, filename });
    } catch (error) {
      console.error(error);
    }
  };

  const handlePharmacySet = (pharmacyId: number) => {
    fetchPharmacies(pharmacyId);
  };

  const handleValidateOrder = () => {
    history.push("/basket");
  };

  const fetchNetworkAnimator = async (userId: number) => {
    if (!userId) return;
    const user = await getUser(userId);
    const { LastName, FirstName, Email, PhoneNumber } = user;
    const result = {
      name:
        LastName && FirstName ? `${LastName.toUpperCase()} ${FirstName}` : "",
      email: Email ?? "",
      phone: PhoneNumber ?? "",
    };
    setNetworkAnimator(result);
  };

  useEffect(() => {
    fetchNetworkAnimator(pharmacyData?.networkAnimator);
  }, [pharmacyData?.networkAnimator]);

  return (
    <Layout>
      <Logo>
        <img alt="logo-leadersante-shop" src={LogoShop} />
      </Logo>
      <Link to={`/produit/${bannerInformations.product_id}`}>
        <NewsBanner>
          <BannerImageContainer>
            <BannerImage
              alt="banniere-nouveautes"
              src={`${environment.backendUrl}/fileUpload/${bannerInformations.filename}`}
            />
          </BannerImageContainer>
        </NewsBanner>
      </Link>
      <PageHeader title="Saisir une commande" />
      <Filters>
        {filters.map((filter, idx) => (
          <Filter key={idx} color={selectTheme(user.Role)}>
            {filter}
            <FontAwesomeIcon
              icon={faTimesCircle}
              onClick={() => handleRemoveFilter(filter)}
            />
          </Filter>
        ))}
      </Filters>

      {["admin", "salesperson"].includes(user.Role) && (
        <Grid align="stretch" mb="sm">
          <Grid.Col sm={6}>
            <Paper
              p="sm"
              h="100%"
              sx={{ backgroundColor: "rgba(255, 255, 255, 0.9)" }}
            >
              <Stack h="100%" spacing="xs">
                <Text size="lg" fw="bold">
                  Sélecteur de Pharmacie
                </Text>
                <PharmacieSelector
                  pharmacyData={pharmacyData}
                  pharmacies={pharmacies}
                  onSelection={handlePharmacySet}
                />
                <Text size="sm">
                  {pharmacyData?.PharmacyGroup
                    ? "Pharmacie " +
                      pharmacyData?.PharmacyGroup +
                      (pharmacyData?.CIP ? ` (${pharmacyData?.CIP})` : "")
                    : "Aucune pharmacie sélectionnée"}
                </Text>
              </Stack>
            </Paper>
          </Grid.Col>

          <Grid.Col sm={6}>
            <LastOrder
              error={lastOrderData.error}
              person={
                lastOrderData.person === user.UserId
                  ? "Vous"
                  : "Votre commercial"
              }
              orderDate={lastOrderData.orderDate}
              amount={lastOrderData.amount}
            />
          </Grid.Col>
        </Grid>
      )}

      {isFetchingProducts && (
        <Paper sx={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}>
          <Center h={200}>
            <Loader />
          </Center>
        </Paper>
      )}
      {!isFetchingProducts &&
        (filters.length ? (
          <ProductList>
            <ProductsTable
              products={filteredProducts}
              baseDiscounts={baseDiscountsByRange}
              discountThresholds={discountThresholds}
              discountGroupProducts={discountGroupProducts}
            />
            <AddBasketFooter />
          </ProductList>
        ) : (
          <Accordion>
            {products
              ? Object.entries(products)
                  ?.sort(([a], [b]) =>
                    a === "null"
                      ? Number.POSITIVE_INFINITY
                      : b === "null"
                      ? Number.NEGATIVE_INFINITY
                      : a.localeCompare(b)
                  )
                  .map(([key, value]: [string, unknown], index) => (
                    <AccordionRow
                      key={index}
                      title={key === "null" ? undefined : key}
                      color={
                        index % 2 === 0
                          ? "rgba(255, 255, 255, 0.8)"
                          : "rgba(255, 255, 255, 0.6)"
                      }
                    >
                      <Accordion>
                        {value
                          ? Object.entries(value)
                              ?.sort((a, b) => a[0].localeCompare(b[0]))
                              .map(([subKey, subValue], idx) =>
                                subKey === "null" ? (
                                  <ProductList key={idx}>
                                    <ProductsTable
                                      products={subValue}
                                      baseDiscounts={baseDiscountsByRange}
                                      discountThresholds={discountThresholds}
                                      discountGroupProducts={
                                        discountGroupProducts
                                      }
                                    />
                                    <AddBasketFooter />
                                  </ProductList>
                                ) : (
                                  <AccordionRow
                                    key={idx}
                                    title={subKey}
                                    parentTitle={
                                      key === "null" ? undefined : key
                                    }
                                    subRow
                                  >
                                    <ProductList>
                                      <ProductsTable
                                        products={subValue}
                                        baseDiscounts={baseDiscountsByRange}
                                        discountThresholds={discountThresholds}
                                        discountGroupProducts={
                                          discountGroupProducts
                                        }
                                      />
                                      <AddBasketFooter />
                                    </ProductList>
                                  </AccordionRow>
                                )
                              )
                          : null}
                      </Accordion>
                    </AccordionRow>
                  ))
              : null}
          </Accordion>
        ))}
      {Object.keys(basket)?.length ? (
        <div
          style={{ width: "240px", marginTop: "1em" }}
          onClick={handleValidateOrder}
        >
          <CtaButton>
            <p>Accéder à mon panier</p>
          </CtaButton>
        </div>
      ) : null}
      <Footer>
        <FooterCta>
          Pour les commandes de produits LeaderMarket et Outils Marketing, merci
          de vous rapprocher de votre commercial.
        </FooterCta>
        <FooterCta>
          {"N'hésitez pas à nous contacter au "}
          <Bold>{"01 41 05 45 62"}</Bold>
          {" ou via "}
          <Bold>{"commande@leadersante.fr"}</Bold>
        </FooterCta>
        <Logos>
          <a href="https://www.facebook.com/leadersantegroupe/" target="_blank">
            <img
              src={FacebookLogo}
              alt="facebook logo"
              width="24px"
              height="24px"
            />
          </a>
          <a href="https://www.instagram.com/leadersantegroupe" target="_blank">
            <img
              src={InstagramLogo}
              alt="instagram logo"
              width="24px"
              height="24px"
            />
          </a>
        </Logos>
      </Footer>
    </Layout>
  );
};

export default OrderPage;
