import { useEffect, useState } from "react";
import { get } from "../services/utils.service";
import { useDispatch, useSelector } from "react-redux";

export function useDiscounts() {
  const [baseDiscounts, setBaseDiscounts] = useState([]);
  const [discountThresholds, setDiscountThresholds] = useState([]);
  const [discountGroupProducts, setDiscountGroupProducts] = useState({});

  const pharmacyData = useSelector(
    (state) => state.information.informationState
  );

  const getBaseDiscounts = async () => {
    const response = await get("/discounts/base/user");
    if (!response.ok) {
      setBaseDiscounts([]);
      return;
    }
    const data = await response.json();
    setBaseDiscounts(data);
  };

  const getDiscountThresholds = async () => {
    const response = await get("/discounts/thresholds/user");
    if (!response.ok) {
      setDiscountThresholds([]);
      return;
    }
    const data = await response.json();
    setDiscountThresholds(data);
  };

  const getDiscountGroupProducts = async () => {
    const response = await get("/discounts/product-groups");
    if (!response.ok) {
      setDiscountGroupProducts({});
      return;
    }
    const data = await response.json();
    setDiscountGroupProducts(data);
  };

  useEffect(() => {
    getBaseDiscounts();
    getDiscountThresholds();
    getDiscountGroupProducts();
  }, [pharmacyData]);

  const thresholdsByGroup = discountThresholds.reduce((prev, current) => {
    if (!current.discount_group_id) return prev;
    if (!prev[current.discount_group_id]) prev[current.discount_group_id] = [];
    prev[current.discount_group_id].push(current);
    return prev;
  }, {});

  const baseDiscountsByRange = baseDiscounts.reduce((prev, current) => {
    if (!prev[current.sub_range_id]) prev[current.sub_range_id] = [];
    prev[current.sub_range_id].push(current);
    return prev;
  }, {});

  return {
    baseDiscounts,
    discountThresholds,
    thresholdsByGroup,
    baseDiscountsByRange,
    discountGroupProducts,
  };
}
