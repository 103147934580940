import { useEffect, useState } from "react";
import { get } from "../services/utils.service";

export function useProductDiscounts(productId: number) {
  const [discountThresholds, setDiscountThresholds] = useState([]);
  const [discountGroupProducts, setDiscountGroupProducts] = useState({});

  async function getDiscounts() {
    const response = await get(
      `/discounts/combined/user?productId=${productId}`
    );
    if (!response.ok) {
      setDiscountThresholds([]);
      return;
    }
    const discounts = await response.json();
    setDiscountThresholds(discounts);
  }

  async function getDiscountGroupProducts() {
    const response = await await get(`/discounts/product-groups/${productId}`);
    if (!response.ok) {
      setDiscountGroupProducts({});
      return;
    }
    const products = await response.json();
    setDiscountGroupProducts(products);
  }

  useEffect(() => {
    getDiscounts();
    getDiscountGroupProducts();
  }, []);

  return { discountThresholds, discountGroupProducts };
}
