import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import variables from "../../../themes/variables.theme";

import { formatProductTitle } from "../../../utils/text-formatting.utils";
import { selectTheme } from "../../../utils/theme.utils";

const ProductTitle = styled.div<{ color: string }>`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: flex-start;

  color: black;
  text-decoration: none;

  &:hover {
    transition: 0.2s;
    color: ${(props) => props.color};
    cursor: pointer;
  }
`;

const SubTitle = styled.div`
  font-weight: 300;
  font-size: 10px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
`;

const ProductTitleEan = ({
  title,
  ean,
  brand = undefined,
  onClick = undefined,
  onMouseEnter = null,
  onMouseLeave = null,
}) => {
  const user = useSelector((state) => state.user);

  return (
    <ProductTitle
      onClick={onClick}
      onMouseLeave={onMouseLeave}
      onMouseEnter={onMouseEnter}
      color={selectTheme(user.Role)}
    >
      <div>{formatProductTitle(title)}</div>
      <SubTitle>{`${brand ? brand + " - " : ""}${ean}`}</SubTitle>
    </ProductTitle>
  );
};

export default ProductTitleEan;
