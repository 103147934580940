import {
  SET_PRODUCT_MARK,
  RESET_PRODUCT_MARK,
} from "../actions/product-mark.action";

const initialState = {};

const ProductMarkReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PRODUCT_MARK:
      return {
        ...state,
        ...action.data,
      };
    case RESET_PRODUCT_MARK:
      return initialState;
    default:
      return {
        ...state,
      };
  }
};

export default ProductMarkReducer;
