import React, { useEffect, useState } from "react";
import styled from "styled-components";
import CtaButton from "../../components/Buttons/CtaButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileContract } from "@fortawesome/free-solid-svg-icons";
import { get } from "../../services/utils.service";
import Layout from "../../components/Layout/Layout";
import PageHeader from "../../components/PageHeader/PageHeader";
import NoCommands from "../../components/EmptyPage/NoCommands";
import PaginateOrders from "../../components/Paginate/PaginateOrders";

import {
  Table,
  CenteredTh,
  Tr,
  CenteredTd,
  Thead,
  Tbody,
} from "../../components/table/table.component";
import IconButton from "../../components/Buttons/IconButton";
import { formatUSDate } from "../../utils/dates.utils";
import { formatAmount } from "../../utils/text-formatting.utils";
import { useHistory } from "react-router-dom";

const CenteredText = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 64px;
  background-color: white;

  div {
    margin-left: 32px;
    p {
      text-align: center;
    }
  }
`;

const CenteredDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const Accounting = () => {
  const [orders, setOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorWithOrders, setErrorWithOrders] = useState(false);

  const history = useHistory();

  const columns = [
    "Date",
    "Référence",
    "Date livraison",
    "Total HT (€)",
    "Total TTC (€)",
  ];

  const fetchOrders = async (pageNumber?: string, numberPerPage?: string) => {
    try {
      let query = "/orders/me";
      if (!(pageNumber === null || pageNumber === undefined)) {
        const perPage = numberPerPage ? +numberPerPage : 5;
        query += "?offset=" + +pageNumber * perPage;
        query += "&limit=" + perPage;
      }
      const response = await get(query);
      const orders = await response.json();
      if (orders.statusCode && orders.statusCode != 200)
        throw "Error while fetching orders";
      const formattedOrders = orders.map((order) => {
        return {
          ...order,
          order_date: formatUSDate(new Date(order.order_date)),
          order_status_date: formatUSDate(new Date(order.order_status_date)),
        };
      });
      setOrders(formattedOrders);
    } catch (error) {
      setErrorWithOrders(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchOrders("0", "30");
  }, []);

  if (isLoading) {
    return (
      <Layout>
        <PageHeader title="Ma comptabilité" />
      </Layout>
    );
  }
  if (errorWithOrders) {
    return (
      <Layout>
        <PageHeader title={"Ma comptabilité - Erreur"} />
        <CenteredText>
          <p>
            Il y a eu un problème lors de la récuperation de votre historique de
            commande, nous vous invitons à recharger la page.
          </p>
          <CtaButton onClick={() => history.go(0)}>
            <p>Recharger la page</p>
          </CtaButton>
        </CenteredText>
      </Layout>
    );
  }

  if (!orders || !orders.length)
    return (
      <Layout>
        <PageHeader title="Ma comptabilité" />
        <NoCommands text="Vous n'avez pas encore passé de commande" />
      </Layout>
    );

  return (
    <Layout>
      <PageHeader title="Ma comptabilité" />
      <PaginateOrders fetchOrders={fetchOrders} />
      <Table className="responsive-static">
        <Thead>
          <Tr>
            {columns.map((h, idx) => (
              <CenteredTh key={idx}>{h}</CenteredTh>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {orders.map((order, idx) => (
            <Tr className="hoverable" key={idx}>
              <CenteredTd>{order.order_date}</CenteredTd>

              <CenteredTd>{order.order_reference}</CenteredTd>

              <CenteredTd>{order.order_status_date}</CenteredTd>
              <CenteredTd>
                {`${formatAmount(order.total_ht, false)}`}
              </CenteredTd>
              <CenteredTd>
                {`${formatAmount(order.total_ttc, false)}`}
              </CenteredTd>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Layout>
  );
};

export default Accounting;
