import styled from "styled-components";
import variables from "./../../themes/variables.theme";

type Props = {
  width?: string;
};

const Card = styled.div`
  width: 100%;
  border: none;
  border-radius: 4px;
  min-height: 10px;
  padding: 0;
  box-shadow: 0 2px 14px 0 rgba(203, 203, 203, 0.2);
  background-color: #ffffff;
  h2 {
    margin-bottom: 12px;
  }
  p {
    margin-bottom: 0;
  }
`;

const CardBody = styled.div`
  padding: 20px 32px;
  &.no-padding {
    padding: 0;
  }
  &.padding-small {
    padding: 16px 18px;
  }
`;

const CardHeader = styled.div`
  height: 69px;
  border-radius: 4px;
  background-color: #f7f9fa;
  display: flex;
  align-items: center;
  padding-left: 32px;
  padding-right: 32px;
  .card-title {
    font-size: 16px;
    color: ${variables.steel};
    margin-bottom: 0;
  }
`;

const Item = styled.div<Props>`
  display: flex;
  justify-content: center;
  width: ${(props) => (props.width ? props.width : "100%")};
  margin-left: 8px;
  margin-right: 8px;
  flex-wrap: wrap;
  @media (max-width: 1032px) {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-gap: 16px;
  margin-bottom: 16px;
`;
const Grid2 = styled(Grid)`
  grid-template-columns: repeat(2, 1fr);
`;
const Grid3 = styled(Grid)`
  grid-template-columns: repeat(3, 1fr);
  @media (max-width: 991px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 600px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;
const Grid4 = styled(Grid)`
  grid-template-columns: repeat(4, 1fr);
`;

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: -8px;
  margin-right: -8px;
  margin-bottom: 16px;
  &.align-center {
    align-items: center;
  }
  &.justify-between {
    justify-content: space-between;
  }
  @media (max-width: 1032px) {
    display: inline-grid;
    grid-gap: 16px;
    margin-left: 0;
    margin-right: 0;
    grid-template-columns: 100%;
    width: 100%;
  }
`;
const Half = styled.div`
  width: 491px;
  margin-left: 8px;
  margin-right: 8px;

  @media (max-width: 1032px) {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }
`;

const LinedCard = styled.div`
  border: solid 1px rgba(206, 212, 218, 0.25);
  padding: 14px 22px;
`;

const Center = styled.div`
  display: flex;
  justify-content: center;
`;

export interface styledContainerPropsType {
  justifyContent?: string;
  alignItems?: string;
  alignContent?: string;
  maxWidth?: string;
  margin?: string;
  width?: string;
  noWrap?: boolean;
  position?: string;
  left?: string;
  right?: string;
  top?: string;
}

export const Col = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: ${(props: styledContainerPropsType) =>
    props.justifyContent ? props.justifyContent : ""};
  align-items: ${(props: styledContainerPropsType) =>
    props.alignItems ? props.alignItems : ""};
  max-width: ${(props: styledContainerPropsType) =>
    props.maxWidth ? props.maxWidth : ""};
  width: ${(props) => props.width || ""};
`;

export {
  Grid,
  Grid2,
  Grid3,
  Grid4,
  Item,
  Row,
  Half,
  Card,
  CardHeader,
  CardBody,
  LinedCard,
  Center,
};
