export interface User {
  UserId: number;
  Email: string;
  Password: string;
  ClearPassword: string;
  FirstName: string;
  LastName: string;
  Phone: string;
  Fax: string;
  Address: string;
  ZipCode: string;
  City: string;
  Role: "user" | "admin" | "accountant" | "advancedUser";
  CreatedAt?: Date;
  UpdatedAt?: Date;
  IsTabord?: number;
  IsTabordPersonalData?: number;
  IsAppBackOffice?: number;
  PharmacyIds?: number[];
  PharmacyId?: number;
}

export function isAdminUser(user: User): boolean {
  return user && user.Role === "admin";
}

export function isPharmacyUser(user: User): boolean {
  return user && ["user", "userAccountant"].includes(user.Role);
}
