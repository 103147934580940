const variables = {
  primary: "#20212e",
  secondary: "#6d2b93",
  success: "#28A745",
  danger: "#DC3545",
  warning: "#ee8b2e",
  info: "#17A2B8",
  dark: "#38393b",
  light: "#F8F9FA",
  transparent: "transparent",
  white: "#ffffff",
  whiteTwo: "#f9f9f9",
  whiteThree: "#e5e5e5",
  whiteFour: "#f5f5f5",
  black: "#2a2a2a",
  black2: "#2a2a2a",
  black3: "#2a2a2a",
  blue: "#357cdf",
  paleGrey: "#eceef2",
  silver: "#ced4da",
  charcoalGrey: "#3d3e43",
  steel: "#7c8493",
  carolinaBlue: "#81bdff",
  gunmetal: "#495057",
  darkGrey: "#22252a",
  purple: "#8729a5",
  green: "#3cd316",
  darkYellow: "#cca000",
  pink: "#e53285",
  charcoalGreyTwo: "#323335",

  orange: "#e65e30",
  greenLS: "#a1c51c",
  blueLS: "#1d90bd",
  orangeLight: "#FC7A30",
  greenLightLS: "#d8ec8e",
  blueLightLS: "#8ad6f5",
  mobileBreakpoint: "768px",
};

export default variables;
