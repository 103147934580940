import Cookies from "universal-cookie";
import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import RoundButton from "../RoundButton/RoundButton";
import { selectTheme } from "../../utils/theme.utils";
import { isPharmacyUser } from "../../utils/client.utils";

const BasketControls = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: center;
`;

const BasketInput = styled.input`
  margin: 0 6px;
`;

const ProductBasketControls = ({
  orderQuantity,
  stock,
  setOrderQuantity,
  batchQuantity,
}) => {
  const [value, setValue] = useState("0");
  const ref = useRef(null);
  const user = useSelector((state) => state.user);

  const cookies = new Cookies();

  useEffect(() => {
    setValue(orderQuantity);
  }, [orderQuantity]);

  const handleSetValue = () => {
    if (!isPharmacyUser(user) && !cookies.get("pharmacy")) return;
    const int = parseInt(value, 10);
    let orderQuantity: number;
    if (user.Role !== "admin")
      orderQuantity = int < 0 ? 0 : int + (int % batchQuantity);
    else orderQuantity = int < 0 ? 0 : int;
    orderQuantity =
      orderQuantity > stock ? stock - (stock % batchQuantity) : orderQuantity;
    orderQuantity = orderQuantity <= 0 ? 0 : orderQuantity;
    setOrderQuantity(orderQuantity);
  };

  const handleQuantityChange = (e) => {
    if (!isPharmacyUser(user) && !cookies.get("pharmacy")) return;
    if (e.target.value === "") {
      setValue("");
      return;
    }

    const nbrReg = /^\d+$/;
    if (!nbrReg.test(e.target.value)) return;

    // Make sure value is a multiple of batch without being bigger than stock
    const int = parseInt(e.target.value, 10);
    const val =
      int < 0
        ? 0
        : int > stock
        ? stock
        : int < batchQuantity && user.Role !== "admin"
        ? batchQuantity
        : int;
    setValue(val.toString());
  };

  const handleQuantityIncrement = () => {
    if (!isPharmacyUser(user) && !cookies.get("pharmacy")) return;
    if (orderQuantity === "") {
      setOrderQuantity("0");
      return;
    }
    const int = parseInt(orderQuantity, 10);
    if (int > stock - batchQuantity) return;
    setOrderQuantity((int + batchQuantity).toString());
  };

  const handleQuantityDecrement = () => {
    if (!isPharmacyUser(user) && !cookies.get("pharmacy")) return;
    if (orderQuantity === "") {
      setOrderQuantity("0");
      return;
    }
    const int = parseInt(orderQuantity, 10);
    if (int - batchQuantity < 0) return;
    setOrderQuantity((int - batchQuantity).toString());
  };

  const handleOnBlur = () => {
    if (value === "") {
      setValue("0");
    }
    handleSetValue();
  };

  const handleKey = (e) => {
    if (e.key === "Enter") {
      handleSetValue();
      ref.current.blur();
    }
  };

  if (!cookies.get("pharmacy") && user?.Role === "admin") {
    return <p>Aucune pharmacie sélectionnée</p>;
  }

  return (
    <BasketControls>
      <RoundButton
        content="-"
        onClick={handleQuantityDecrement}
        color={selectTheme(user.Role)}
      />
      <BasketInput
        type="text"
        ref={ref}
        size={6}
        value={value}
        onChange={handleQuantityChange}
        onBlur={handleOnBlur}
        onKeyPress={handleKey}
      />
      <RoundButton
        content="+"
        onClick={handleQuantityIncrement}
        color={selectTheme(user.Role)}
      />
    </BasketControls>
  );
};

export default ProductBasketControls;
