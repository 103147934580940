// import { SET_BASKET, RESET_BASKET } from '../actions/basket.action';

export const SET_BASKET = "SET_BASKET";
export const RESET_BASKET = "RESET_BASKET";

const initialState = {};

const BasketReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_BASKET:
      return {
        ...state,
        ...action.data,
      };
    case RESET_BASKET:
      return initialState;
    default:
      return {
        ...state,
      };
  }
};

export default BasketReducer;
