import React, { useState } from "react";
import {
  Button,
  Center,
  Group,
  Loader,
  Pagination,
  Paper,
  Stack,
  Table,
  Text,
  TextInput,
} from "@mantine/core";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  addToPharmacyDiscountGroup,
  getPharmacyDiscountGroupLinks,
  getPharmacyDiscountGroups,
  removeFromPharmacyDiscountGroup,
} from "../../services/discounts.service";
import PharmacyDiscountGroupSelector from "./PharmacyDiscountGroupSelector";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { useDebouncedValue } from "@mantine/hooks";
import ErrorBox from "../../components/ErrorBox";

function PharmacyDiscountGroups() {
  const queryClient = useQueryClient();

  const [currentPage, setCurrentPage] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [debouncedSearch] = useDebouncedValue(searchQuery, 300, {
    leading: true,
  });

  const {
    data: pharmacyDiscountGroups,
    isLoading: groupsLoading,
    error: groupsError,
    fetchStatus: groupsStatus,
  } = useQuery(["pharmacyDiscountGroups"], getPharmacyDiscountGroups);
  const {
    data: pharmacyDiscountGroupLinks,
    isLoading: linksLoading,
    error: linksError,
    fetchStatus: linksStatus,
  } = useQuery({
    queryKey: ["groupLinks", currentPage, debouncedSearch],
    queryFn: () => getPharmacyDiscountGroupLinks(currentPage, debouncedSearch),
    keepPreviousData: true,
  });

  const pharmacyDiscountGroupData = pharmacyDiscountGroups?.map(
    (pharmacyDiscountGroup) => ({
      value: pharmacyDiscountGroup.id?.toString(),
      label: pharmacyDiscountGroup.name,
    })
  );

  const addToDiscountGroupMutation = useMutation({
    mutationFn: (discountGroupLink: {
      pharmacyDiscounGroupId: number;
      pharmacyId: number;
    }) => addToPharmacyDiscountGroup(discountGroupLink),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["groupLinks", currentPage] });
    },
  });

  const removeFromDiscountGroupMutation = useMutation({
    mutationFn: (discountGroupLink: {
      pharmacyDiscounGroupId: number;
      pharmacyId: number;
    }) => removeFromPharmacyDiscountGroup(discountGroupLink),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["groupLinks", currentPage] });
    },
  });

  // Currified functions to avoid passing a pharmacyId prop to the PharmacyDiscountGroupSelector component
  const onAddDiscountGroup = (pharmacyId) => (pharmacyDiscounGroupId) => {
    addToDiscountGroupMutation.mutate({ pharmacyDiscounGroupId, pharmacyId });
  };

  const onRemoveDiscountGroup = (pharmacyId) => (pharmacyDiscounGroupId) => {
    removeFromDiscountGroupMutation.mutate({
      pharmacyDiscounGroupId,
      pharmacyId,
    });
  };

  const onChange = (pageNumber) => {
    setCurrentPage(pageNumber - 1);
  };

  if (groupsLoading || linksLoading)
    return (
      <Center my="lg">
        <Loader />
      </Center>
    );
  if (groupsError || linksError) {
    return <ErrorBox />;
  }

  const totalPages = pharmacyDiscountGroupLinks.totalPages;
  const links = pharmacyDiscountGroupLinks.data;

  const orderedLinks = Object.keys(links).sort((a, b) => {
    const aName = links[a][0].LegalName?.split("|")[0].toLowerCase() ?? "";
    const bName = links[b][0].LegalName?.split("|")[0].toLowerCase() ?? "";
    return aName.localeCompare(bName);
  });

  const isLoading =
    addToDiscountGroupMutation.isLoading ||
    removeFromDiscountGroupMutation.isLoading ||
    groupsLoading ||
    linksLoading ||
    groupsStatus === "fetching" ||
    linksStatus === "fetching";

  return (
    <PharmacyDiscountGroupsWrapper loading={isLoading}>
      <>
        <TextInput
          my="md"
          placeholder="Rechercher une pharmacie (nom, ville ou CIP)"
          icon={<FontAwesomeIcon icon={faSearch} />}
          value={searchQuery}
          onChange={(e) => {
            setCurrentPage(0);
            setSearchQuery(e.currentTarget.value);
          }}
        />

        <Table>
          <thead>
            <tr>
              <th>Pharmacie</th>
              <th>Groupe(s) de réductions</th>
            </tr>
          </thead>
          <tbody>
            {orderedLinks.map((pharmacyId) => (
              <tr key={pharmacyId}>
                {/* <td><span style={{ textTransform: 'capitalize' }}>{links[pharmacyId][0].LegalName?.split('|')[0].toLowerCase() ?? '[Pharmacie sans nom]'}</span></td> */}
                <td>
                  <Group spacing={3}>
                    <Text span sx={{ textTransform: "capitalize" }}>
                      {links[pharmacyId][0].LegalName?.split(
                        "|"
                      )[0].toLowerCase() ?? "[Pharmacie sans nom]"}
                    </Text>
                    <Text span color="dimmed" fz="xs">{`(${
                      links[pharmacyId][0].cip ?? "n/a"
                    })`}</Text>
                  </Group>
                </td>

                <td style={{ minWidth: "50%" }}>
                  <PharmacyDiscountGroupSelector
                    data={pharmacyDiscountGroupData}
                    links={links[pharmacyId]}
                    onAdd={(discountGroupId) =>
                      onAddDiscountGroup(pharmacyId)(discountGroupId)
                    }
                    onRemove={(discountGroupId) =>
                      onRemoveDiscountGroup(pharmacyId)(discountGroupId)
                    }
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <Group position="center">
          <Pagination
            my="md"
            total={totalPages}
            withControls={false}
            onChange={onChange}
            value={currentPage + 1}
          />
        </Group>
      </>
    </PharmacyDiscountGroupsWrapper>
  );
}

function PharmacyDiscountGroupsWrapper({ loading, children }) {
  return (
    <Paper>
      <Group>
        <Text size="xl" weight={700}>
          Répartition des pharmacies
        </Text>
        {loading && <Loader size="sm" />}
      </Group>
      <Stack spacing={0}>
        <Text size="sm" color="gray">
          Une pharmacie peut appartenir à plusieurs groupes de réductions. Pour
          enlever toutes les réductions à une pharmacie, lui enlever tous les
          groupes de réduction associés.
        </Text>
      </Stack>

      {children}
    </Paper>
  );
}

export default PharmacyDiscountGroups;
