import React, { useRef, useState } from 'react'
import CtaButton from '../../components/Buttons/CtaButton'
import styled from 'styled-components'
import { useGetCoupon } from './useGetCoupon'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'
import { formatCurrency } from '../../utils/prices'

const CouponContainer = styled.div`
  display: flex;
  gap: 0.25em;
  margin: 0.5em 0;
`

const List = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  padding: 16px;
  background-color: white;
`

const Label = styled.div`
  margin-right: 64px;
  font-weight: bold;
`

type CouponInputProps = {
  onValidateCoupon: (data: {
    code: string
    discountedProducts: number[]
    discountAmount: number
    minimumTotal?: number
  }) => void
}

const CouponInput = ({ onValidateCoupon }: CouponInputProps) => {
  const [couponInput, setCouponInput] = useState('')

  const getCouponMutation = useGetCoupon()

  const onSubmit = (e) => {
    e.preventDefault()
    getCouponMutation.mutate(couponInput, {
      onSuccess: (data) => {
        onValidateCoupon({
          code: data.code,
          discountAmount: data.discount_amount,
          discountedProducts: data.products.map((product) => product.id),
          minimumTotal: data.minimum_total,
        })
      },
    })
  }

  return (
    <List>
      <Label>Bon de réduction</Label>

      <form onSubmit={onSubmit}>
        <CouponContainer>
          <input
            required
            style={{ width: '100%' }}
            onChange={(e) => setCouponInput(e.target.value)}
          />
          <CtaButton disabled={getCouponMutation.isLoading}>
            {getCouponMutation.isLoading ? (
              <FontAwesomeIcon icon={faCircleNotch} color="white" spin />
            ) : (
              <p>Valider</p>
            )}
          </CtaButton>
        </CouponContainer>
      </form>

      {getCouponMutation.isError && <p style={{ color: 'red' }}>Code invalide</p>}
      {getCouponMutation.isSuccess && (
        <>
          <p>{`Code de réduction pris en compte. (${getCouponMutation.data.discount_amount}%)`}</p>
          {getCouponMutation.data.minimum_total && (
            <p>{`Minimum d'achat de ${formatCurrency(getCouponMutation.data.minimum_total)}`}</p>
          )}
          {getCouponMutation.data.products.length > 0 && (
            <>
              <p>
                <br />
                {`Produits concernés :`}
              </p>
              {getCouponMutation.data.products.map((product) => {
                return <p key={product.id}>- {product.title}</p>
              })}
            </>
          )}
        </>
      )}
    </List>
  )
}

export default CouponInput
