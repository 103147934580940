import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  span {
    font-weight: 300;
    color: white !important;
    text-decoration: none;
  }
`;

const SpanLink = styled.span`
  font-weight: 500 !important;
  &:hover {
    color: grey !important;
    transition: 0.2s;
  }
`;

const Step = styled.div`
  &::after {
    content: "/";
    color: white;
    margin: 0 8px;
  }
  &:last-child {
    &::after {
      content: none;
    }
  }
`;

const Breadcrumb = ({ steps }) => {
  return (
    <Wrapper>
      {steps
        .filter((step) => step.label != null)
        .map((step, idx) => (
          <Step key={idx}>
            {idx < steps.length - 1 ? (
              <Link to={step.href}>
                <SpanLink>{step.label}</SpanLink>
              </Link>
            ) : (
              <span>{step.label}</span>
            )}
          </Step>
        ))}
    </Wrapper>
  );
};

export default Breadcrumb;
