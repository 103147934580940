import { environment } from "../environments";

export const queryBuilder = (query) => {
  let queryBuilder = "?";

  Object.keys(query).forEach((key) => {
    queryBuilder += `${key}=${JSON.stringify(query[key])}&`;
  });
  return queryBuilder;
};

const headers = {
  Accept: "application/json",
  "Content-Type": "application/json",
};

export const put = async (route, object) => {
  console.log("ICIPut " + environment.apiUrl + route);

  return await fetch(environment.apiUrl + route, {
    method: "put",
    credentials: "include",
    headers,
    body: JSON.stringify(object),
  });
};

export const get = async (route) => {
  console.log("ICIGet " + environment.apiUrl + route);

  return await fetch(environment.apiUrl + route, {
    method: "get",
    credentials: "include",
    headers,
  });
};

export const del = async (route) => {
  console.log("ICIDel " + environment.apiUrl + route);

  return await fetch(environment.apiUrl + route, {
    method: "delete",
    credentials: "include",
    headers,
  });
};

export const post = async (route, object) => {
  console.log("ICIPost " + environment.apiUrl + route);

  return await fetch(environment.apiUrl + route, {
    method: "post",
    credentials: "include",
    headers,
    body: JSON.stringify(object),
  });
};

export const postFormData = async (route, formData) => {
  console.log("ICIPostFormData " + environment.apiUrl + route);

  return await fetch(environment.apiUrl + route, {
    method: "post",
    credentials: "include",
    body: formData,
  });
};

export const postFile = async (route, object) => {
  console.log("ICIPostFile " + environment.apiUrl + route);

  const formdata = new FormData();
  formdata.append(object[0], object[1]);

  return await fetch(environment.apiUrl + route, {
    method: "post",
    credentials: "include",
    body: formdata,
  });
};
