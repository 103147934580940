import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { get } from "../../services/utils.service";

import "./Paginate.css";

const PaginateOrders = ({ fetchOrders }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [initialRender, setInitialRender] = useState(true);
  const [perPage, setPerPage] = useState(30);
  const [totalPage, setTotalPage] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [totalElement, setTotalElement] = useState(0);

  const handlePageClick = (e) => {
    const selected = +e.selected;
    setPageNumber(selected);
  };

  const fetchTotalElement = async () => {
    const response = await get("/orders/count");
    const { ordersNumber } = await response.json();
    const result = Math.trunc(ordersNumber / perPage + 1);
    setTotalElement(ordersNumber);
    setTotalPage(result);
  };

  const getOrders = async () => {
    await fetchOrders(pageNumber, perPage);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchTotalElement();
  }, []);

  useEffect(() => {
    if (initialRender) setInitialRender(false);
    else getOrders();
  }, [pageNumber]);

  if (isLoading) return null;

  if (!totalElement) return null;

  return (
    <ReactPaginate
      previousLabel={"precedent"}
      nextLabel={"suivant"}
      breakLabel={"..."}
      breakClassName={"break-me"}
      pageCount={totalPage}
      marginPagesDisplayed={2}
      pageRangeDisplayed={5}
      onPageChange={handlePageClick}
      containerClassName={"pagination"}
      subContainerClassName={"pages pagination"}
      activeClassName={"active"}
    />
  );
};

export default PaginateOrders;
