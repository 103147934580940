import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faTruck } from "@fortawesome/free-solid-svg-icons";
import {
  emptyBasket,
  updateBasketCookie,
} from "../../store/actions/basket.action";
import CtaButton from "../../components/Buttons/CtaButton";
import PaginateOrders from "../../components/Paginate/PaginateOrders";
import {
  Table,
  CenteredTh,
  Tr,
  CenteredTd,
  Thead,
  Tbody,
} from "../../components/table/table.component";
import Layout from "../../components/Layout/Layout";
import PageHeader from "../../components/PageHeader/PageHeader";
import IconButton from "../../components/Buttons/IconButton";
import NoCommands from "../../components/EmptyPage/NoCommands";
import { get } from "../../services/utils.service";
import { formatUSDate } from "../../utils/dates.utils";
import { formatAmount } from "../../utils/text-formatting.utils";

// import Cookies from "js-cookie";
import Cookies from "universal-cookie";

import CancelOrderModal from "../../components/CancelOrderModal/CancelOrderModal";

const CenteredText = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 64px;
  background-color: white;

  div {
    margin-left: 32px;
    p {
      text-align: center;
    }
  }
`;

const CenteredDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const History = () => {
  const cookies = new Cookies();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  const [orders, setOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorWithOrders, setErrorWithOrders] = useState(false);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [orderReferenceToBeCancelled, setOrderReferenceToBeCancelled] =
    useState(null);

  const history = useHistory();

  const columns = [
    "Date",
    "Référence",
    "Statut",
    "Total HT (€)",
    "Total TTC (€)",
    "Effectué par",
    "Dupliquer",
    "Annuler / Refaire",
  ];

  const fetchOrder = async (order_reference: string) => {
    try {
      const response = await get(`/orders/order/${order_reference}`);
      const orders = await response.json();
      dispatch(emptyBasket);
      for (const order of orders) {
        dispatch(
          updateBasketCookie(
            +cookies.get("pharmacy"),
            order.product_id,
            order.quantity
          )
        );
      }
    } catch (error) {
      console.error(error); //DEBUG: manage this error
    }
  };

  const fetchOrders = async (pageNumber?: string, numberPerPage?: string) => {
    try {
      let query = "/orders/me";
      if (!(pageNumber === null || pageNumber === undefined)) {
        const perPage = numberPerPage ? +numberPerPage : 5;
        query += "?offset=" + +pageNumber * perPage;
        query += "&limit=" + perPage;
      }
      const response = await get(query);
      const orders = await response.json();
      if (orders.statusCode && orders.statusCode != 200)
        throw "Error while fetching orders";
      const formattedOrders = orders
        .map((order) => {
          return {
            ...order,
            order_date_initial: order.order_date,
            order_date: formatUSDate(new Date(order.order_date)),
          };
        })
        .sort(
          (order1, order2) =>
            order1.order_date_initial < order2.order_date_initial
        );

      setOrders(formattedOrders);
    } catch (error) {
      setErrorWithOrders(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDuplication = (event, orderReference: string) => {
    event.stopPropagation();
    fetchOrder(orderReference);
    history.push("/basket");
  };

  const handleCancelOrder = (event, orderReference: string) => {
    event.stopPropagation();
    setOrderReferenceToBeCancelled(orderReference);
    setModalIsOpen(true);
  };

  const displayTrash = (order) =>
    order.order_status_fk === 6 && !order.is_covid; // display trash if order is pending

  const handleCheckDetails = (orderReference: string) => {
    history.push(`/order/${orderReference}`);
  };

  useEffect(() => {
    fetchOrders("0", "30");
  }, []);

  if (isLoading) return null;

  if (errorWithOrders) {
    return (
      <Layout>
        <PageHeader title={"Commande - Erreur"} />
        <CenteredText>
          <p>
            Il y a eu un problème lors de la recuperation de votre historique de
            commande, nous vous invitons a recharger la page.
          </p>
          <CtaButton onClick={() => history.go(0)}>
            <p>Recharger la page</p>
          </CtaButton>
        </CenteredText>
      </Layout>
    );
  }

  if (!orders || !orders.length)
    return (
      <Layout>
        <PageHeader title="Historique de commande" />
        <NoCommands text="Vous n'avez pas encore passé de commande" />
      </Layout>
    );

  // DEBUG: Why the table is still there? It seems that
  return (
    <Layout>
      <PageHeader title="Historique de commande" />
      <PaginateOrders fetchOrders={fetchOrders} />
      <Table className="responsive-static" style={{ backgroundColor: "white" }}>
        <Thead>
          <Tr>
            {columns.map((h, idx) => (
              <CenteredTh key={idx}>{h}</CenteredTh>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {orders.map((order, idx) => (
            <Tr
              className="hoverable"
              key={idx}
              style={{ cursor: "pointer" }}
              onClick={() => handleCheckDetails(order.order_reference)}
            >
              <CenteredTd>{order.order_date}</CenteredTd>

              <CenteredTd>{order.order_reference}</CenteredTd>

              <CenteredTd>{order.intitule_status}</CenteredTd>
              <CenteredTd>{`${formatAmount(
                order.total_ht,
                false
              )}`}</CenteredTd>
              <CenteredTd>{`${formatAmount(
                order.total_ttc,
                false
              )}`}</CenteredTd>
              <CenteredTd>
                {["user", "userAccountant"].includes(order.order_user_role)
                  ? "Vous"
                  : "Votre commercial"}
              </CenteredTd>
              {order.deliveryLink ? (
                <CenteredTd>
                  <CenteredDiv>
                    <IconButton>
                      <FontAwesomeIcon icon={faTruck} />
                    </IconButton>
                  </CenteredDiv>
                </CenteredTd>
              ) : null}
              <CenteredTd>
                <CenteredDiv>
                  <IconButton
                    onClick={(e) => handleDuplication(e, order.order_reference)}
                  >
                    <FontAwesomeIcon icon={faTruck} />
                  </IconButton>
                </CenteredDiv>
              </CenteredTd>
              {displayTrash(order) ? (
                <CenteredTd>
                  <CenteredDiv>
                    <IconButton
                      onClick={(e) =>
                        handleCancelOrder(e, order.order_reference)
                      }
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </IconButton>
                  </CenteredDiv>
                </CenteredTd>
              ) : (
                <div>{null}</div>
              )}
            </Tr>
          ))}
        </Tbody>
      </Table>

      <CancelOrderModal
        isVisible={modalIsOpen}
        setIsVisible={setModalIsOpen}
        orderReferenceToBeCancelled={orderReferenceToBeCancelled}
        setOrderReferenceToBeCancelled={setOrderReferenceToBeCancelled}
        fetchOrders={fetchOrders}
        fetchOrder={fetchOrder}
      />
    </Layout>
  );
};

export default History;
