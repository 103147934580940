import variables from "../themes/variables.theme";

export const selectTheme = (role: string) => {
  return role === "admin"
    ? variables.greenLS
    : role === "salesperson"
    ? variables.blueLS
    : variables.orange;
};

export const selectLightTheme = (role: string) => {
  return role === "admin"
    ? variables.greenLightLS
    : role === "salesperson"
    ? variables.blueLightLS
    : variables.orangeLight;
};
