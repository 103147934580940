import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Redirect, Link } from "react-router-dom";
import styled from "styled-components";

import Product from "../../components/Products/Product.interface";
import RoundButton from "../RoundButton/RoundButton";

import { formatUSDate } from "../../utils/dates.utils";

import {
  formatProductTitle,
  formatAmount,
} from "../../utils/text-formatting.utils";
import { environment } from "../../environments";
import variables from "../../themes/variables.theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { selectTheme } from "../../utils/theme.utils";

const GreenP = styled.div`
  color: #003c32;
  font-weight: bold;
`;
const ConfirmationButton = styled.div`
  background-color: red;
  border: 1px solid ${variables.darkGrey};
  padding: 5px;
  /* margin-right: 10px; */
  color: white;
  font-weight: lighter;
  font-size: smaller;
  opacity: 0.7;
  width: 120px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  right: 10px;
  top: 10px;
  &:hover {
    opacity: 1;
  }
  svg {
    path {
      fill: white;
    }
    &:hover {
      cursor: pointer;
      path {
        fill: black;
      }
    }
  }
`;

const IconButton = styled.button`
  background: none;
  border: none;
  color: ${variables.charcoalGrey};
  cursor: pointer;
  :focus {
    outline: none;
  }
  :hover {
    color: ${variables.darkGrey};
  }
`;

const Card = styled.div`
  position: relative;
  background-color: white;
  width: 330px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  margin: 20px;

  padding: 16px;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
`;

export const DeleteButton = styled.div`
  background-color: red;
  border: 1px solid ${variables.darkGrey};
  padding: 5px;
  color: white;
  font-weight: lighter;
  font-size: smaller;
  opacity: 0.7;
  width: 120px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 10px;
  top: 10px;
  &:hover {
    cursor: pointer;
    opacity: 1;
  }
`;

const CtaButton = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
`;

// DEBUG: temporary
const FakeImg = styled.div`
  height: 123px;
  width: 95px;
  background-color: grey;
  &::before {
    content: "image";
  }
`;

const Image = styled.div`
  max-height: 123px;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
  img {
    max-width: 100%;
    max-height: 100%;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: black;
`;

const Title = styled.h3`
  text-align: center;
  margin-bottom: 8px;
`;

// DEBUG: what the plus button should do? Open a new tab with the product maybe?
const ProductMiniature = ({
  id,
  isEditing,
  wantToDeleteLinked,
  setWantToDeleteLinked,
  handleDeleteLinked,
}) => {
  const user = useSelector((state) => state.user);
  const products = useSelector((state) => state.products.values);

  const [product, setProduct] = useState(null);

  const handleClickDeleteLinked = (event, id) => {
    event.preventDefault();
    setWantToDeleteLinked((prevState) => ({ ...prevState, [id]: true }));
  };

  useEffect(() => {
    if (!products) return;
    const idInt: number = parseInt(id, 10);

    const product: Product = products.find((p) => p.product_id === idInt);
    setProduct(product);
  }, [products, id]);

  const link = `/produit/${id}`;

  if (product === null) {
    return null;
  } else if (product === undefined) {
    return <Redirect to="/" />;
  }

  return (
    <Card>
      {isEditing ? (
        <>
          {wantToDeleteLinked[id] === true ? (
            <ConfirmationButton>
              <IconButton onClick={(e) => handleDeleteLinked(e, id)}>
                <FontAwesomeIcon icon={faCheck} />
              </IconButton>
              Sûr ?
              <IconButton
                onClick={() =>
                  setWantToDeleteLinked((prevState) => ({
                    ...prevState,
                    [id]: false,
                  }))
                }
              >
                <FontAwesomeIcon icon={faTimes} />
              </IconButton>
            </ConfirmationButton>
          ) : (
            <DeleteButton onClick={(e) => handleClickDeleteLinked(e, id)}>
              SUPPRIMER
            </DeleteButton>
          )}
        </>
      ) : (
        <CtaButton>
          <Link to={link}>
            <RoundButton
              content="+"
              variant="big"
              color={selectTheme(user.Role)}
            />
          </Link>
        </CtaButton>
      )}

      {product.image_path ? (
        <Image>
          <img
            src={`${environment.imageBasePath}${product.image_path}`}
            alt={`${product.product_title}`}
          />
        </Image>
      ) : (
        <FakeImg />
      )}
      <StyledLink
        // DEBUG: to be precised
        to={link}
      >
        <Title>{formatProductTitle(product.product_title)}</Title>
      </StyledLink>
      <p>
        <StyledLink
          // DEBUG: to be precised
          to={`/?gamme=${product.sub_range_title}`}
        >
          {product.sub_range_title}
        </StyledLink>
        {" - "}
        <StyledLink to={link}>{product.ean_id}</StyledLink>
      </p>
      <p>{`Colisage : ${product.batch_quantity}`}</p>
      <p>{`${formatAmount(product.price_eur)}`}</p>
      {product.stock >=
      (product.batch_quantity ? product.batch_quantity : 0) ? (
        <GreenP>EN STOCK</GreenP>
      ) : product.restock_date ? (
        <p>Retour prévu le {formatUSDate(new Date(product.restock_date))}</p>
      ) : (
        <p>Victime de son succès</p>
      )}
    </Card>
  );
};

export default ProductMiniature;
