import { MultiSelect } from "@mantine/core";
import { useDebouncedState } from "@mantine/hooks";
import { useQuery } from "@tanstack/react-query";
import React from "react";
import { getProductSearchResults } from "../../services/discounts.service";

function ProductSelector({ selectedProducts, onAdd, onRemove }) {
  const [searchQuery, setSearchQuery] = useDebouncedState("", 200);

  const {
    data: products,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["products", { search: searchQuery }],
    queryFn: () => getProductSearchResults(searchQuery),
  });

  const selectedProductData = selectedProducts?.map((product) => ({
    value: product.id.toString(),
    label: product.title,
  }));
  const searchProductData = products?.map((product) => ({
    value: product.id.toString(),
    label: product.title,
  }));
  const productData = [...selectedProductData, ...(searchProductData ?? [])];

  const productValues = selectedProducts?.map((product) =>
    product.id?.toString()
  );

  const selectedAndSearchedProducts = [
    ...selectedProducts,
    ...(products ?? []),
  ];

  const onChange = (newValue) => {
    const difference = newValue
      .filter((x) => !productValues.includes(x))
      .concat(productValues.filter((x) => !newValue.includes(x)));

    if (difference.length !== 1) return;
    const changedProduct = selectedAndSearchedProducts.find(
      (product) => product.id.toString() === difference[0]
    );
    if (newValue.length > productValues.length) {
      onAdd(changedProduct);
    } else if (newValue.length < productValues.length) {
      onRemove(changedProduct);
    }
  };

  // if (isLoading) return <div>Loading</div>
  // if (error) return <div>{error}</div>
  return (
    <MultiSelect
      searchable
      label="Produits"
      description="Les quantités des produits dans un même groupe sont combinées pour calculer la réduction."
      data={productData}
      value={productValues}
      onSearchChange={setSearchQuery}
      onChange={onChange}
    />
  );
}

export default ProductSelector;
