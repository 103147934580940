import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert } from "@mantine/core";
import PropTypes from "prop-types";
import React from "react";

export default function ErrorBox({ children }) {
  return (
    <Alert
      icon={<FontAwesomeIcon icon={faExclamationCircle} />}
      title="Erreur"
      color="red"
    >
      {children || "Une erreur est survenue, veuillez réessayer plus tard."}
    </Alert>
  );
}

ErrorBox.propTypes = {
  children: PropTypes.node,
};
