import React, { useState } from "react";
import { useDisclosure } from "@mantine/hooks";
import { Center, Loader, Modal } from "@mantine/core";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  deleteBaseDiscount,
  getAllBaseDiscounts,
  getPharmacyDiscountGroups,
  getRanges,
  setBaseDiscount,
} from "../../services/discounts.service";
import BaseDiscountForm from "./BaseDiscountForm";
import BaseDiscountsPanel from "./BaseDiscountsPanel";
import ErrorBox from "../../components/ErrorBox";

function BaseDiscount() {
  const queryClient = useQueryClient();

  const {
    data: baseDiscounts,
    isLoading,
    error,
  } = useQuery(["allBaseDiscounts"], getAllBaseDiscounts);
  const {
    data: ranges,
    isLoading: rangesLoading,
    error: rangeError,
  } = useQuery(["ranges"], getRanges);
  const {
    data: pharmacyDiscountGroups,
    isLoading: groupsLoading,
    error: groupError,
  } = useQuery(["pharmacyDiscountGroups"], getPharmacyDiscountGroups);

  const baseDiscountsByRange = baseDiscounts?.reduce((prev, current) => {
    if (!prev[current.sub_range_id]) prev[current.sub_range_id] = [];
    prev[current.sub_range_id].push(current);
    return prev;
  }, {});

  const [opened, { open, close }] = useDisclosure(false);
  const [selectedBaseDiscount, setSelectedBaseDiscount] = useState();

  const setBaseDiscountMutation = useMutation({
    mutationFn: (newBaseDiscount) => {
      return setBaseDiscount(newBaseDiscount);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["allBaseDiscounts"] });
    },
  });

  const deleteBaseDiscountMutation = useMutation({
    mutationFn: (baseDiscount) => {
      return deleteBaseDiscount(baseDiscount);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["allBaseDiscounts"] });
    },
  });

  const onEdit = (baseDiscount) => {
    setSelectedBaseDiscount({ ...baseDiscount, edit: true });
    open();
  };

  const onCreate = () => {
    setSelectedBaseDiscount(undefined);
    open();
  };

  const onDelete = (baseDiscount) => {
    deleteBaseDiscountMutation.mutate(baseDiscount);
  };

  const onFormSubmit = (baseDiscount) => {
    close();
    setBaseDiscountMutation.mutate(baseDiscount);
  };

  const onFormCancel = () => {
    close();
    setSelectedBaseDiscount(undefined);
  };

  if (isLoading || rangesLoading || groupsLoading)
    return (
      <Center my="lg">
        <Loader />
      </Center>
    );
  if (error || rangeError || groupError) {
    return <ErrorBox />;
  }

  return (
    <>
      <Modal opened={opened} onClose={close} title="Réductions par Gamme">
        <BaseDiscountForm
          ranges={ranges}
          pharmacyDiscountGroups={pharmacyDiscountGroups}
          baseDiscount={selectedBaseDiscount}
          onFormSubmit={onFormSubmit}
          onFormCancel={onFormCancel}
        />
      </Modal>

      <BaseDiscountsPanel
        onEdit={onEdit}
        onCreate={onCreate}
        onDelete={onDelete}
        baseDiscounts={baseDiscountsByRange}
        ranges={ranges}
        pharmacyDiscountGroups={pharmacyDiscountGroups}
      />
    </>
  );
}

export default BaseDiscount;
