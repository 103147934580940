import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";

import ProductBasketControls from "./ProductBasketControls";
import BasketUpdated from "./BasketUpdated";

import { updateBasketCookie } from "../../store/actions/basket.action";
import {
  formatProductTitle,
  formatAmount,
} from "../../utils/text-formatting.utils";
import { formatUSDate } from "../../utils/dates.utils";
import {
  getDiscountLevel,
  getTotalWithoutTaxes,
  getAdjustedLevels,
  getDiscountLevels,
} from "../../utils/prices";
import variables from "../../themes/variables.theme";
import { EditIcon, SaveIcon, CloseIcon } from "../../assets/icons/icons";
import { del, get, put } from "../../services/utils.service";
import { IconButton } from "../table/EditableTr";
// import Cookies from "js-cookie";
import Cookies from "universal-cookie";
import { useProductDiscounts } from "../../hooks/useProductDiscounts";
import DiscountThresholds from "./DiscountThresholds";
import { DateInput } from "@mantine/dates";
import { NumberInput, Stack, TextInput } from "@mantine/core";

const ProductInfos = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: flex-start;
  padding: 16px;
  height: 100%;
  width: 100%;
  font-size: 21px;
  font-weight: 300;
`;

const Title = styled.div`
  font-size: 32px;
  color: #131415;
  margin-bottom: 30px;
`;

const ErrorText = styled.p`
  font-size: 20px;
  color: #9b1219;
  margin-bottom: 30px;
`;

const Details = styled.div`
  font-size: 21px;
  font-weight: 400;
  margin-bottom: 30px;
  color: #454647;
`;

const EditBox = styled.div`
  height: 32px;
  right: 16px;
  top: 16px;
  background: ${variables.whiteThree};
  display: flex;
  align-items: center;
  padding: 0 10px;
  border-radius: 4px;
  z-index: 1;
  span {
    font-size: 14px;
  }
  input {
    :focus {
      outline: none;
    }
  }
  button {
    background: none;
    border: none;
    margin-left: 10px;
    padding: 0;
    cursor: pointer;
    :focus {
      outline: none;
    }
  }
`;

const BoldP = styled.div`
  font-size: 19px;
  font-weight: bold;
  color: #131415;
`;

const Price = styled(BoldP)`
  margin-bottom: 17px;
`;

const Stock = styled(BoldP)`
  color: #003c32;
  font-size: 19px;
  margin-bottom: 32px;
`;

const DiscountedPrice = styled.div`
  margin-top: 8px;
  p {
    font-size: 16px;
  }
`;

const Controls = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
`;

const ProductDetails = ({
  product, // DEBUG
  id,
  title,
  subRange,
  ean,
  batchQuantity,
  price,
  stock,
  restock_date,
}) => {
  const cookies = new Cookies();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const basket = useSelector(
    (state) => state.basket[cookies.get("pharmacy") ?? "0"] ?? {}
  );

  const basketUpdatedRef = useRef(null);
  const [orderQuantity, setOrderQuantity] = useState("0");
  const [isEditing, setIsEditing] = useState(false);

  const quantity = parseInt(orderQuantity) ?? 0;

  const { discountThresholds, discountGroupProducts } = useProductDiscounts(id);
  const adjustedLevels = getAdjustedLevels(
    id,
    discountThresholds,
    discountGroupProducts,
    basket
  );
  const discountLevel = getDiscountLevel(adjustedLevels, quantity);
  const totalWithoutTaxes = getTotalWithoutTaxes(
    price,
    quantity,
    discountLevel
  );
  const [eanError, setEanError] = useState(false);
  const [discountError, setDiscountError] = useState(false);

  const history = useHistory();
  const defaultEditInputsValue = {
    product_title: product.product_title,
    ean_id: product.ean_id,
    batch_quantity: product.batch_quantity,
    price_eur: product.price_eur,
    stock: product.stock,
    restock_date: product.restock_date ? new Date(restock_date) : null,
  };

  const [editInputs, setEditInputs] = useState(defaultEditInputsValue);

  useEffect(() => {
    if (!basket) {
      return;
    }
    if (basket[id]) {
      setOrderQuantity(basket[id]);
    }
  }, [id, JSON.stringify(basket)]);

  const handleSetOrderQuantity = (val) => {
    setOrderQuantity(val);
    dispatch(updateBasketCookie(+cookies.get("pharmacy") ?? 0, id, val));
    if (basketUpdatedRef.current) {
      basketUpdatedRef.current.handleTemporaryDisplay();
    }
  };

  //handle edit data
  const handleOpenEditMode = (event) => {
    event.preventDefault();
    setIsEditing(true);
  };

  const handleCloseEditMode = (event) => {
    event.preventDefault();
    setEditInputs(defaultEditInputsValue);
    setIsEditing(false);
  };

  const handleSaveNewDetails = async (event) => {
    try {
      event.preventDefault();
      const body = {
        ...editInputs,
        product_title: editInputs.product_title?.toUpperCase(),
        restock_date: editInputs.restock_date?.toISOString().split("T")[0],
      };

      const response = await put(
        `/product/details/${product.product_id}`,
        body
      );

      if (!response.ok) throw "wrong datas";

      const parseResponse = await response.json();
      if (parseResponse.result) history.go(0);
      else {
        setEditInputs(defaultEditInputsValue);
        if (parseResponse.text === "ean error") {
          setEanError(true);
          setTimeout(() => {
            setEanError(false);
          }, 3000);
        }
        if (parseResponse.text === "discount error") {
          setDiscountError(true);
          setTimeout(() => {
            setDiscountError(false);
          }, 3000);
        }
      }
    } catch (error) {
      console.error(error);
      setEditInputs(defaultEditInputsValue);
    } finally {
      setIsEditing(false);
    }
  };

  return (
    <ProductInfos>
      {user.Role === "admin" ? (
        <EditBox>
          <span>Modifier les détails du produit</span>
          {isEditing ? (
            <>
              <button onClick={handleSaveNewDetails}>
                <SaveIcon color={variables.charcoalGrey} />
              </button>
              <button onClick={handleCloseEditMode}>
                <CloseIcon color={variables.charcoalGrey} />
              </button>
            </>
          ) : (
            <button onClick={handleOpenEditMode}>
              <EditIcon color={variables.charcoalGrey} />
            </button>
          )}
        </EditBox>
      ) : null}
      {!isEditing ? (
        <>
          {eanError ? (
            <ErrorText>Erreur: Cet identifiant ean existe deja</ErrorText>
          ) : null}
          {discountError ? (
            <ErrorText>
              Erreur: Les niveaux de discounts doivent être croissants
            </ErrorText>
          ) : null}
          <Title>{formatProductTitle(title)}</Title>
          <Details>{`${
            subRange ? subRange : "Non repertorié"
          } - ${ean}`}</Details>
          <p>{`Colisage : ${batchQuantity ? batchQuantity : "0"}`}</p>
          <DiscountThresholds discounts={discountThresholds} />
          <Price>{`Prix tarif ${formatAmount(price ? price : 0)}`}</Price>
          {stock < batchQuantity ? (
            <p>
              Produit victime de son succès
              {restock_date && new Date(restock_date) > new Date() ? (
                <span>
                  , retour en stock prévu le{" "}
                  {formatUSDate(new Date(restock_date))}
                </span>
              ) : null}
            </p>
          ) : (
            <>
              <Stock>EN STOCK</Stock>
              <Controls>
                <ProductBasketControls
                  orderQuantity={orderQuantity}
                  stock={stock}
                  setOrderQuantity={handleSetOrderQuantity}
                  batchQuantity={batchQuantity}
                />
                <BasketUpdated ref={basketUpdatedRef} />
              </Controls>
              <DiscountedPrice>
                <p>{`Remise : ${discountLevel}%`}</p>
                <Price>{`Prix remisé ${formatAmount(
                  totalWithoutTaxes
                )}`}</Price>
              </DiscountedPrice>
            </>
          )}
        </>
      ) : (
        <Stack spacing={0} sx={{ width: "100%", height: "100%" }}>
          <TextInput
            label="Titre"
            size="lg"
            value={editInputs.product_title ?? ""}
            onChange={(event) =>
              setEditInputs((prev) => ({
                ...prev,
                product_title: event.target.value,
              }))
            }
          />
          <TextInput
            label="EAN"
            value={editInputs.ean_id ?? ""}
            onChange={(event) =>
              setEditInputs((prev) => ({ ...prev, ean_id: event.target.value }))
            }
          />
          <NumberInput
            label="Colisage "
            min={0}
            value={editInputs.batch_quantity ?? ""}
            onChange={(value) =>
              setEditInputs((prev) => ({ ...prev, batch_quantity: value }))
            }
          />
          <NumberInput
            label="Prix tarif"
            min={0}
            decimalSeparator=","
            precision={2}
            step={0.01}
            value={editInputs.price_eur ?? ""}
            onChange={(value) =>
              setEditInputs((prev) => ({ ...prev, price_eur: value }))
            }
          />
          <NumberInput
            label="Stock"
            min={0}
            value={editInputs.stock ?? ""}
            onChange={(value) =>
              setEditInputs((prev) => ({ ...prev, stock: value }))
            }
          />
          {stock < (batchQuantity ? batchQuantity : 0) && (
            <DateInput
              allowDeselect
              valueFormat="DD MMM YYYY"
              label="Date de remise en stock"
              value={editInputs.restock_date}
              onChange={(value) =>
                setEditInputs((prev) => ({ ...prev, restock_date: value }))
              }
            />
          )}
        </Stack>
      )}
    </ProductInfos>
  );
};

export default ProductDetails;
