import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { get, put } from "../../../services/utils.service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSignOutAlt,
  faExchangeAlt,
  faBook,
  faBookOpen,
} from "@fortawesome/free-solid-svg-icons";

import SearchInput from "./SearchInput";

import ProfileLogoImage from "../../../assets/imgs/user.jpg";
import { environment } from "../../../environments";
// import Cookies from "js-cookie";
import Cookies from "universal-cookie";
import moment from "moment";
import { base64ToBlob } from "../../../utils/blob.utils";
import variables from "../../../themes/variables.theme";
import { selectTheme } from "../../../utils/theme.utils";
import {
  ActionIcon,
  Button,
  Group,
  Popover,
  Stack,
  Text,
  Tooltip,
} from "@mantine/core";
import { isPharmacyUser } from "../../../utils/client.utils";

const MobileOnly = styled.div`
  @media (min-width: 768px) {
    display: none;
  }
`;

const DesktopOnly = styled.div`
  @media (max-width: 44rem) {
    display: none;
  }
`;

const Wrapper = styled.div`
  position: sticky;
  top: 0;
  z-index: 10;
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
`;

const CatalogueWrapper = styled.div`
  display: flex;
  height: 100%;

  @media (max-width: 768px) {
    display: none;
    background-color: red;
  }
`;

const HeaderCentered = styled(Wrapper)`
  justify-content: center;
`;

const DownloadText = styled.p`
  color: #f75216;
  font-size: 12px;
  font-weight: 400;
`;

const DownloadButtonContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
  justify-content: space-around;
  margin-left: 8px;
`;

const DownloadButton = styled.div<{ color: string }>`
  cursor: pointer;
  border: 1px solid ${(props) => props.color};
  border-radius: 14px;

  padding: 5px 8px 4px;
  font-size: 12px;
  font-weight: 300;
  color: ${(props) => props.color};

  &:hover {
    background-color: ${(props) => props.color};
    color: white;
    transition: 0.3s;
  }
`;

const DownloadButtonMini = styled.div<{ color: string }>`
  cursor: pointer;
  border: 1px solid ${(props) => props.color};
  border-radius: 14px;

  padding: 3px 4px 2px;
  font-size: 10px;
  font-weight: 200;
  color: ${(props) => props.color};

  &:hover {
    background-color: ${(props) => props.color};
    color: white;
    transition: 0.3s;
  }
  @media (min-width: 768px) {
    margin-right: 10px;
  }
`;

const MenuLogos = styled.div`
  display: flex;
  flex-grow: 1;
  flex-basis: 0;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: right;
  align-items: center;
  img {
    margin-right: 20px;
    cursor: pointer;
  }
`;

const MenuLogo = styled.div`
  cursor: pointer;
  width: 24px;
  height: 24px;
  margin-right: 20px;
  position: relative;
  svg {
    fill: #6d7980;
  }
  &:hover {
    svg {
      fill: #20252c;
    }
  }
`;

const MenuBasket = styled.div<{ hasProductsInBasket: boolean; color: string }>`
  position: relative;
  cursor: pointer;
  width: 34px;
  height: 34px;
  margin-right: 20px;
  #circle {
    fill: ${(props) => props.color};
  }
  /* &:hover {
    #circle {
      fill: #fc7a30;
      transition: 0.3s;
    } */
  }
  &::before {
    content: "";
    display: ${({ hasProductsInBasket }) =>
      hasProductsInBasket ? "block" : "none"};
    position: absolute;
    top: 0;
    right: 0;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: ${(props) => props.color};
  }
`;

const RoundImg = styled.img`
  border-radius: 50%;
`;

const PortailTriangle = styled.div<{ open?: boolean }>`
  z-index: 2;

  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;

  border-bottom: 10px solid white;
  background: none;

  display: ${({ open }) => (open ? "block" : "none")};
  position: absolute;
  top: 38px;
`;

const Menu = styled.div<{ open?: boolean; fromRight: number }>`
  z-index: 2;
  display: ${({ open }) => (open ? "flex" : "none")};
  position: absolute;
  right: ${({ fromRight }) => `${fromRight.toString()}px`};
  top: 45px;
  max-width: 300px;
  width: 300px;
  padding: 20px;
  background-color: white;
  flex-direction: row;
  flex-wrap: wrap;
  box-shadow: 0 2px 10px rgb(0 0 0 / 20%);
`;

const MenuProfileLogo = styled.div`
  position: relative;
`;

const ProfileSubMenu = styled.div`
  text-align: center;
  color: rgb(215, 0, 127);
  svg {
    fill: rgb(215, 0, 127);
  }
`;

const LinkWrapper = styled.a`
  width: 130px;
  height: 95px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &:hover {
    border-radius: 5px;
    border: 1px solid #eee;
  }

  img {
    height: 30px;
    margin: 0;
  }
`;

const MenuRole = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 40px;
  text-align: center;
  @media (min-width: 768px) {
    margin-left: 20px;
  }
  @media (max-width: 768px) {
    margin-right: 20px;
  }
`;

const handleLogout = () => {
  const cookies = new Cookies();
  cookies.remove("basket");
};

const MenuLink = ({ href, children }) => {
  return (
    <LinkWrapper href={href} onClick={handleLogout}>
      {children}
    </LinkWrapper>
  );
};

const MenuLinkPassword = ({ href, children }) => {
  return <LinkWrapper href={href}>{children}</LinkWrapper>;
};

const Header = () => {
  const cookies = new Cookies();
  const basket = useSelector(
    (state) => state.basket[cookies.get("pharmacy") ?? "0"] ?? {}
  );

  const user = useSelector((state) => state.user);
  const pharmacyData = useSelector(
    (state) => state.information.informationState
  );

  const [headerMenuOpen, setHeaderMenuOpen] = useState(false);
  const [headerProfileOpen, setHeaderProfileOpen] = useState(false);
  const [catalogueDownloading, setCatalogueDownloading] = useState(false);

  const MenuLogoRef = useRef(null);
  const MenuProfileLogoRef = useRef(null);

  const hasProductsInBasket = !!Object.keys(basket).length;

  useEffect(() => {
    const closeHeaderMenus = (e) => {
      if (headerMenuOpen && !MenuLogoRef.current.contains(e.target)) {
        setHeaderMenuOpen(false);
      }
      if (headerProfileOpen && !MenuProfileLogoRef.current.contains(e.target)) {
        setHeaderProfileOpen(false);
      }
    };

    window.addEventListener("click", closeHeaderMenus);

    return () => {
      window.removeEventListener("click", closeHeaderMenus);
    };
  }, [MenuLogoRef, MenuProfileLogoRef, headerMenuOpen, headerProfileOpen]);

  const handleMenuLogoToggle = () => {
    setHeaderMenuOpen((headerMenuOpen) => !headerMenuOpen);
    setHeaderProfileOpen(false);
  };

  const handleProfileLogoToggle = () => {
    setHeaderProfileOpen((headerProfileOpen) => !headerProfileOpen);
    setHeaderMenuOpen(false);
  };

  const handleDownloadCatalogue = async (event, groupement: string) => {
    try {
      const groupementLink = {
        LS: 1,
        CP: 3,
        AS: 2,
        GP: 4,
      };

      event.preventDefault();
      setCatalogueDownloading(true);
      const response = await get(
        `/catalogue/download/${groupementLink[groupement]}`
      );
      const pdfBase64 = await response.json();
      const url = base64ToBlob(pdfBase64[0]);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `Catalogue produit ${groupement} ${moment().format("DD-MM-YYYY")}.pdf`
      );
      document.body.appendChild(link);
      // Start download
      link.click();
      // Clean up and remove the link
      link.parentNode.removeChild(link);
      setCatalogueDownloading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const matchPharmacyGroup = {
    Leadersanté: "LS",
    "Artisan santé": "AS",
    "Coté Pharma": "CP",
    Groupapharm: "GP",
  };

  return (
    <>
      <Group noWrap position="apart" w="100%">
        <div style={{ flexGrow: 1 }}>
          {(user.Role === "admin" || user.Role === "salesperson") && (
            <Popover zIndex={10} position="bottom" withArrow shadow="md">
              <Popover.Target>
                <ActionIcon
                  radius="xl"
                  size="lg"
                  variant="filled"
                  color="primary"
                >
                  <FontAwesomeIcon icon={faBookOpen} />
                </ActionIcon>
              </Popover.Target>
              <Popover.Dropdown>
                <Stack spacing="xs">
                  <Button
                    variant="outline"
                    onClick={(e) => handleDownloadCatalogue(e, "LS")}
                  >
                    Télécharger le catalogue LS
                  </Button>
                  <Button
                    variant="outline"
                    onClick={(e) => handleDownloadCatalogue(e, "AS")}
                  >
                    Télécharger le catalogue AS
                  </Button>
                  <Button
                    variant="outline"
                    onClick={(e) => handleDownloadCatalogue(e, "CP")}
                  >
                    Télécharger le catalogue CP
                  </Button>
                  <Button
                    variant="outline"
                    onClick={(e) => handleDownloadCatalogue(e, "GP")}
                  >
                    Télécharger le catalogue GP
                  </Button>
                </Stack>
              </Popover.Dropdown>
            </Popover>
          )}

          {isPharmacyUser(user) && (
            <Tooltip label="Télécharger le catalogue">
              <ActionIcon
                radius="xl"
                size="lg"
                variant="filled"
                color="primary"
                onClick={(e) =>
                  handleDownloadCatalogue(
                    e,
                    pharmacyData
                      ? matchPharmacyGroup[pharmacyData?.PharmacyGroup]
                      : "LS"
                  )
                }
              >
                <FontAwesomeIcon icon={faBookOpen} />
              </ActionIcon>
            </Tooltip>
          )}
        </div>

        {catalogueDownloading && (
          <DownloadText>
            Le catalogue est en cours de téléchargement...
          </DownloadText>
        )}

        <DesktopOnly>
          <SearchInput />
        </DesktopOnly>
        <MenuLogos>
          {user.Role === "admin" && (
            <MenuRole>
              <p>Siège</p>
            </MenuRole>
          )}

          {user.Role === "salesperson" && (
            <MenuRole>
              <p>
                {user.FirstName} {user.LastName}
              </p>
            </MenuRole>
          )}
          {isPharmacyUser(user) && (
            <MenuRole>
              <Text lineClamp={2}>
                {pharmacyData.LegalName} | {pharmacyData.CIP}
              </Text>
            </MenuRole>
          )}
          <Link to="/basket">
            <MenuBasket
              hasProductsInBasket={hasProductsInBasket}
              color={selectTheme(user.Role)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="34"
                height="34"
                viewBox="0 0 34 34"
              >
                <g fill="none" fillRule="evenodd">
                  <g fillRule="nonzero">
                    <g transform="translate(-1290 -11) translate(1290 11)">
                      <circle id="circle" cx="17" cy="17" r="17" />
                      <g fill="#FFF" transform="translate(6 8)">
                        <ellipse cx="6.02" cy="16.167" rx="1.673" ry="1.573" />
                        <path d="M16.196 14.594c-.924 0-1.673.704-1.673 1.573s.749 1.573 1.673 1.573c.923 0 1.673-.704 1.673-1.573s-.75-1.573-1.673-1.573zM16.83 11.383c.456-.003.857-.284.992-.694l2.021-6.16c.093-.284.045-.594-.133-.84-.178-.247-.465-.405-.78-.43L4.343 2.132 3.877.79c-.14-.405-.541-.678-.994-.678H1.211C.633.11.165.55.165 1.094c0 .543.468.983 1.046.983h.913L3.775 6.84l1.253 3.66-1.323 2.318c-.174.305-.165.673.024.97.188.296.529.478.897.478h13.103c.577 0 1.045-.44 1.045-.983 0-.543-.468-.983-1.045-.983H6.373l.487-.853 9.97-.064zm.515-6.75c0-.272.234-.492.523-.492.289 0 .523.22.523.491v1.623c0 .271-.234.491-.523.491-.289 0-.523-.22-.523-.491V4.632zM5.8 5.926c0 .272-.234.492-.523.492-.288 0-.523-.22-.523-.492V3.716c0-.272.235-.492.523-.492.29 0 .523.22.523.492v2.211zm9.448-1.479c0-.271.234-.491.523-.491.289 0 .523.22.523.491v4.804c0 .272-.234.491-.523.491-.289 0-.523-.22-.523-.491V4.448zm-2.099-.077c0-.272.234-.492.523-.492.289 0 .523.22.523.492v5.57c0 .272-.234.492-.523.492-.289 0-.523-.22-.523-.492v-5.57zm-2.098-.263c0-.271.233-.491.523-.491.289 0 .522.22.522.491v5.833c0 .272-.233.492-.522.492-.29 0-.523-.22-.523-.492V4.108zm-2.099-.13c0-.272.234-.492.523-.492.289 0 .523.22.523.491v5.964c0 .272-.234.492-.523.492-.29 0-.523-.22-.523-.492V3.977zm-2.099-.096c0-.271.234-.49.523-.49.289 0 .523.219.523.49v4.646c0 .272-.234.491-.523.491-.289 0-.523-.22-.523-.491V3.882z" />
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
            </MenuBasket>
          </Link>
          <MenuLogo ref={MenuLogoRef} onClick={handleMenuLogoToggle}>
            <svg>
              <path d="M1 1h6v6h-6v-6z" />
              <path d="M17 1h6v6h-6v-6z" />
              <path d="M9 1h6v6h-6v-6z" />
              <path d="M1 9h6v6h-6v-6z" />
              <path d="M17 9h6v6h-6v-6z" />
              <path d="M9 9h6v6h-6v-6z" />
              <path d="M1 17h6v6h-6v-6z" />
              <path d="M17 17h6v6h-6v-6z" />
              <path d="M9 17h6v6h-6v-6z" />
            </svg>
            <PortailTriangle open={headerMenuOpen} />
            <Menu open={headerMenuOpen} fromRight={-50}>
              {environment.menuLinks.map((link, idx) => (
                <MenuLink key={idx} href={link.href}>
                  <img src={link.src} alt="" />
                </MenuLink>
              ))}
            </Menu>
          </MenuLogo>
          <MenuProfileLogo
            ref={MenuProfileLogoRef}
            onClick={handleProfileLogoToggle}
          >
            <RoundImg
              src={ProfileLogoImage}
              alt="Menu profil"
              height="28px"
              width="28px"
            />
            <PortailTriangle open={headerProfileOpen} />
            <Menu open={headerProfileOpen} fromRight={20}>
              <MenuLink href={`${environment.portalUrl}/logout`}>
                <ProfileSubMenu>
                  <FontAwesomeIcon icon={faSignOutAlt} />
                  <p>Déconnexion</p>
                </ProfileSubMenu>
              </MenuLink>
              <MenuLinkPassword href={`${environment.portalUrl}/myInformation`}>
                <ProfileSubMenu>
                  <FontAwesomeIcon icon={faExchangeAlt} />
                  <p>Gestion mot de passe</p>
                </ProfileSubMenu>
              </MenuLinkPassword>
            </Menu>
          </MenuProfileLogo>
        </MenuLogos>
      </Group>
      {/* <MobileOnly>
        <HeaderCentered>
          <SearchInput />
        </HeaderCentered>
      </MobileOnly> */}
    </>
  );
};

export default Header;
