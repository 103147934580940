import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import variables from "../../themes/variables.theme";
import { selectLightTheme, selectTheme } from "../../utils/theme.utils";

const Button = styled.button<{
  clickable: boolean;
  color: string;
  lightColor: string;
}>`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  padding: 18px 35px;
  border: none;
  background-color: ${({ clickable, color }) => (clickable ? color : "grey")};
  border-radius: 2px;

  > p {
    color: white;
    font-size: 14px;
    padding: 0;
    margin: 0;
    font-weight: 300;
    line-height: 13px;
  }

  &:hover {
    cursor: ${({ clickable }) => (clickable ? "pointer" : "not-allowed")};
    background-color: ${({ clickable, lightColor }) =>
      clickable ? lightColor : "grey"};
  }
`;

const CtaButton = ({
  onClick = () => {},
  children,
  className = undefined,
  disabled = false,
}) => {
  const user = useSelector((state) => state.user);

  return (
    <Button
      disabled={disabled}
      className={className}
      clickable={!disabled}
      onClick={onClick}
      color={selectTheme(user.Role)}
      lightColor={selectLightTheme(user.Role)}
    >
      {children}
    </Button>
  );
};

export default CtaButton;
