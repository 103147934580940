import Product, { Discount } from "../components/Products/Product.interface";

export const getDiscountLevels = (baseDiscounts, discountsThresholds) => {
  const discountsLevels = [];
  let baseDiscount = 0;

  // Get Best Base discount
  baseDiscounts?.sort((a, b) => b.discount - a.discount);
  if (baseDiscounts?.length > 0) {
    baseDiscount = baseDiscounts[0].discount;
  }

  discountsLevels.push({
    quantity: 0,
    amount: baseDiscount,
  });

  discountsThresholds.forEach((d) => {
    discountsLevels.push({
      quantity: d.quantity,
      amount: Math.min(baseDiscount + d.additional_discount, 100),
      discountGroup: d.discount_group_id,
    });
  });
  return discountsLevels ?? [];
};

export const getAdjustedLevels = (
  productId,
  discountLevels,
  discountGroupProducts,
  basket
) => {
  // Adjust levels based on basket, if products from the same discount group are in the basket, their quantities are subtracted.
  // productId: the product we are computing levels for. We ignore its quantity.
  const { [productId]: _, ...basketWithoutCurrentProduct } = basket;

  const adjustedLevels = discountLevels?.map((d) => {
    const level = { ...d };
    discountGroupProducts[level.discountGroup]?.forEach((p) => {
      const basketQuantity = basketWithoutCurrentProduct[p];
      if (basketQuantity) {
        if (level.quantity > basketQuantity) {
          level.quantity = level.quantity - basketQuantity;
        } else {
          level.quantity = 0;
        }
      }
    });
    return level;
  });
  return adjustedLevels ?? [];
};

export const keepBestDiscounts = (discountLevels) => {
  const finalDiscountLevels = [];
  discountLevels
    ?.sort((a, b) => b.amount - a.amount)
    .forEach((d) => {
      const existing = finalDiscountLevels.find(
        (e) => e.quantity === d.quantity
      );
      if (!existing)
        finalDiscountLevels.push({ quantity: d.quantity, amount: d.amount });
    });

  return finalDiscountLevels;
};

export const getDiscountLevel = (
  discountLevels: any,
  quantity: number
): number => {
  if (!discountLevels || !discountLevels.length) return 0;

  discountLevels.sort((a, b) => b.amount - a.amount);
  const last = discountLevels.find((dis) => dis.quantity <= quantity);
  return last ? Math.min(last.amount, 100) : 0;
};

export const priceWithDiscount = (price: number, discount: number) =>
  price - (price * discount) / 100;

export const getTotalWithoutTaxes = (
  price: number,
  quantity: number,
  discount: number
) => priceWithDiscount(price, discount) * quantity;

export const getTotalWithTaxes = (totalWithoutTaxes, tva) =>
  totalWithoutTaxes + (totalWithoutTaxes * tva) / 100;

export const formatCurrency = (amount: number) => {
  return new Intl.NumberFormat("fr-FR", {
    style: "currency",
    currency: "EUR",
    maximumFractionDigits: 2,
  }).format(amount);
};

export const getProductCouponDiscountLevel = (
  coupon: {
    code: string
    discountedProducts: number[]
    discountAmount: number
    minimumTotal?: number
  },
  product: {
    product_id: number
    discountLevel: number
  }) => {
  const isCouponProduct = coupon.discountedProducts.includes(product.product_id);

  const couponDiscount =
    isCouponProduct || coupon.discountedProducts.length === 0
      ? Math.min(100, coupon.discountAmount)
      : 0

  const updatedProductDiscountLevel =
    product.discountLevel + couponDiscount - (product.discountLevel * couponDiscount) / 100

  return updatedProductDiscountLevel
}
