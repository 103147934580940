import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { setDefaultInformationSettings } from "../../store/actions/information.action";

import Layout from "../../components/Layout/Layout";
import PageHeader from "../../components/PageHeader/PageHeader";
import NoPharmacyError from "../../components/EmptyPage/NoPharmacyError";

const Section = styled.div`
  margin-bottom: 24px;
`;

const SectionTitle = styled.div`
  margin-bottom: 23px;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: white;
`;

const Coordinates = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 3fr 13px 3fr 13px 3fr;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-content: center;
  }
`;

const CoordinatesSection = styled.div<{ gridPosition: number }>`
  grid-column-start: ${({ gridPosition }) => gridPosition};
  margin-bottom: 16px;

  @media (max-width: 768px) {
  }
`;

const CoordinatesTitle = styled.div`
  margin-bottom: 10px;
  color: white;
`;

const Field = styled.div`
  padding: 13px 16px;
  background-color: white;
`;

const ImageWrapper = styled.div`
  width: 157px;
  height: 157px;
  background-color: white;
  margin-bottom: 41px;
`;

const InformationsScreen = (props) => {
  const dispatch = useDispatch();
  const pharmacyData = useSelector(
    (state) => state.information.informationState
  );

  useEffect(() => {
    dispatch(setDefaultInformationSettings);
  }, [dispatch]);

  if (pharmacyData.statusCode && pharmacyData.pharmacyData !== 200) {
    return <NoPharmacyError title="Informations Pharmacie" />;
  }

  const { Address, ZipCode, City, Phone, CIP, LegalName, Email } = pharmacyData;

  // DEBUG: create a component for the "coordinates" sections
  return (
    <Layout>
      <PageHeader title="Informations Pharmacie" />
      <Section>
        <SectionTitle>Coordonnées</SectionTitle>
        <Coordinates>
          <CoordinatesSection gridPosition={1}>
            <CoordinatesTitle>Nom de la Pharmacie</CoordinatesTitle>
            <Field>{LegalName}</Field>
          </CoordinatesSection>
          <CoordinatesSection gridPosition={3}>
            <CoordinatesTitle>CIP</CoordinatesTitle>
            <Field>{CIP}</Field>
          </CoordinatesSection>
          <CoordinatesSection gridPosition={5}>
            <CoordinatesTitle>Numéro</CoordinatesTitle>
            <Field>{Phone}</Field>
          </CoordinatesSection>
        </Coordinates>
        <Coordinates>
          <CoordinatesSection gridPosition={1}>
            <CoordinatesTitle>Adresse</CoordinatesTitle>
            <Field>{`${Address}, ${ZipCode} ${City}`}</Field>
          </CoordinatesSection>
          <CoordinatesSection gridPosition={3}>
            <CoordinatesTitle>Adresse email</CoordinatesTitle>
            <Field>{Email}</Field>
          </CoordinatesSection>
        </Coordinates>
      </Section>
      <Section>
        <SectionTitle>Kbis</SectionTitle>
        <ImageWrapper />
      </Section>
      <Section>
        <SectionTitle>Rib</SectionTitle>
        <ImageWrapper />
      </Section>
    </Layout>
  );
};

export default InformationsScreen;
