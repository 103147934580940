import { faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ActionIcon, Table, Text } from "@mantine/core";
import React from "react";
import { formatCurrency } from "../../utils/prices";
import dayjs from "dayjs";

function CouponCodeTable({ coupons, onEdit, onViewDetail }) {
  return (
    <Table highlightOnHover>
      <thead>
        <tr>
          <th>Code</th>
          <th>Réduction</th>
          <th>Min. d'achat</th>
          <th>Validité</th>
          <th>Utilisations autorisées</th>
          <th>Utilisations totales</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {coupons.map((coupon, index: number) => (
          <CouponCodeRow
            coupon={coupon}
            onEdit={onEdit}
            onViewDetail={onViewDetail}
          />
        ))}
      </tbody>
    </Table>
  );
}

function CouponCodeRow({ coupon, onEdit, onViewDetail }) {
  const start_date = new Date(coupon.start_date);
  const end_date = new Date(coupon.end_date);

  const isExpired = new Date() < start_date || new Date() > end_date;
  const isUsedUp =
    coupon?.total_uses >= (coupon?.max_uses ?? Number.MAX_SAFE_INTEGER);
  const isSoldOut = coupon?.total_sales >= coupon?.max_sales;

  const isActive = !isExpired && !isUsedUp && !isSoldOut;

  // TODO: Use constants for colors
  const lightRed = "#ffcccb";

  return (
    // onClick={() => onViewDetail(coupon)} // TODO: fix coupon detail view
    <tr style={{ cursor: "pointer" }} onClick={() => onEdit(coupon)}> 

      <td>
        <Text fw="bold" sx={isActive ? {} : { textDecoration: "line-through" }}>
          {coupon.code}
        </Text>
      </td>
      <td>{coupon.discount_amount}%</td>
      <td>{formatCurrency(coupon.minimum_total)}</td>
      <td style={isExpired ? { backgroundColor: lightRed } : {}}>
        {dayjs(start_date).isSame(end_date, "day") ? (
          <Text align="center" size="sm" color="dark">
            {start_date.toLocaleDateString()}
          </Text>
        ) : (
          <Text size="sm" color="dark">
            {start_date.toLocaleDateString()} - {end_date.toLocaleDateString()}
          </Text>
        )}
      </td>
      <td style={isUsedUp ? { backgroundColor: lightRed } : {}}>
        {coupon.max_uses ?? "-"}
      </td>
      <td>{coupon.total_uses}</td>
      <td>
        <ActionIcon
          onClick={(e) => {
            e.stopPropagation();
            onEdit(coupon);
          }}
          color="primary"
          variant="light"
          title="edit"
        >
          <FontAwesomeIcon icon={faPen} />
        </ActionIcon>
      </td>
    </tr>
  );
}

export default CouponCodeTable;
