import { Button, Group, NumberInput, Select } from "@mantine/core";
import React from "react";
import { useState } from "react";

function BaseDiscountForm({
  baseDiscount,
  ranges,
  pharmacyDiscountGroups,
  onFormSubmit,
  onFormCancel,
}) {
  const [formValues, setFormValues] = useState(baseDiscount);

  const rangeData = ranges
    .sort((a, b) => a.title - b.title)
    .map((range) => ({ value: range.id, label: range.title }));

  const pharmacyDiscountGroupData = pharmacyDiscountGroups.map(
    (pharmacyDiscountGroup) => ({
      value: pharmacyDiscountGroup.id,
      label: pharmacyDiscountGroup.name,
    })
  );

  function onSubmit(e) {
    e.preventDefault();
    onFormSubmit(formValues);
  }

  return (
    <form onSubmit={onSubmit}>
      <Select
        searchable
        mt="sm"
        label="Gamme"
        dropdownPosition="bottom"
        placeholder="Pick one"
        data={rangeData}
        disabled={formValues?.edit}
        value={formValues?.sub_range_id}
        onChange={(value) =>
          setFormValues({ ...formValues, sub_range_id: value })
        }
        withAsterisk
      />

      <Select
        searchable
        dropdownPosition="bottom"
        label="Groupement de pharmacies"
        placeholder="Pick one"
        data={pharmacyDiscountGroupData}
        disabled={formValues?.edit}
        value={formValues?.pharmacy_discount_group_id}
        onChange={(value) =>
          setFormValues({ ...formValues, pharmacy_discount_group_id: value })
        }
        withAsterisk
      />

      <NumberInput
        mt="sm"
        defaultValue={0}
        placeholder="Réduction de base (%)"
        label="Réduction de base (%)"
        value={formValues?.discount}
        onChange={(value) => setFormValues({ ...formValues, discount: value })}
        withAsterisk
      />

      <Group mt="md" position="right">
        <Button variant="outline" onClick={onFormCancel}>
          Annuler
        </Button>
        <Button type="submit">Valider</Button>
      </Group>
    </form>
  );
}

export default BaseDiscountForm;
