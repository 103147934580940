import {
  Alert,
  Button,
  Center,
  Group,
  Loader,
  Modal,
  MultiSelect,
  Pagination,
  Paper,
  Select,
  Space,
  Text,
  TextInput,
} from "@mantine/core";
import { useDebouncedState, useDisclosure } from "@mantine/hooks";
import { useQueryClient, useQuery, useMutation } from "@tanstack/react-query";
import React, { useState } from "react";
import {
  createDiscountGroup,
  deleteDiscountGroup,
  getDiscountGroups,
  getPharmacyDiscountGroups,
  updateDiscountGroup,
} from "../../services/discounts.service";
import DiscountGroupTable from "./DiscountGroupTable";
import DiscountGroupForm from "./DiscountGroupForm";
import {
  faExclamationCircle,
  faPlus,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ErrorBox from "../../components/ErrorBox";

export function DiscountGroups() {
  const [productSearchQuery, setProductSearchQuery] = useDebouncedState(
    "",
    200
  );
  const [groupFilters, setGroupFilters] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);

  const queryClient = useQueryClient();

  const {
    data: discountGroups,
    isLoading,
    error,
  } = useQuery(["discountGroups"], getDiscountGroups);
  const {
    data: pharmacyDiscountGroups,
    isLoading: groupsLoading,
    error: groupError,
  } = useQuery(["pharmacyDiscountGroups"], getPharmacyDiscountGroups);

  const [opened, { open, close }] = useDisclosure(false);
  const [selectedDiscountGroup, setSelectedDiscountGroup] = useState();

  const onEdit = (discountGroup) => {
    setSelectedDiscountGroup(discountGroup);
    open();
  };

  const onCreate = () => {
    setSelectedDiscountGroup(undefined);
    open();
  };

  const updateDiscountGroupMutation = useMutation({
    mutationFn: (discountGroup) => {
      return updateDiscountGroup(discountGroup);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["discountGroups"] });
    },
  });

  const createDiscountGroupMutation = useMutation({
    mutationFn: (newDiscountGroup) => {
      return createDiscountGroup(newDiscountGroup);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["discountGroups"] });
    },
  });

  const deleteDiscountGroupMutation = useMutation({
    mutationFn: (discountGroup) => {
      return deleteDiscountGroup(discountGroup);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["discountGroups"] });
    },
  });

  const onFormSubmit = (discountGroup) => {
    if (discountGroup?.id) {
      updateDiscountGroupMutation.mutate(discountGroup);
    } else {
      createDiscountGroupMutation.mutate(discountGroup);
    }
    close();
  };

  const formLoading =
    updateDiscountGroupMutation.isLoading ||
    createDiscountGroupMutation.isLoading ||
    deleteDiscountGroupMutation.isLoading;

  const onFormCancel = () => {
    setSelectedDiscountGroup(undefined);
    close();
  };

  const onFormDelete = () => {
    deleteDiscountGroupMutation.mutate(selectedDiscountGroup);
    close();
  };

  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber - 1);
  };

  const pharmacyDiscountGroupData =
    pharmacyDiscountGroups?.map((pharmacyDiscountGroup) => ({
      value: pharmacyDiscountGroup.id.toString(),
      label: pharmacyDiscountGroup.name,
    })) ?? [];

  const filteredDiscountGroups = discountGroups
    ?.filter((discountGroup) => {
      if (productSearchQuery) {
        // Search by name, ean, range
        return discountGroup?.products?.some(
          (product) =>
            product.title
              .toLowerCase()
              .includes(productSearchQuery.toLowerCase()) ||
            product.ean_id
              ?.toLowerCase()
              .includes(productSearchQuery.toLowerCase()) ||
            product.sub_range
              ?.toLowerCase()
              .includes(productSearchQuery.toLowerCase())
        );
      }
      return true;
    })
    ?.filter((discountGroup) => {
      if (groupFilters.length > 0) {
        return discountGroup?.pharmacyGroups?.some((pharmacyDiscountGroup) =>
          groupFilters.includes(pharmacyDiscountGroup.id.toString())
        );
      }
      return true;
    });

  const getDiscountGroupsForPage = (discountGroups, page) => {
    const pageSize = 10;
    const start = page * pageSize;
    const end = start + pageSize;
    return discountGroups?.slice(start, end) ?? [];
  };

  const totalPages = Math.ceil(filteredDiscountGroups?.length / 10) ?? 0;
  const discountGroupsForPage = getDiscountGroupsForPage(
    filteredDiscountGroups,
    currentPage
  );

  if (isLoading || groupsLoading) {
    return (
      <Center my="lg">
        <Loader />
      </Center>
    );
  }

  if (error || groupError) {
    return <ErrorBox />;
  }

  return (
    <>
      <Modal opened={opened} onClose={close} title="Modifier les remises">
        <DiscountGroupForm
          onFormSubmit={onFormSubmit}
          onFormCancel={onFormCancel}
          onFormDelete={onFormDelete}
          discountGroup={selectedDiscountGroup}
          pharmacyDiscountGroups={pharmacyDiscountGroups}
        />
      </Modal>
      <Paper>
        <Group position="apart">
          <Text size="xl" weight={700}>
            Paliers de réductions
          </Text>
          <Group>
            {formLoading && <Loader size="sm" />}
            <Button
              onClick={onCreate}
              leftIcon={<FontAwesomeIcon icon={faPlus} />}
            >
              Ajouter
            </Button>
          </Group>
        </Group>
        <Text mb="md" size="sm" color="gray">
          Les remises sont appliquées aux produits sélectionnés uniquement pour
          les groupes de pharmacies sélectionnés.
        </Text>

        <Group>
          <TextInput
            sx={{ flexGrow: 1 }}
            placeholder="Rechercher un produit (nom, gamme ou EAN)"
            icon={<FontAwesomeIcon icon={faSearch} />}
            onChange={(e) => {
              setCurrentPage(0);
              setProductSearchQuery(e.currentTarget.value);
            }}
          />

          <MultiSelect
            placeholder="Groupement de pharmacies"
            value={groupFilters}
            onChange={setGroupFilters}
            data={pharmacyDiscountGroupData}
          />
        </Group>
        <Space mb="sm" />

        {discountGroupsForPage?.length === 0 && (
          <Text align="center" my="lg" size="sm" color="gray">
            Aucun résultat
          </Text>
        )}
        {discountGroupsForPage?.length > 0 && (
          <DiscountGroupTable
            discountGroups={discountGroupsForPage}
            onEdit={onEdit}
          />
        )}

        <Group position="center">
          <Pagination
            my="md"
            total={totalPages}
            withControls={false}
            onChange={onPageChange}
            value={currentPage + 1}
          />
        </Group>
      </Paper>
    </>
  );
}
