import { post, get, del, put } from "./utils.service";

export const getAllBaseDiscounts = async () => {
  const response = await get("/discounts/base");
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  return await response.json();
};

export const setBaseDiscount = async (data) => {
  const response = await post("/discounts/base", data);
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  return await response.json();
};

export const deleteBaseDiscount = async (data) => {
  const url = `/discounts/base?pharmacy-discount-group-id=${data.pharmacy_discount_group_id}&sub-range-id=${data.sub_range_id}`;
  const response = await del(url);
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  return await response.json();
};

export const getRanges = async () => {
  const response = await get("/product/gammes");
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  return await response.json();
};

export const getPharmacyDiscountGroups = async () => {
  const response = await get("/discounts/pharmacy-discount-groups");
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  return await response.json();
};

export const getPharmacies = async () => {
  const response = await get("/pharmacies");
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  return await response.json();
};

export const getPharmacyDiscountGroupLinks = async (
  pageNumber: number,
  query?: string
) => {
  const response = await get(
    `/discounts/pharmacy-discount-group-links?page=${pageNumber}${
      query ? `&query=${query}` : ""
    }`
  );
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  return await response.json();
};

export const addToPharmacyDiscountGroup = async (discountGroupLink: {
  pharmacyDiscounGroupId: number;
  pharmacyId: number;
}) => {
  const response = await post("/discounts/pharmacy-discount-group-links", {
    pharmacy_discount_group_id: discountGroupLink.pharmacyDiscounGroupId,
    pharmacy_id: discountGroupLink.pharmacyId,
  });
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  return await response.json();
};

export const removeFromPharmacyDiscountGroup = async (discountGroupLink: {
  pharmacyDiscounGroupId: number;
  pharmacyId: number;
}) => {
  const response = await del(
    `/discounts/pharmacy-discount-group-links?pharmacy-discount-group-id=${discountGroupLink.pharmacyDiscounGroupId}&pharmacy-id=${discountGroupLink.pharmacyId}`
  );
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  return await response.json();
};

export const getDiscountGroups = async () => {
  const response = await get("/discounts/discount-groups");
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  return await response.json();
};

// TODO move this function to a products.service file
export const getProductSearchResults = async (name: string) => {
  // Gets 10 products that match the name of the product
  const response = await get(
    "/product/search?" + new URLSearchParams({ name })
  );
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  return await response.json();
};

export const updateDiscountGroup = async (discountGroup) => {
  const response = await put(
    `/discounts/discount-groups/${discountGroup.id}`,
    discountGroup
  );
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  return await response.json();
};

export const createDiscountGroup = async (discountGroup) => {
  const response = await post("/discounts/discount-groups", discountGroup);
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  return await response.json();
};

export const deleteDiscountGroup = async (discountGroup) => {
  const response = await del(`/discounts/discount-groups/${discountGroup.id}`);
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  return await response.json();
};

export const updatePharmacyDiscountGroup = async (pharmacyDiscountGroup) => {
  const response = await put(
    `/discounts/pharmacy-discount-groups/${pharmacyDiscountGroup.id}`,
    pharmacyDiscountGroup
  );
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  return await response.json();
};

export const createPharmacyDiscountGroup = async (pharmacyDiscountGroup) => {
  const response = await post(
    "/discounts/pharmacy-discount-groups",
    pharmacyDiscountGroup
  );
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  return await response.json();
};

export const deletePharmacyDiscountGroup = async (pharmacyDiscountGroup) => {
  const response = await del(
    `/discounts/pharmacy-discount-groups/${pharmacyDiscountGroup.id}`
  );
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  return await response.json();
};

export const getCoupons = async () => {
  const response = await get("/coupon-codes");
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  return await response.json();
};

export const createCoupon = async (coupon) => {
  const response = await post("/coupon-codes", coupon);
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
};

export const updateCoupon = async (coupon) => {
  const response = await put(`/coupon-codes/${coupon.id}`, coupon);
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
};

export const deleteCoupon = async (couponId) => {
  const response = await del(`/coupon-codes/${couponId}`);
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
};

export const getCouponOrders = async (id) => {
  const response = await get(`/coupon-codes/${id}/orders`);
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  return await response.json();
};
